import React, { useEffect, useState } from 'react'
import WedgeTable from '../../../components/Table/WedgeTable'
import { IWedgeTableProps } from '../../../components/Table/constants/TableInterfaces'
import { deviceDetailColumns } from '../../../components/Table/constants/TableData'
import { styled } from '@mui/material/styles'
import { Typography } from '@mui/material'
import store from '../../../store/index'
import { setDrawerMenuItem } from '../../../actions/index'

const PREFIX = 'device-details'

const classes = {
  root: `${PREFIX}-root`,
  wrapper: `${PREFIX}-wrapper`,
  buttonProgress: `${PREFIX}-buttonProgress`,
  paper: `${PREFIX}-paper`,
}

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
  },

  [`& .${classes.wrapper}`]: {
    margin: theme.spacing(1),
    position: 'relative',
  },

  [`& .${classes.buttonProgress}`]: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },

  [`& .${classes.paper}`]: {
    maxWidth: 600,
    marginLeft: theme.spacing(2),
    padding: theme.spacing(2),
  },
}))

const Details = () => {
  const [barcode, setBarcode] = useState('')
  const tableProps: IWedgeTableProps = {
    tHeader: 'Inventory History',
    tName: 'devices',
    tColumns: deviceDetailColumns,
    optionsEndpoint: `devices/devices/${window.location.pathname.split('/')[2]}/options`,
    searchEndpoint: `devices/devices/${window.location.pathname.split('/')[2]}/search`,
    handleSelectRow: () => {},
  }

  useEffect(() => {
    store.dispatch(setDrawerMenuItem('devices'))
    setBarcode(window.location.pathname.split('/')[2])
  }, [])

  return (
    <Root className={'divBg'}>
      <Typography variant="h4" sx={{ marginBottom: '20px' }}>
        Device Details: <strong>{barcode}</strong>{' '}
      </Typography>
      <WedgeTable {...tableProps} />
    </Root>
  )
}

export default Details
