import React, { useEffect, useState } from 'react'
import { IWedgeTableProps } from '../../../components/Table/constants/TableInterfaces'
import { Link, useLocation } from 'react-router-dom'
import { Moment } from 'moment'
import moment from 'moment'
import { analyticsColumns, siteAnalyticsColumns } from '../../../components/Table/constants/TableData'
import store from '../../../store'
import { setDrawerMenuItem } from '../../../actions'
import WedgeTable from '../../../components/Table/WedgeTable'
import { Button, Typography, styled } from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import ResponseTime from '../../../components/Visualizations/ResponseTime'

const PREFIX = 'analytics-details'

const classes = {
  root: `${PREFIX}-root`,
  table: `${PREFIX}-table`,
  tableWrapper: `${PREFIX}-tableWrapper`,
  tableWrapperNon: `${PREFIX}-tableWrapperNon`,
  divBg: `${PREFIX}-divBg`,
  loading: `${PREFIX}-loading`,
}

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.root}`]: {
    width: '100%',
    marginTop: theme.spacing(3),
  },

  [`& .${classes.table}`]: {
    minWidth: 1020,
  },

  [`& .${classes.tableWrapper}`]: {
    overflowX: 'auto',
    display: 'block',
  },

  [`& .${classes.tableWrapperNon}`]: {
    display: 'none',
  },

  [`&.${classes.divBg}`]: {
    padding: 25,
  },

  [`& .${classes.loading}`]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom: 35,
  },
}))

const Details = () => {
  const location = useLocation<any>()

  const today = new Date()
  const minDate = moment(today).subtract(13, 'days').hours(0).minutes(0).seconds(0)
  const maxDate = moment(today)

  const [startDate, setStartDate] = useState(moment(today).subtract(13, 'days').hours(0).minutes(0).seconds(0))
  const [endDate, setEndDate] = useState(moment(today))
  const [dateFilters, setDateFilters] = useState({} as any)
  const defaultAnalyticsColumns = [...siteAnalyticsColumns]
  const [columns, setColumns] = useState([...defaultAnalyticsColumns])

  const [selectedFunctions, setSelectedFunctions] = useState([] as any)

  /**
   * Populates the table column headers based on the filters or min and max date
   * @param days days between start and end date
   * @param eDate end date
   */
  const getTableColumns = (days = 14, eDate?: Moment) => {
    let end = eDate ? eDate : endDate
    let cols = [...siteAnalyticsColumns]

    for (let i = 0; i < days; i++) {
      let localDate = moment(end).subtract(i, 'days')
      // Edge case for if the end date is the current date (because the current date might not have 24 hours yet like the rest)
      if (i == 0 && localDate.format('MM/DD') == maxDate.format('MM/DD')) {
        let hours = localDate.hours() // this is the total amount of local hours that the table should go up to
        for (let j = hours; j >= 0; j--) {
          let header = localDate.hours(j).minutes(0).seconds(0).format('MM/DD HH:mm')
          let field = moment.utc(localDate.hours(j).minutes(0).seconds(0)).format()
          let column = {
            headerName: header,
            field: field,
            hide: false,
            sortable: false,
            cellRenderer: 'responseRenderer',
            filterable: false,
          }
          cols.push(column)
        }
      } else {
        for (let j = 23; j >= 0; j--) {
          let header = localDate.hours(j).minutes(0).seconds(0).format('MM/DD HH:mm')
          let field = moment.utc(localDate.hours(j).minutes(0).seconds(0)).format()
          let column = {
            headerName: header,
            field: field,
            hide: false,
            sortable: false,
            cellRenderer: 'responseRenderer',
            filterable: false,
          }
          cols.push(column)
        }
      }
    }
    setColumns(cols)
  }

  /**
   * Handles applying the date filters to the table
   */
  const handleApplyDateFilter = () => {
    let sDate = moment(startDate).hours(0).minutes(0)
    let eDate = moment(endDate).hours(23).minutes(59)

    // If end date is today, keep the hours in the date
    if (endDate.format('MM/DD') == moment(today).format('MM/DD')) {
      eDate = moment(today)
    }

    let filter = {
      startTime: {
        start: sDate.format(),
        stop: eDate.format(),
      },
    }
    setDateFilters(filter)

    let days = eDate.diff(sDate, 'days') + 1
    getTableColumns(days, eDate)
  }

  /**
   * Handles selecting rows within the table
   * @param rows
   */
  const handleSelectRows = (rows: any) => {
    if (typeof rows != 'undefined') {
      let functionNames = rows.map((row: any) => row.functionName)
      setSelectedFunctions(functionNames)
    }
  }

  useEffect(() => {
    getTableColumns()
    store.dispatch(setDrawerMenuItem('analytics'))
  }, [])

  const tableProps: IWedgeTableProps = {
    tHeader: 'Function Response Times',
    tName: 'function response times',
    tColumns: columns,
    optionsEndpoint: `logging/analytics/${window.location.pathname.split('/')[2]}/options`,
    searchEndpoint: `logging/analytics/${window.location.pathname.split('/')[2]}/search`,
    handleSelectRow: handleSelectRows,
    filters: dateFilters,
    multiSelect: true,
  }

  return (
    <Root className={classes.divBg}>
      <Typography variant="h4" sx={{ marginBottom: '20px' }}>
        <Link to={`/sites/${window.location.pathname.split('/')[2]}`}>
          <strong>{location.state ? location.state.siteName : ''}</strong>
        </Link>
      </Typography>
      <Typography variant="h6" sx={{ marginBottom: '20px' }}>
        Date Range
      </Typography>
      <div style={{ marginBottom: '20px' }}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            label="Select Start Date"
            value={startDate}
            minDate={minDate}
            maxDate={maxDate}
            onChange={(date) => {
              setStartDate(date!.hour(0).minutes(0).seconds(0))
              if (date!.isAfter(endDate)) {
                setEndDate(date!.hour(23).minutes(59).seconds(59))
              }
            }}
            slotProps={{
              textField: { InputLabelProps: { color: 'secondary' }, focused: false },
            }}
            sx={{ marginRight: '10px' }}
          ></DatePicker>
        </LocalizationProvider>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            label="Select End Date"
            value={endDate}
            minDate={minDate}
            maxDate={maxDate}
            slotProps={{
              textField: { InputLabelProps: { color: 'secondary' }, focused: false },
            }}
            onChange={(date) => {
              if (date?.format('MM/DD') == moment(today).format('MM/DD')) {
                setEndDate(date)
              } else {
                if (date!.isBefore(startDate)) {
                  setStartDate(date!.hour(0).minutes(0).seconds(0))
                }
                setEndDate(date!.hour(23).minutes(59).seconds(59))
              }
            }}
            sx={{ marginRight: '10px' }}
          ></DatePicker>
        </LocalizationProvider>
        <Button variant="contained" color="primary" onClick={handleApplyDateFilter}>
          Update
        </Button>
      </div>
      <WedgeTable {...tableProps} />
      <div style={{ marginTop: '20px' }}>
        <ResponseTime
          dateFilters={dateFilters}
          selectedData={selectedFunctions}
          variant={'function'}
          endpoint={`logging/analytics/${window.location.pathname.split('/')[2]}/search`}
        />
      </div>
    </Root>
  )
}

export default Details
