import React, { Component } from 'react'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import FormControl from '@mui/material/FormControl'
import LoaderButton from '../../components/LoaderButton'
import './login.css'
import Auth, { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth'

const PREFIX = 'login'

const classes = {
  container: `${PREFIX}-container`,
  formControl: `${PREFIX}-formControl`,
  root: `${PREFIX}-root`,
  margin: `${PREFIX}-margin`,
  withoutLabel: `${PREFIX}-withoutLabel`,
  textField: `${PREFIX}-textField`,
}

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.container}`]: {
    display: 'flex',
    flexWrap: 'wrap',
  },

  [`& .${classes.formControl}`]: {
    margin: theme.spacing(1),
  },

  [`& .${classes.root}`]: {
    display: 'flex',
    flexWrap: 'wrap',
  },

  [`& .${classes.margin}`]: {
    margin: theme.spacing(1),
  },

  [`& .${classes.withoutLabel}`]: {
    marginTop: theme.spacing(3),
  },

  [`& .${classes.textField}`]: {
    flexBasis: 200,
  },
}))

//Login From UI Testing
class Login extends Component<any, any> {
  constructor(props: any) {
    super(props)

    this.state = {
      isLoading: false,
      isNewPassword: false,
      email: '',
      password: '',
      newPassword: '',
      userObject: {},
    }
  }

  validateForm() {
    return this.state.isNewPassword
      ? this.state.newPassword.length > 0 && this.state.email.length > 0 && this.state.password.length > 0
      : this.state.email.length > 0 && this.state.password.length > 0
  }

  handleChange = (event: any) => {
    this.setState({
      [event.target.id]: event.target.value,
    })
  }

  handleSubmit = async (event: any) => {
    event.preventDefault()
    console.log('hello')
    this.setState({ isLoading: true })
    try {
      await Auth.federatedSignIn({ customProvider: 'United' })
    } catch (error) {
      if (error instanceof Error) alert(error.message)
      this.setState({ isLoading: false })
    }
  }

  componentDidMount = () => {
    Auth.currentAuthenticatedUser()
      .then((user) => this.setState({ user }))
      .catch(() => console.log('Not signed in'))
  }

  render() {
    return (
      <Root className="Login">
        <form onSubmit={this.handleSubmit}>
          <div className={classes.container}>
            <FormControl fullWidth className={classes.formControl} variant="standard">
              <LoaderButton
                disabled={false}
                type="submit"
                text={'Continue with United Rentals'}
                loadingText={'Logging in…'}
                isLoading={this.state.isLoading}
              />
            </FormControl>
          </div>
        </form>
      </Root>
    )
  }
}

export default Login
