import React, { useState } from 'react'
import WedgeTable from './WedgeTable'
import { IWedgeTableProps } from './constants/TableInterfaces'
import { Box, Paper, Typography, styled, useTheme } from '@mui/material'
import SplitPane from 'react-split-pane'
import ReactJson from 'react-json-view'
import '../../css/PageableTableWithData.css'

const PREFIX = 'WedgeTableWithDataDisplay'

const classes = {
  root: `${PREFIX}-root`,
  paperTextRoot: `${PREFIX}-paperTextRoot`,
  table: `${PREFIX}-table`,
  tableWrapper: `${PREFIX}-tableWrapper`,
  tableWrapperNon: `${PREFIX}-tableWrapperNon`,
  divBg: `${PREFIX}-divBg`,
  loading: `${PREFIX}-loading`,
  textField: `${PREFIX}-textField`,
}

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.root}`]: {
    width: '100%',
  },

  [`& .${classes.paperTextRoot}`]: {
    padding: theme.spacing(2),
    height: 510,
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
  },

  [`& .${classes.table}`]: {
    minWidth: 1020,
  },

  [`& .${classes.tableWrapper}`]: {
    overflowX: 'auto',
    display: 'block',
  },

  [`& .${classes.tableWrapperNon}`]: {
    display: 'none',
  },

  [`& .${classes.divBg}`]: {
    padding: 25,
  },

  [`& .${classes.loading}`]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom: 35,
  },

  [`& .${classes.textField}`]: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}))

/**
 * Table component with side pane for data display
 * @param tProps table props
 * @returns Table with side pane
 */
const WedgeTableWithData = (tProps: IWedgeTableProps) => {
  const theme = useTheme()
  // Row management to show data on the side panel
  const [isJson, setIsJson] = useState(true)
  const [isGap, setIsGap] = useState(false)
  const [data, setData] = useState<any>()

  /**
   * Handles displaying different data formats depending on the row field
   * @param row selected row
   */
  const handleSelectRow = (row: any) => {
    if (row) {
      if (tProps.tName == 'records') {
        setIsJson(true)
        setIsGap(false)
        setData(row.source)
      } else if (tProps.tName == 'configuration changes') {
        setIsJson(false)
        setIsGap(false)
        setData(row.changes)
      } else if (tProps.tName == 'notifications') {
        setIsJson(false)
        setIsGap(false)
        setData(row.notif.split('\n').join('<br/>'))
      } else if (tProps.tName == 'device docs') {
        setIsJson(true)
        setIsGap(false)
        setData(row)
      } else if (tProps.tName == 'gap elimination') {
        setIsJson(false)
        setIsGap(true)
        setData(row)
      } else {
        setData(row)
      }
    } else {
      setData('')
    }
  }

  // Adding row handling to the table props
  let tableProps = { ...tProps, handleSelectRow }

  /**
   * Renders data gap data for display
   * @param source row data source
   * @returns html for the data display
   */
  const renderDataGaps = (source: any) => {
    if (source && source.equipmentId) {
      let div = `
            <div>
            Equipment Id: ${source.equipmentId}<br>
            &nbsp &nbsp ${new Date(source.gapStart).toISOString().replace('T', ' ').slice(0, -8)}</br>
            &nbsp &nbsp ${new Date(source.gapEnd).toISOString().replace('T', ' ').slice(0, -8)}</br>
            </p>
            State: ${source.state} &nbsp (${source.recovered}%)</br>
            </p>
        `
      if (source.cmds != null && source.cmds.length > 0) {
        div += `Downlink Commands:</br>\n`
        source.cmds.forEach((cmd: any) => {
          div += `&nbsp &nbsp Msg Id: ${cmd.msgId}</br>\n`
          div += `&nbsp &nbsp ${cmd.descr}</br>\n`
          if (!!cmd.timestamp)
            div += `&nbsp &nbsp Sent at: ${new Date(cmd.timestamp).toISOString().replace('T', ' ').slice(0, -5)}</br>\n`

          div += `&nbsp &nbsp Sent: ${cmd.sent}`
          if (cmd.ack != null) {
            div += `&nbsp &nbsp Ack: ${new Date(cmd.ack).toISOString().replace('T', ' ').slice(0, -5)}`
          }
          if (cmd.retryCount && cmd.retryCount > 0) {
            div += `&nbsp &nbsp Retries: ${cmd.retryCount}`
          }
          div += `<br>\n&nbsp &nbsp </br>\n`
        })
      }
      if (source.polling != null) {
        div += `Polled Data:</br>\n`
        source.polling.forEach((item: any) => {
          div += `&nbsp &nbsp ${new Date(item.timestamp).toISOString().replace('T', ' ').slice(0, -8)} &nbsp ${
            item.recovered
          }</br>\n`
        })
      }
      div += '</div>\n'
      return <html dangerouslySetInnerHTML={{ __html: div }} />
    }
  }

  /**
   * Renders html data for display
   * @param data row data
   * @returns html for data rows
   */
  const renderHTMLData = (data: any) => {
    let div = ``
    if (theme.palette.mode == 'dark') {
      div = `
      <div class="changeLogContainer-dark">
          ${data}
      </div>
  `
    } else {
      div = `
            <div class="changeLogContainer">
                ${data}
            </div>
        `
    }
    return <html dangerouslySetInnerHTML={{ __html: div }} />
  }

  return (
    <Root>
      <Paper
        className={classes.root}
        style={{
          flex: '1 1 100%',
          height: 'auto',
          minWidth: '30em',
        }}
        elevation={0}
      >
        <SplitPane
          split="vertical"
          defaultSize={500}
          minSize={200}
          maxSize={1000}
          style={{ display: 'flex', position: 'relative', flexDirection: 'column', height: 'auto', padding: '10px' }}
          primary="second"
        >
          <div>
            <WedgeTable {...tableProps}></WedgeTable>
          </div>
          <div>
            <Paper
              className={classes.paperTextRoot}
              style={{
                flex: '2 1 100%',
                height: '590px',
                wordBreak: 'break-all',
                overflowY: 'auto',
                padding: '20px',
                margin: '0px',
                borderStyle: 'ridge',
                backgroundColor: theme.palette.mode == 'dark' ? 'black' : 'white',
              }}
            >
              {data && isJson && (
                <ReactJson
                  src={data}
                  name={tProps.tName == 'records' ? data.barcode : data.id}
                  displayDataTypes={false}
                  theme={theme.palette.mode == 'dark' ? 'bright' : undefined}
                />
              )}
              {data && !isJson && !isGap && renderHTMLData(data)}
              {data && !isJson && isGap && renderDataGaps(data)}
              {!data && (
                <Box>
                  <Typography variant="body1">Select a row from the table</Typography>
                </Box>
              )}
            </Paper>
          </div>
        </SplitPane>
      </Paper>
    </Root>
  )
}

export default WedgeTableWithData
