// import React from 'react'
// import Auth from '@aws-amplify/auth'
// import { GET_ENDPOINT, POST_ENDPOINT } from '../../../api-request'
// import Grid from '@mui/material/Grid'
// import Paper from '@mui/material/Paper'
// import store from '../../../store/index'
// import 'echarts/lib/chart/tree'
// import 'echarts/lib/component/tooltip'
// import { setDrawerMenuItem } from '../../../actions/index'
// import AWS from 'aws-sdk'
// // import PageableTable from '../../../components/PageableTable'
// // import CustomDatePicker from './CustomDatePicker'

// AWS.config.region = 'us-west-2'

// class EnhancedTable extends React.Component<any, any> {
//   constructor(props: any) {
//     super(props)
//     this.state = {
//       selectedRow: null,
//       data: [],
//       source: '',
//       logsLoading: false,
//       dateValue: new Date()
//     }
//     this.setDateValue = this.setDateValue.bind(this)
//   }

//   setDateValue(dateValue:any) {
//     this.setState({dateValue})
//   }

//   async componentDidMount() {
//     store.dispatch(setDrawerMenuItem('trackunitapis'))
//     try {
//       let sessionDetails: any = await Auth.currentCredentials()
//       await Auth.currentAuthenticatedUser()

//       AWS.config.credentials = new AWS.CognitoIdentityCredentials(sessionDetails.params)
//     } catch (error) {
//       if (error instanceof Error) alert(error.message)
//     }
//   }

//   render() {
//     const { data, source } = this.state
//     return (
//       <div style={{ padding: 25 }}>
//         <Grid container spacing={3}>
//           <Grid item xs={12} lg={12}>
//           {/* <CustomDatePicker value={this.state.dateValue} setValue={this.setDateValue}/>
//             <PageableTable
//               endpoint={`logging/trackunitapis`}
//               dateValue={this.state.dateValue}
//               tableName="Trackunit API Calls"
//               columns={[
//                 { field: 'equipmentId', header: 'Equipment ID', selected: true, filterType: 'none' },
//                 { field: 'hvacFanMode', header: 'HVAC Fan Mode', selected: true, filterType: 'none' },
//                 { field: 'heatingSetpoint', header: 'Heating Setpoint', selected: true, filterType: 'none' },
//                 { field: 'coolingSetpoint', header: 'Cooling Setpoint', selected: true, filterType: 'none' },
//                 { field: 'controllerMode', header: 'Controller Mode', selected: true, filterType: 'none' },
//                 { field: 'wedgeReturnTemp', header: 'Wedge Return Temp', selected: true, filterType: 'none' },
//                 { field: 'smartheatSystem', header: 'Smartheat System', selected: true, filterType: 'none' },
//                 { field: 'totalCommands', header: 'Total Commands', selected: true, filterType: 'none' },
//               ]}
//             /> */}
//           </Grid>
//         </Grid>

//       </div>
//     )
//   }
// }

// export default EnhancedTable

import React, { useState, useEffect } from 'react'
import { Grid } from '@mui/material'
import { setDrawerMenuItem } from '../../../actions'
import store from '../../../store'
import styled from '@emotion/styled'
import { IWedgeTableProps } from '../../../components/Table/constants/TableInterfaces'
import { trackUnitApiColumns } from '../../../components/Table/constants/TableData'
import WedgeTable from '../../../components/Table/WedgeTable'
import WedgeTableWithData from '../../../components/Table/WedgeTableWithData'

const PREFIX = 'trackunitapis'
const classes = {
  divBg: `${PREFIX}-divBg`,
}

const Root = styled('div')(() => ({
  [`&.${classes.divBg}`]: {
    padding: 10,
    backgroundColor: 'transparent',
  },
}))

const Index = () => {
  useEffect(() => {
    store.dispatch(setDrawerMenuItem('trackunitapis'))
  })

  const trackUnitApisProps: IWedgeTableProps = {
    tHeader: 'Trackunit API Calls',
    tName: 'calls',
    tColumns: trackUnitApiColumns,
    optionsEndpoint: `data/trackunit/options`,
    searchEndpoint: `data/trackunit/search`,
    handleSelectRow: () => {},
  }

  return (
    <Root className={classes.divBg}>
      <WedgeTableWithData {...trackUnitApisProps} />
    </Root>
  )
}

export default Index
