import React, { useEffect, useReducer, useState } from 'react'
import WedgeTable from '../../../components/Table/WedgeTable'
import { IAsyncDevice, IWedgeTableProps } from '../../../components/Table/constants/TableInterfaces'
import {
  asyncDeviceDataColumns,
  asyncDeviceDetailColumns,
  logsColumns,
  m7DataColumns,
} from '../../../components/Table/constants/TableData'
import { styled } from '@mui/material/styles'
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@mui/material'
import store from '../../../store/index'
import {
  newNotificationMessage,
  openNotification,
  publishToTopic,
  setDrawerMenuItem,
  subscribeToTopic,
} from '../../../actions/index'
import { useLocation } from 'react-router-dom'
import SplitButton from '../../../components/SplitButton'
import config from '../../../config'
import Auth from '@aws-amplify/auth'
import { IState } from '../../../reducers'
import { connect } from 'react-redux'
import WedgeTableWithData from '../../../components/Table/WedgeTableWithData'

const PREFIX = 'async-device-details'

const classes = {
  root: `${PREFIX}-root`,
  wrapper: `${PREFIX}-wrapper`,
  buttonProgress: `${PREFIX}-buttonProgress`,
  paper: `${PREFIX}-paper`,
}

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
  },

  [`& .${classes.wrapper}`]: {
    margin: theme.spacing(1),
    position: 'relative',
  },

  [`& .${classes.buttonProgress}`]: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },

  [`& .${classes.paper}`]: {
    maxWidth: 600,
    marginLeft: theme.spacing(2),
    padding: theme.spacing(2),
  },
}))

interface IDialog {
  action: string
  heading: string
  handler: () => void
  details: string
  submitText: string
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    openNotification: (flag: any) => dispatch(openNotification(flag)),
    newNotificationMessage: (message: any) => dispatch(newNotificationMessage(message)),
    publishToTopic: (message: any) => dispatch(publishToTopic(message)),
    subscribeToTopic: (message: any) => dispatch(subscribeToTopic(message)),
    setDrawerMenuItem: (message: any) => dispatch(setDrawerMenuItem(message)),
  }
}

const mapStateToProps = (state: IState) => {
  return {
    cagData: state.cagData,
  }
}

const Details = (props: any) => {
  const { publishToTopic, subscribeToTopic, setDrawerMenuItem, cagData } = props

  const forceUpdate = useReducer((x) => x + 1, 0)[1]

  const [barcode, setBarcode] = useState('')
  const [devEui, setDevEui] = useState('')

  const [topicPub, setTopicPub] = useState('')
  const [topicSub, setTopicSub] = useState('')
  const [dialog, setDialog] = useState<IDialog>()
  const [dialogOpen, setDialogOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [statusText, setStatusText] = useState('')
  const [disabled, setDisabled] = useState(false)
  const [refresh, setRefresh] = useState(false)

  const location = useLocation<any>()
  const [isLwanGateway, setIsLwanGateway] = useState(false)
  const [isMultiTech, setIsMultiTech] = useState(false)
  const [isM7, setIsM7] = useState(false)

  const asyncHistoryProps: IWedgeTableProps = {
    tHeader: 'Async Device History',
    tName: 'async devices',
    tColumns: asyncDeviceDetailColumns,
    optionsEndpoint: `devices/async/devices/${location.state.barcode}/options`,
    searchEndpoint: `devices/async/devices/${location.state.barcode}/search`,
    handleSelectRow: () => {},
  }

  const asyncLogsProps: IWedgeTableProps = {
    tHeader: 'Async Device Logs',
    tName: 'logs',
    tColumns: logsColumns,
    optionsEndpoint: `devices/async/devices/${location.state.devEui}/logs/options`,
    searchEndpoint: `devices/async/devices/${location.state.devEui}/logs/search`,
    handleSelectRow: () => {},
  }

  const asyncDataProps: IWedgeTableProps = {
    tHeader: 'Async Data',
    tName: 'async data',
    tColumns: asyncDeviceDataColumns,
    optionsEndpoint: `data/async/data/${location.state.devEui}/options`,
    searchEndpoint: `data/async/data/${location.state.devEui}/search`,
    handleSelectRow: () => {},
  }

  const m7DataProps: IWedgeTableProps = {
    tHeader: 'M7 Data',
    tName: 'm7 data',
    tColumns: m7DataColumns,
    optionsEndpoint: `data/m7/data/${location.state.equipmentId}/options`,
    searchEndpoint: `data/m7/data/${location.state.equipmentId}/search`,
    handleSelectRow: () => {},
  }

  /**
   * Handles different actions to devices
   * @param action name of action
   */
  const handleClick = (action: string) => {
    switch (action) {
      case 'Reboot':
        setDialog(deviceActions.REBOOT)
        break
      case 'Get Logs':
        setDialog(deviceActions.GET_LOGS)
        break
      case 'Check-in':
        setDialog(deviceActions.CHECK_IN)
        break
      case 'Restart':
        setDialog(deviceActions.RESTART)
        break
      default:
        console.log('default')
    }
  }

  const buttonProps = {
    options: isMultiTech ? ['Check-in', 'Get Logs', 'Reboot', 'Restart'] : ['Get Logs', 'Reboot'],
    handleButtonClick: handleClick,
    setModalOpen: setDialogOpen,
    btnDisabled: disabled,
  }

  /**Handles rebooting the device */
  const handleReboot = () => {
    setDisabled(true)
    setDialogOpen(false)
    setLoading(true)
    try {
      publishToTopic({
        publishTopic: topicPub,
        publishText: {
          cag: location.state.devEui,
          command: 'REBOOT_CAG',
        },
      })
    } catch (e) {}
  }

  /** Handles getting logs from the device */
  const handleGetLogs = () => {
    setDisabled(true)
    setDialogOpen(false)
    setLoading(true)
    try {
      publishToTopic({
        publishTopic: topicPub,
        publishText: {
          cag: location.state.devEui,
          command: 'GET_LOG_FILES',
        },
      })
    } catch (e) {
      console.log(e)
    }
  }

  /** Handles checking into a device */
  const handleCheckIn = () => {
    setDisabled(true)
    setDialogOpen(false)
    setLoading(true)
    try {
      publishToTopic({
        publishTopic: topicPub,
        publishText: {
          cag: location.state.devEui,
          command: 'CHECK_IN',
        },
      })
    } catch (e) {}
  }

  /** Handles restarting a device */
  const handleRestart = () => {
    setDisabled(true)
    setDialogOpen(false)
    setLoading(true)
    try {
      publishToTopic({
        publishTopic: topicPub,
        publishText: {
          cag: location.state.devEui,
          command: 'RESTART',
        },
      })
    } catch (e) {}
  }

  // Device actions
  const deviceActions = {
    REBOOT: {
      action: 'REBOOT',
      heading: `Reboot Device ${barcode}?`,
      handler: handleReboot,
      details: `Rebooting a gateway may result in the loss of data. Are you sure you want to reboot Gateway ${barcode}?`,
      submitText: `Reboot`,
    },
    GET_LOGS: {
      action: 'GET_LOGS',
      heading: `Get Logs from Device ${barcode}?`,
      handler: handleGetLogs,
      details: `Are you sure you want to get logs on device ${barcode}?`,
      submitText: `Get logs`,
    },
    CHECK_IN: {
      action: 'CHECK_IN',
      heading: `Check-in on Device ${barcode}?`,
      handler: handleCheckIn,
      details: `Are you sure you want to check-in on device: ${barcode}`,
      submitText: `Check in`,
    },
    RESTART: {
      action: 'RESTART',
      heading: `Restart Device ${barcode}?`,
      handler: handleRestart,
      details: `Are you sure you want to restart Gateway ${barcode}?`,
      submitText: `Restart`,
    },
  }

  // Use effect to check if the cagData has been updated due to a response from cag mqtt
  useEffect(() => {
    if ('topic' in cagData) {
      if (cagData.topic.includes(barcode)) {
        let command = cagData.topic.split('/')[-1]
        console.log(`Command: ${command}`)
      } else if (cagData.topic == topicSub) {
        console.log('This is a user rsp')
      }
    }

    // Check to see for a message from the cag indicating the reverse ssh is successful
    if ('payload' in cagData) {
      if ('message' in cagData.payload && cagData.topic == topicSub) {
        setDisabled(false)
        if (cagData.payload.message == 'RESTART SUCCESSFUL') {
          setLoading(false)
          setStatusText(cagData.payload.message)
        } else if (cagData.payload.message == 'GET LOGS SUCCESSFUL') {
          setRefresh(true)
          setLoading(false)
          setStatusText(cagData.payload.message)
        } else if (cagData.payload.message == 'IN PROGRESS') {
          setStatusText(cagData.payload.message)
        } else {
          setLoading(false)
          setStatusText(cagData.payload.message)
        }
      }
    }
  }, [cagData])

  useEffect(() => {
    setDrawerMenuItem('async')

    if (location.state.type == 'Gateway') {
      if (location.state.registered!) {
        setIsLwanGateway(true)
      }
      if (location.state.make == 'MultiTech') {
        setIsMultiTech(true)
      }
    }

    if (location.state.deviceType && location.state.deviceType.slice(0, 2) == 'M7') {
      setIsM7(true)
    }

    let devBarcode = window.location.pathname.split('/')[2]
    setBarcode(devBarcode)
    setDevEui(location.state.devEui)

    if (location.state.type == 'Gateway' && location.state.registered!) {
      let tPub = ''
      let tSub = ''

      // Retrieve client id for mqtt pub/sub
      const getClientId = async () => {
        let sessionDetails = await Auth.currentCredentials()
        let client_id = sessionDetails.identityId
        return client_id
      }

      getClientId().then((id) => {
        tPub = `${config.setting.STAGE}/${id}/cmd`
        tSub = `${config.setting.STAGE}/cag/${location.state.devEui}/rsp` // listen to response from cag here for debug
        subscribeToTopic(tSub)
        setTopicPub(tPub)
        tSub = `${config.setting.STAGE}/${id}/rsp`
        setTopicSub(tSub)
      })

      setTopicPub(tPub)
      setTopicSub(tSub)
    }
  }, [])

  useEffect(() => {
    // console.log(location)
    if (location.state.isGw) {
      setIsLwanGateway(true)
    }
    console.log(location.state)
    // forceUpdate()
  }, [location])

  return (
    <Root className={'divBg'}>
      <Typography variant="h4" style={{ marginBottom: '20px' }}>
        Async Device Details: <strong>{location.state.barcode}</strong>{' '}
      </Typography>
      <Typography variant="h5" sx={{ marginBottom: '20px' }}>
        Dev EUI: <strong>{location.state.devEui}</strong>
      </Typography>
      <div style={{ display: 'flex' }}>
        <Typography variant="h5" sx={{ marginBottom: '20px', marginRight: '20px' }}>
          Make: <strong>{location.state.make}</strong>
        </Typography>
        <Typography variant="h5" sx={{ marginBottom: '20px' }}>
          Model: <strong>{location.state.model}</strong>
        </Typography>
      </div>
      {isLwanGateway && (
        <div style={{ display: 'flex', marginBottom: '20px' }}>
          <SplitButton {...buttonProps} />
          {loading && (
            <div style={{ display: 'flex', marginLeft: '20px' }}>
              <Typography variant="body1" style={{ marginRight: '20px' }}>
                <strong>Status: </strong>
              </Typography>
              <CircularProgress size={28} className={classes.buttonProgress} color="secondary" />
            </div>
          )}
          {statusText && (
            <Typography
              variant="body1"
              color={statusText.includes('ERROR') ? 'red' : 'green'}
              style={{ marginLeft: '20px' }}
            >
              <strong style={{ color: 'black' }}>Status: </strong>
              {statusText}
            </Typography>
          )}
        </div>
      )}
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{dialog?.heading}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{dialog?.details}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => setDialogOpen(false)}>
            Cancel
          </Button>
          <Button variant="contained" onClick={dialog?.handler} autoFocus>
            {dialog?.submitText}
          </Button>
        </DialogActions>
      </Dialog>
      <WedgeTable {...asyncHistoryProps} />
      <Box sx={{ marginTop: '40px' }}>
        {isM7 ? <WedgeTable {...m7DataProps} /> : <WedgeTableWithData {...asyncDataProps} />}
      </Box>
      {isLwanGateway && (
        <Box sx={{ marginTop: '40px' }}>
          <WedgeTable {...asyncLogsProps} />
        </Box>
      )}
    </Root>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Details)
