import React from 'react'
import Typography from '@mui/material/Typography'

interface HeadingProps {
  title: string
}
export const HeadingMain = (props: HeadingProps) => {
  const { title } = props
  return <Typography variant="h6">{title}</Typography>
}
