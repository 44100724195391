import React from 'react'
import { Link } from 'react-router-dom'
import WithUserPermissions from '../../user-permissions-hoc/'
import Room from '@mui/icons-material/RoomOutlined'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import { CAN_VIEW_CONSOLE_SITE_LIST_LINK, COMPONENT_TYPE_LINK } from '../../constants/permission-types'
import { connect } from 'react-redux'
import store from '../../store/index'
import { setDrawerMenuItem } from '../../actions/index'
import { IState } from '../../reducers'

const mapStateToProps = (state: IState) => {
  return {
    drawerActiveMenu: state.drawerActiveMenu,
  }
}

interface IProps {
  drawerActiveMenu: string
  isDeveloper: boolean
}

class SiteListLink extends React.Component<IProps> {
  render() {
    const { drawerActiveMenu, isDeveloper } = this.props

    return (
      <div>
        <span className="listTitle">SITES</span>
        <ListItem
          dense
          button
          component={Link}
          to="/sites"
          selected={drawerActiveMenu === 'sites'}
          onClick={() => store.dispatch(setDrawerMenuItem('sites'))}
        >
          <ListItemIcon>
            <Room className={drawerActiveMenu === 'sites' ? 'activeLink' : 'nonactiveLink'} />
          </ListItemIcon>
          <ListItemText secondary="Sites" className={drawerActiveMenu === 'sites' ? 'activeLink' : 'nonactiveLink'} />
        </ListItem>
        <ListItem
          dense
          button
          component={Link}
          to="/sites-v2"
          selected={drawerActiveMenu === 'sites-v2'}
          onClick={() => store.dispatch(setDrawerMenuItem('sites-v2'))}
        >
          <ListItemIcon>
            <Room className={drawerActiveMenu === 'sites-v2' ? 'activeLink' : 'nonactiveLink'} />
          </ListItemIcon>
          <ListItemText
            secondary="Sites V2"
            className={drawerActiveMenu === 'sites-v2' ? 'activeLink' : 'nonactiveLink'}
          />
        </ListItem>
      </div>
    )
  }
}

const List = connect(mapStateToProps)(SiteListLink)
export default WithUserPermissions(List, CAN_VIEW_CONSOLE_SITE_LIST_LINK, COMPONENT_TYPE_LINK)
