import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react'
import { IMetricDialogProps, MenuProps } from './utils'
import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Theme,
  Typography,
  useTheme,
  Stack,
  Chip,
  InputBase,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
  Divider,
  Box,
  Tooltip,
  Autocomplete,
  TextField,
} from '@mui/material'
import { GET, POST } from '../../api-request'
import { FilterList, Close, Download } from '@mui/icons-material'
import Graph from './Visualizations/Graph'
import moment from 'moment'
import { makeStyles } from '@mui/styles'
import Map from './Visualizations/Map'
import { capitalize, prepend } from '../../utils/utils'
import './styles.css'
import { newNotificationMessage } from '../../actions'
import { connect } from 'react-redux'
import ConfidenceBand from './Visualizations/ConfidenceBand'

const ExcelJS = require('exceljs')

const useStyles = makeStyles((theme: Theme) =>
  theme.palette.mode == 'dark'
    ? {
        menuItem: {
          backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12))',
        },
        chip: {
          backgroundColor: '#48484a',
          color: 'white',
        },
      }
    : {
        menuItem: { backgroundColor: 'white' },
        chip: {
          backgroundColor: '#f0f0f0',
          '&hover': {
            backgroundColor: '#dededf !important',
          },
        },
      }
)

const mapDispatchToProps = (dispatch: any) => {
  return {
    newNotificationMessage: (message: any) => dispatch(newNotificationMessage(message)),
  }
}

const WidgetDialog = (props: IMetricDialogProps) => {
  const {
    id,
    open,
    onClose,
    variant,
    heading,
    options,
    endpoint,
    params,
    subheading,
    showMetric,
    frequency,
    newNotificationMessage,
    showTimeRange,
  } = props
  const theme = useTheme()
  const classes = useStyles()

  // Headings/Subheadings
  const [dialogHeading, setDialogHeading] = useState(heading)
  const [dialogSubheading, setDialogSubheading] = useState(subheading)

  // Visualization/Loading states
  const [map, setMap] = useState(false)
  const [confidence, setConfidence] = useState(false)
  const [loading, setLoading] = useState(true)
  const [loadVis, setLoadVis] = useState(false)

  // Visualization data states
  const [seriesData, setSeriesData] = useState<any>()
  const [mapData, setMapData] = useState<any>()

  // Settings states
  const [metric, setMetric] = useState('monthly')
  const [timeRange, setTimeRange] = useState(frequency == 'hourly' ? 1 : id == 'duration' ? 14 : 7)
  const [groupByOptions, setGroupByOptions] = useState(new Array())
  const [groupBy, setGroupBy] = useState('none')
  const [settings, setSettings] = useState(
    showMetric
      ? ({ metric: 'monthly', timeRange: frequency == 'hourly' ? 1 : id == 'duration' ? 14 : 7 } as any)
      : ({ timeRange: frequency == 'hourly' ? 1 : id == 'duration' ? 14 : 7 } as any)
  )

  // Download states
  const [resData, setResData] = useState<any>({})
  const [fileType, setFileType] = useState('xlsx')

  // Filter states
  const [disabled, setDisabled] = useState(false)
  const [filters, setFilters] = useState({} as any)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [filterChips, setFilterChips] = useState<any>({}) // data for filter chips
  const [filterOpen, setFilterOpen] = useState(false)
  const [filterOptionsOpen, setFilterOptionsOpen] = useState(false)
  const [currentFilter, setCurrentFilter] = useState('')
  const [filterValues, setFilterValues] = useState<any>({})

  /**
   * Resets state values for closing dialog
   */
  const resetValues = () => {
    setDialogHeading(heading)
    setDialogSubheading(subheading)
    setMap(false)
    setGroupBy('none')
    setTimeRange(frequency == 'hourly' ? 1 : id == 'duration' ? 14 : 7)
    setMetric('monthly')
    setFilterChips({})
    setCurrentFilter('')
    setFilterValues({})
    setFileType('xlsx')
    setResData({})
    setSettings(
      showMetric
        ? { metric: 'monthly', timeRange: frequency == 'hourly' ? 1 : id == 'duration' ? 14 : 7 }
        : { timeRange: frequency == 'hourly' ? 1 : 7 }
    )
    setDisabled(false)
  }

  /**
   * Removes all filters
   */
  const removeAllFilters = () => {
    let emptyFilters = {}
    setCurrentFilter('')
    setFilterValues(emptyFilters)
    setFilterChips(emptyFilters)
    applyFilters({})
  }

  /**
   * Handles selecting a filter group
   * @param filter the selected group
   */
  const handleSelectFilter = (filter: string) => {
    setCurrentFilter(filter)
    setFilterOpen(false)
    setFilterOptionsOpen(true)
  }

  /**
   * Handles filter object and calls apply filter
   * @param e submit event
   */
  const handleApplyFilters = (e: FormEvent): void => {
    e.preventDefault()
    let newFilters = { ...filterValues }
    for (let key of Object.keys(newFilters)) {
      // if filter is an object with more keys, check each key
      if (newFilters[key].length === undefined) {
        let empty = true
        for (const prop in newFilters[key]) {
          if (newFilters[key][prop].length > 0) {
            empty = false
          }
        }
        if (empty) {
          delete newFilters[key]
        }
      } else if (newFilters[key].length == 0) {
        delete newFilters[key]
      }
    }
    setFilterValues(newFilters)
    setFilterOptionsOpen(false)
    setFilterChips(newFilters)
    applyFilters(newFilters)
  }

  /**
   * Handles changing the selected filter values
   * @param value selected values
   */
  const handleSelect = (value: Array<string>) => {
    let newFilters: any = {}
    let filterField = currentFilter

    if (value.includes('All')) {
      if (filterField in filterValues) {
        // remove all values when all is deselected
        if (filterValues[filterField].length == filters[currentFilter].length) {
          newFilters = { ...filterValues }
          delete newFilters[filterField]
        } else {
          newFilters = { ...filterValues, [filterField]: filters[currentFilter] }
        }
      } else {
        newFilters = { ...filterValues, [filterField]: filters[currentFilter] }
      }
    } else {
      newFilters = { ...filterValues, [filterField]: value }
    }
    setFilterValues(newFilters)
  }

  /**
   * Helper to get the chip label
   * @param filterField
   * @param values selected values
   * @returns string with the chip label
   */
  const getChipLabel = (filterField: string, values: any): string => {
    let chipLabel = formatOption(filterField) + ':'

    if (Array.isArray(values)) {
      for (let i = 0; i < values.length; i++) {
        if (i == 0) {
          chipLabel = chipLabel + ' ' + values[i]
        } else {
          chipLabel = chipLabel + ', ' + values[i]
        }
      }
    } else {
      chipLabel += ` "${values}"`
    }

    return chipLabel
  }

  /**
   * Deletes the filter chip and removes the filter
   * @param filterField
   */
  const deleteFilterChip = (filterField: string): void => {
    let newFilterValues = { ...filterValues }
    delete newFilterValues[filterField]
    setFilterValues(newFilterValues)
    setFilterChips(newFilterValues)
    applyFilters(newFilterValues)
  }

  /**
   * Handles changing the group by of the visualization
   * @param event change event
   */
  const handleGroupByChange = (event: SelectChangeEvent<string>) => {
    let val = event.target.value
    setGroupBy(val)
    if (val == 'state') {
      setTimeRange(frequency == 'hourly' ? 1 : 7)
      setDisabled(true)
    } else {
      setDisabled(false)
    }
  }

  /**
   * Handles changing the metric of the visualization
   * @param event change event
   */
  const handleMetricChange = (event: SelectChangeEvent<string>) => {
    let val = event.target.value
    setMetric(val)
  }

  /**
   * Handles changing the time range of the visualization
   * @param event change event
   */
  const handleTimeRangeChange = (event: SelectChangeEvent<number>) => {
    let val = event.target.value as number
    setTimeRange(val)
  }

  /**
   * Update heading and subheading based on settings
   */
  const updateHeaders = () => {
    let subheading = ''
    switch (id) {
      case 'users':
        subheading = 'Number of unique users '
        switch (metric) {
          case 'daily':
            setDialogHeading('Daily Active Users')
            subheading += 'counted each day'
            break
          case 'monthly':
            setDialogHeading('Monthly Active Users')
            subheading += 'counted in a 30-day sliding window'
            break
        }
        break
      case 'equipment':
        subheading = 'Number of unique devices, gateways and M7/LWAN devices assigned to a site counted'
        break
      case 'exceptions':
        if (timeRange > 1) {
          setDialogHeading('Daily Exceptions')
        } else {
          setDialogHeading('Hourly Exceptions')
        }
        subheading = 'Number of 400/500 status logs counted'
        break
      case 'non-processing':
        if (timeRange > 1) {
          setDialogHeading('Daily Non-Processing Sites')
        } else {
          setDialogHeading('Hourly Non-Processing SItes')
        }
        subheading = 'Number of sites that are not processing counted'
        break
      case 'report':
        subheading = 'Number of reports generated counted'
        break
      case 'active-sites':
        subheading = 'Number of active sites counted'
        break
      case 'duration':
        subheading = 'Average duration of PUT/POST requests within the sites-config stack counted'
    }

    if (groupBy != 'state') {
      switch (timeRange) {
        case 1:
          subheading += ' hourly for the last day'
          break
        case 7:
          subheading += ' each day for the last 7 days'
          break
        case 14:
          subheading += ' each day for the last 14 days'
          break
        case 30:
          subheading += ' each day for the last 30 days'
          break
        case 90:
          subheading += ' each day for the last 90 days'
          break
        case 365:
          subheading += ' each day for the last 1 year'
          break
      }
    } else {
      subheading += ' for the last day'
    }

    if (groupBy != 'none') {
      subheading += ` grouped by ${formatGroupBy(groupBy)}.`
    } else {
      subheading += '.'
    }
    setDialogSubheading(subheading)
  }

  /**
   * Formats the response data to be used in the graph
   * @param data
   */
  const formatData = (data: any, newSettings?: any) => {
    let dataSettings = newSettings ? newSettings : settings
    // If timeRange > 1, show as daily not hourly
    if (dataSettings['timeRange'] > 1 && dataSettings['groupBy'] != 'state') {
      if (dataSettings['groupBy'] == 'none' || dataSettings['groupBy'] == undefined) {
        if (id == 'duration') {
          let seriesData = {
            max: new Array(),
            mean: new Array(),
            min: new Array(),
          }

          for (let i = 0; i < data.length; i++) {
            let day = moment(Object.keys(data[i])[0]).utc().format('YYYY-MM-DD')
            let values: any = Object.values(data[i])[0]

            seriesData.max.push([day, values.max ? values.max : '-'])
            seriesData.mean.push([day, values.mean ? values.mean : '-'])
            seriesData.min.push([day, values.min ? (values.min > 0 ? values.min : 0) : '-'])
          }
          setSeriesData(seriesData)
          setConfidence(true)
        } else {
          let series = new Array()
          for (let i = 0; i < data.length; i++) {
            let day = null
            if (frequency == 'hourly') {
              day = moment(Object.keys(data[i])[0]).local().format('YYYY-MM-DD')
            } else {
              day = moment(Object.keys(data[i])[0]).utc().format('YYYY-MM-DD')
            }
            let value = Object.values(data[i])[0]
            series.push([day, value])
          }
          console.log(series)
          setSeriesData(series)
        }
      } else if (dataSettings['groupBy'] == 'state') {
        setMapData(data)
      } else {
        let seriesData = {} as any
        setConfidence(false)
        for (let i = 0; i < data.length; i++) {
          let day = Object.keys(data[i])[0]
          let fDay = moment(Object.keys(data[i])[0]).utc().format('YYYY-MM-DD')
          let values = Object.keys(data[i][day])

          if (values.length == 0) {
            for (const key of Object.keys(seriesData)) {
              seriesData[key].push([fDay, '-'])
            }
          }

          for (let j = 0; j < values.length; j++) {
            if (seriesData.hasOwnProperty(values[j])) {
              seriesData[values[j]].push([fDay, data[i][day][values[j]]])
            } else {
              seriesData[values[j]] = [[fDay, data[i][day][values[j]]]]
            }
          }
        }
        setSeriesData(seriesData)
      }
    } else {
      if (dataSettings['groupBy'] == 'none' || dataSettings['groupBy'] == undefined) {
        let series = new Array()
        for (let i = 0; i < data.length; i++) {
          let timestamp = moment(Object.keys(data[i])[0]).local().format()
          let value = Object.values(data[i])[0]
          series.push([timestamp, value])
        }
        setSeriesData(series)
        setLoading(false)
      } else if (dataSettings['groupBy'] == 'state') {
        setMapData(data)
      } else {
        let seriesData = {} as any
        for (let i = 0; i < data.length; i++) {
          let timestamp = Object.keys(data[i])[0]
          let fTimestamp = moment(timestamp).local().format()
          let values = Object.keys(data[i][timestamp])

          if (values.length == 0) {
            for (const key of Object.keys(seriesData)) {
              seriesData[key].push([fTimestamp, '-'])
            }
          }

          for (let j = 0; j < values.length; j++) {
            if (seriesData.hasOwnProperty(values[j])) {
              seriesData[values[j]].push([fTimestamp, data[i][timestamp][values[j]]])
            } else {
              seriesData[values[j]] = [[fTimestamp, data[i][timestamp][values[j]]]]
            }
          }
        }
        setSeriesData(seriesData)
      }
    }
    setLoading(false)
    setLoadVis(false)
  }

  /**
   * Gets metric data for dialog
   * @param settings visualization settings (i.e. {metric, timeRange, groupBy})
   * @param filters
   * @returns filtered data
   */
  const getData = async (settings: any, filters: any) => {
    setLoadVis(true)
    let body = { ...params, ...settings, ...filters }
    const response = await POST(endpoint, body)
    setResData(response)
    return response
  }

  const formatFilters = (filters: any) => {
    let newFilters = { ...filters }
    if (id == 'users') {
      if ('Employee/Customer' in filters) {
        let vals = filters['Employee/Customer']
        delete newFilters['Employee/Customer']
        newFilters['affinity'] = vals
      }
      return newFilters
    } else if (id == 'active-sites' || id == 'equipment') {
      if ('Internal/External' in filters) {
        let vals = filters['Internal/External']
        delete newFilters['Internal/External']
        newFilters['internal'] = vals
      }

      return newFilters
    } else if (id == 'report') {
      if ('User/Archive' in filters) {
        let vals = filters['User/Archive']
        delete newFilters['User/Archive']
        newFilters['archive'] = vals
      }

      return newFilters
    } else {
      return filters
    }
  }

  /**
   * Helper function to apply filters to the data visualization
   * @param filters
   */
  const applyFilters = (filters: any) => {
    let newFilters = formatFilters(filters)
    getData(settings, newFilters).then((res) => {
      formatData(res.docs)
    })
    // if (id == 'users') {
    //   let newFilters = { ...filters }
    //   if ('Employee/Customer' in filters) {
    //     let vals = filters['Employee/Customer']
    //     delete newFilters['Employee/Customer']
    //     newFilters['affinity'] = vals
    //   }
    //   getData(settings, newFilters).then((res) => {
    //     formatData(res.docs)
    //   })
    // } else if (id == 'active-sites' || id == 'equipment') {
    //   let newFilters = { ...filters }
    //   if ('Internal/External' in filters) {
    //     let vals = filters['Internal/External']
    //     delete newFilters['Internal/External']
    //     newFilters['internal'] = vals
    //   }
    //   getData(settings, newFilters).then((res) => {
    //     formatData(res.docs)
    //   })
    // } else if (id == 'report') {
    //   let newFilters = { ...filters }
    //   if ('User/Archive' in filters) {
    //     let vals = filters['User/Archive']
    //     delete newFilters['User/Archive']
    //     newFilters['archive'] = vals
    //   }
    //   getData(settings, newFilters).then((res) => {
    //     formatData(res.docs)
    //   })
    // } else {
    //   getData(settings, filters).then((res) => {
    //     formatData(res.docs)
    //   })
    // }
  }

  /**
   * Apply settings to the data visualization
   */
  const applySettings = () => {
    updateHeaders()
    let newSettings = showMetric ? ({ timeRange: timeRange, metric: metric } as any) : { timeRange: timeRange }

    if (groupBy != 'none') {
      newSettings['groupBy'] = groupBy
      if (groupBy == 'Employee/Customer') {
        newSettings['groupBy'] = 'affinity'
      }
      if (groupBy == 'Internal/External') {
        newSettings['groupBy'] = 'internal'
      }
      if (groupBy == 'User/Archive') {
        newSettings['groupBy'] = 'archive'
      }
      if (groupBy == 'state') {
        setMap(true)
      } else {
        setMap(false)
      }
    } else {
      setMap(false)
    }

    setSettings(newSettings)
    getData(newSettings, formatFilters(filterChips)).then((res) => {
      formatData(res.docs, newSettings)
    })
  }

  /**
   * Formats group by name to correct casing
   * @param groupBy
   * @returns group by string
   */
  const formatGroupBy = (groupBy: string) => {
    if (groupBy == 'Employee/Customer') {
      return 'employee/customer'
    } else if (groupBy == 'Internal/External') {
      return 'internal/external'
    } else if (groupBy == 'User/Archive') {
      return 'user/archive'
    }
    let res = ''
    for (let i = 0; i < groupBy.length; i++) {
      let char = groupBy.charAt(i)

      if (i == 0) {
        res += char
      } else {
        if (char == char.toUpperCase()) {
          res += ' '
          res += char.toLowerCase()
        } else {
          res += char
        }
      }
    }
    return res
  }

  /**
   * Format option to correct casing
   * @param option
   * @returns option string
   */
  const formatOption = (option: string) => {
    if (option == 'Employee/Customer') {
      return option
    }
    if (option == 'Internal/External') {
      return option
    }
    if (option == 'User/Archive') {
      return option
    }
    let fOption = capitalize(option)
    let res = ''
    for (let i = 0; i < fOption.length; i++) {
      let char = fOption.charAt(i)

      if (i == 0) {
        res += char
      } else {
        if (char == char.toUpperCase()) {
          res += ' '
          res += char
        } else {
          res += char
        }
      }
    }
    return res
  }

  /**
   * Formats data to excel format and creates an excel workbook
   * @returns excel workbook
   */
  const formatExcelData = (): string => {
    let docs = resData.docs

    let workbook = new ExcelJS.Workbook()
    workbook.creator = 'WEDGE'

    let worksheet = workbook.addWorksheet(`${id}`)
    worksheet.properties.defaultColWidth = 13

    // if grouped
    if ('groupBy' in settings) {
      if (settings['groupBy'] == 'state') {
        let headers = new Array()
        headers.push('State')
        headers.push(moment(new Date()).local().subtract(1, 'days').format('DD/MM/YYYY'))
        let hRow = worksheet.addRow(headers)
        hRow.alignment = { horizontal: 'center' }
        for (const doc of docs) {
          let state = Object.keys(doc)[0]
          let row = [state, doc[state]]
          worksheet.addRow(row)
        }
        worksheet.getColumn(1).font = { bold: true }
        worksheet.getColumn(1).width = 20
      } else if (settings['groupBy'] != 'none') {
        let groupBy =
          settings['groupBy'] == 'affinity'
            ? 'Employee/Customer'
            : settings['groupBy'] == 'internal'
            ? 'Internal/External'
            : settings['groupBy']
        let options = filters[groupBy]
        let headers = new Array()
        headers.push(formatOption(groupBy))
        let rows = {} as any
        for (const option of options) {
          rows[option] = new Array()
          rows[option].push(option)
        }

        docs.forEach((doc) => {
          let date = Object.keys(doc)[0]
          let fDate = moment(date).local().format('DD/MM HH:mm')
          if (timeRange > 1) {
            fDate = moment.utc(date).format('DD/MM/YYYY')
          }
          headers.push(fDate)

          for (const option of options) {
            if (option in doc[date]) {
              rows[option].push(doc[date][option])
            } else {
              rows[option].push(0)
            }
          }
        })

        let hRow = worksheet.addRow(headers)
        hRow.alignment = { horizontal: 'center' }

        for (const [key, value] of Object.entries(rows)) {
          worksheet.addRow(value)
        }

        worksheet.getColumn(1).font = { bold: true }
        worksheet.getColumn(1).width = 20
      }
    } else {
      if (id == 'duration') {
        let headers = new Array()
        headers.push('Date')
        let lower = new Array()
        lower.push('Lower')
        let mean = new Array()
        mean.push('Mean')
        let upper = new Array()
        upper.push('Upper')

        for (const doc of docs) {
          let date = Object.keys(doc)[0]
          let fDate = moment(date).local().format('DD/MM/YYYY')
          headers.push(fDate)
          lower.push(doc[date].lower ? doc[date].lower : 'null')
          mean.push(doc[date].mean ? doc[date].mean : 'null')
          upper.push(doc[date].upper ? doc[date].upper : 'null')
        }
        let hRow = worksheet.addRow(headers)
        hRow.alignment = { horizontal: 'center' }

        worksheet.addRow(mean)
        worksheet.addRow(lower)
        worksheet.addRow(upper)
      } else {
        let headers = new Array()
        headers.push('Date')
        let values = new Array()
        values.push(capitalize(id))
        for (const doc of docs) {
          let date = Object.keys(doc)[0]
          let fDate = moment(date).local().format('DD/MM HH:mm')
          if (timeRange > 1) {
            fDate = moment.utc(date).format('DD/MM/YYYY')
          }
          headers.push(fDate)
          values.push(doc[date])
        }

        let hRow = worksheet.addRow(headers)
        worksheet.addRow(values)

        hRow.alignment = { horizontal: 'center' }
      }
    }
    return workbook
  }

  /**
   * Formats widget data for downloading
   * @returns excel workbook or json string
   */
  const formatResData = (): any => {
    let formattedData = ''
    if (resData) {
      switch (fileType) {
        case 'json':
          formattedData = JSON.stringify(resData)
          break
        case 'xlsx':
          formattedData = formatExcelData()
          break
        default:
          break
      }
    }
    return formattedData
  }

  /**
   * Download data within widget
   */
  const downloadData = async () => {
    let data = formatResData()
    if (fileType == 'xlsx') {
      data = await data.xlsx.writeBuffer()
    }
    const blob =
      fileType == 'json'
        ? new Blob([data], { type: 'application/json' })
        : new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' })

    const downloadLink = document.createElement('a')
    downloadLink.href = URL.createObjectURL(blob)

    let date = moment(new Date()).format('YYYY_MM_DD')
    let fileName = heading.replace(' ', '-').toLowerCase() + '_' + date
    downloadLink.download = fileType == 'json' ? `${id}_metrics_${date}.json` : `${id}_metrics_${date}.xlsx`

    // Append the link to the body and click it to trigger the download
    document.body.appendChild(downloadLink)
    downloadLink.click()

    // Clean up by removing the link
    document.body.removeChild(downloadLink)
  }

  useEffect(() => {
    if (open) {
      setLoading(true)
      const getOptions = async () => {
        const response = await GET(options)
        return response
      }
      const getData = async () => {
        const response = await POST(endpoint, params)
        setResData(response)
        return response
      }

      getOptions().then((filters: any) => {
        setGroupByOptions(Object.keys(filters))
        setFilters(filters)

        // add employee/customer to users
        if (id == 'users') {
          let options = Object.keys(filters)
          options = options.filter((option) => option != 'affinity')
          options.push('Employee/Customer')
          setGroupByOptions(options)
          let affinityOptions = filters['affinity']
          let newFilters = { ...filters, 'Employee/Customer': affinityOptions }
          delete newFilters['affinity']
          setFilters(newFilters)
        }
        // add internal/external to sites/equipment
        else if (id == 'active-sites' || id == 'equipment') {
          let options = Object.keys(filters)
          options = options.filter((option) => option != 'internal')
          options.push('Internal/External')
          setGroupByOptions(options)
          let internalOptions = filters['internal']
          let newFilters = { ...filters, 'Internal/External': internalOptions }
          delete newFilters['internal']
          setFilters(newFilters)
        }
        // add user/archive to report
        else if (id == 'report') {
          let options = Object.keys(filters)
          options = options.filter((option) => option != 'archive')
          options.push('User/Archive')
          setGroupByOptions(options)
          let archiveOptions = filters['archive']
          let newFilters = { ...filters, 'User/Archive': archiveOptions }
          delete newFilters['archive']
          setFilters(newFilters)
        }
        getData().then((data: any) => {
          formatData(data.docs)
        })
      })
    }
  }, [open])

  return (
    <Dialog
      open={open}
      onClose={() => {
        resetValues()
        onClose()
      }}
      fullWidth={true}
      maxWidth="xl"
    >
      <IconButton
        aria-label="close"
        onClick={() => {
          resetValues()
          onClose()
        }}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
        }}
      >
        <Close />
      </IconButton>
      <DialogContent>
        {loading ? (
          <Grid container direction="row" justifyContent={'center'} alignItems={'center'}>
            <CircularProgress size={28} color="secondary" />
          </Grid>
        ) : (
          <Grid container direction="row" mt={1}>
            <Grid item width="15%">
              <Stack>
                <Typography variant="h6" fontWeight={500}>
                  Settings
                </Typography>
                <Typography variant="subtitle1">Visualization settings</Typography>
                {showMetric && (
                  <FormControl key={'metric'} fullWidth sx={{ marginTop: '15px' }}>
                    <InputLabel
                      id={'metric'}
                      variant="outlined"
                      color={theme.palette.mode == 'dark' ? 'primary' : 'secondary'}
                    >
                      Metric
                    </InputLabel>
                    <Select
                      key="metric-select"
                      labelId={'metric'}
                      label={'Metric'}
                      value={metric}
                      onChange={handleMetricChange}
                      color={theme.palette.mode == 'dark' ? 'primary' : 'secondary'}
                      MenuProps={MenuProps}
                    >
                      <MenuItem value="daily" key="daily">
                        Daily
                      </MenuItem>
                      <MenuItem value="monthly" key="monthly">
                        Monthly
                      </MenuItem>
                    </Select>
                  </FormControl>
                )}
                {showTimeRange && (
                  <FormControl key={'time-range'} fullWidth sx={{ marginTop: '15px' }}>
                    <InputLabel
                      id={'time-range-label'}
                      variant="outlined"
                      color={theme.palette.mode == 'dark' ? 'primary' : 'secondary'}
                    >
                      Time Range
                    </InputLabel>
                    <Select
                      key="time-range-select"
                      labelId={'time-range-label'}
                      label={'Time Range'}
                      value={timeRange}
                      onChange={handleTimeRangeChange}
                      color={theme.palette.mode == 'dark' ? 'primary' : 'secondary'}
                      MenuProps={MenuProps}
                      disabled={disabled}
                    >
                      {frequency == 'hourly' && (
                        <MenuItem value={1} key={1}>
                          1 day
                        </MenuItem>
                      )}
                      <MenuItem value={7} key={7}>
                        7 days
                      </MenuItem>
                      <MenuItem value={30} key={30}>
                        30 days
                      </MenuItem>
                      <MenuItem value={90} key={90}>
                        90 days
                      </MenuItem>
                      <MenuItem value={365} key={365}>
                        1 year
                      </MenuItem>
                    </Select>
                  </FormControl>
                )}
                <FormControl key={'group-by'} fullWidth sx={{ marginTop: '15px' }}>
                  <InputLabel
                    id={'group-by-label'}
                    variant="outlined"
                    color={theme.palette.mode == 'dark' ? 'primary' : 'secondary'}
                  >
                    Group By
                  </InputLabel>
                  <Select
                    key="group-by-select"
                    labelId={'group-by-label'}
                    label={'Group By'}
                    value={groupBy}
                    onChange={handleGroupByChange}
                    color={theme.palette.mode == 'dark' ? 'primary' : 'secondary'}
                    MenuProps={MenuProps}
                  >
                    <MenuItem value="none">None</MenuItem>
                    {groupByOptions.map((option) => (
                      <MenuItem value={option} key={option}>
                        {formatOption(option)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <Button variant="contained" onClick={applySettings} sx={{ marginTop: '15px' }}>
                  Apply
                </Button>
                <Typography variant="h6" fontWeight={500} sx={{ marginTop: '15px' }}>
                  Download
                </Typography>
                <Typography variant="subtitle1">Download metric data</Typography>
                <FormControl key={'file-type'} fullWidth sx={{ marginTop: '15px' }}>
                  <InputLabel
                    id={'file-type-label'}
                    variant="outlined"
                    color={theme.palette.mode == 'dark' ? 'primary' : 'secondary'}
                  >
                    File Type
                  </InputLabel>
                  <Select
                    key="group-by-select"
                    labelId={'file-type-label'}
                    label={'File Type'}
                    displayEmpty
                    value={fileType}
                    onChange={(e) => {
                      setFileType(e.target.value)
                    }}
                    color={theme.palette.mode == 'dark' ? 'primary' : 'secondary'}
                    MenuProps={MenuProps}
                  >
                    <MenuItem value="xlsx">XLSX</MenuItem>
                    <MenuItem value="json">JSON</MenuItem>
                  </Select>
                </FormControl>
                <Button
                  variant="contained"
                  disabled={!fileType}
                  onClick={() => {
                    downloadData().then(() => {
                      newNotificationMessage('File Downloaded')
                    })
                  }}
                  sx={{ marginTop: '15px' }}
                  endIcon={<Download />}
                >
                  Download
                </Button>
              </Stack>
            </Grid>
            <Grid ml={3} item key="visualization" width="80%">
              <Stack>
                <Typography variant="h5" fontWeight={500}>
                  {dialogHeading}
                </Typography>
                <Typography variant="subtitle1">{dialogSubheading}</Typography>
                <div style={{ display: 'flex' }}>
                  <IconButton
                    value="filter"
                    onClick={() => {
                      anchorEl?.click()
                    }}
                    size="large"
                  >
                    <Tooltip id="filter-tip" title="Filter">
                      <FilterList />
                    </Tooltip>
                  </IconButton>
                  <div style={{ margin: 'auto' }}>
                    {Object.keys(filterChips).map((filterField) => {
                      return (
                        <Chip
                          key={filterField}
                          className={classes.chip}
                          sx={{ marginLeft: '5px', maxWidth: '350px' }}
                          label={getChipLabel(filterField, filterChips[filterField])}
                          variant="outlined"
                          onDelete={() => deleteFilterChip(filterField)}
                          clickable
                          onClick={(e) => {
                            setCurrentFilter(filterField)
                            setAnchorEl(e.currentTarget)
                            handleSelectFilter(filterField)
                          }}
                        />
                      )
                    })}
                  </div>
                  <InputBase
                    style={{ flex: 1, marginLeft: 6 }}
                    placeholder={`Filter`}
                    onClick={(e) => {
                      setFilterOpen(true)
                      setAnchorEl(e.currentTarget)
                    }}
                    inputRef={(node) => setAnchorEl(node)}
                    id="input-base"
                    value={''}
                    onChange={(e) => {}}
                  />
                  <Popper
                    placement="bottom-start"
                    open={filterOpen}
                    anchorEl={anchorEl}
                    transition
                    disablePortal
                    style={{ zIndex: 9999 }}
                  >
                    {({ TransitionProps, placement }) => (
                      <Grow
                        {...TransitionProps}
                        style={{
                          transformOrigin: placement === 'bottom' ? 'left' : 'left',
                          zIndex: 9999,
                          width: '100%',
                        }}
                      >
                        <Paper
                          style={{
                            maxHeight: '250px',
                            overflow: 'hidden',
                            display: 'flex',
                            alignItems: 'center',
                            width: '100%',
                          }}
                        >
                          <ClickAwayListener onClickAway={() => setFilterOpen(false)}>
                            <MenuList
                              sx={{
                                width: '100%',
                                maxHeight: '250px',
                                overflowY: 'scroll',
                                boxSizing: 'content-box',
                              }}
                              className="scroll-list"
                            >
                              {Object.keys(filters).map((filter) => {
                                return (
                                  <MenuItem
                                    key={filter}
                                    onClick={() => {
                                      handleSelectFilter(filter)
                                    }}
                                  >
                                    {formatOption(filter)}
                                  </MenuItem>
                                )
                              })}
                            </MenuList>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
                  <Popper
                    placement="bottom-start"
                    open={filterOptionsOpen}
                    anchorEl={anchorEl}
                    transition
                    disablePortal
                    style={{ zIndex: 999 }}
                  >
                    {({ TransitionProps, placement }) => (
                      <Grow
                        {...TransitionProps}
                        style={{
                          transformOrigin: placement === 'bottom' ? 'left' : 'left',
                          zIndex: 9999,
                          width: '100%',
                        }}
                      >
                        <Paper style={{ minHeight: '200px', minWidth: '275px', maxWidth: '500px' }}>
                          <ClickAwayListener
                            onClickAway={() => {
                              setFilterOptionsOpen(false)
                              setFilterValues(filterChips)
                            }}
                            mouseEvent="onMouseUp"
                          >
                            <div>
                              <DialogTitle id="filter-title" sx={{ padding: '16px' }}>
                                <Box display="flex" alignItems="center" padding={0}>
                                  <Box flexGrow={1}>{formatOption(currentFilter)}</Box>
                                  <Box>
                                    <IconButton
                                      onClick={() => {
                                        setFilterOptionsOpen(false)
                                        setFilterValues(filterChips)
                                      }}
                                      size="large"
                                    >
                                      <Close htmlColor={theme.palette.mode == 'dark' ? 'white' : 'black'} />
                                    </IconButton>
                                  </Box>
                                </Box>
                              </DialogTitle>
                              <Divider />
                              <DialogContent style={{ margin: 0, padding: '10px' }}>
                                <Box component="form" onSubmit={handleApplyFilters}>
                                  <FormControl style={{ minWidth: '275px', maxWidth: '500px', marginTop: '10px' }}>
                                    <Autocomplete
                                      multiple
                                      disableCloseOnSelect
                                      disableListWrap
                                      openOnFocus
                                      options={currentFilter ? prepend('All', filters[currentFilter]) : []}
                                      value={currentFilter in filterValues ? filterValues[currentFilter] : []}
                                      onChange={(event, value: any) => {
                                        handleSelect(value)
                                      }}
                                      renderOption={(props, option, { selected }) => (
                                        <li {...props}>
                                          <Checkbox
                                            color={theme.palette.mode == 'dark' ? 'primary' : 'secondary'}
                                            checked={
                                              option == 'All'
                                                ? currentFilter in filterValues &&
                                                  filterValues[currentFilter].length == filters[currentFilter].length
                                                : selected
                                            }
                                          />
                                          {option}
                                        </li>
                                      )}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          label={formatOption(currentFilter)}
                                          variant="outlined"
                                          color="secondary"
                                        />
                                      )}
                                    />
                                  </FormControl>
                                  <div style={{ display: 'flex', justifyContent: 'right', margin: '10px' }}>
                                    <Button
                                      variant="text"
                                      color={theme.palette.mode === 'dark' ? 'primary' : 'secondary'}
                                      type="submit"
                                    >
                                      Apply
                                    </Button>
                                  </div>
                                </Box>
                              </DialogContent>
                            </div>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
                  <IconButton disabled={Object.keys(filterChips).length == 0} size="large" onClick={removeAllFilters}>
                    <Tooltip id="clear-button" title="Clear all">
                      <Close />
                    </Tooltip>
                  </IconButton>
                </div>
                <Divider />
                <div style={{ width: '100%', height: '55vh' }}>
                  {variant == 'graph' && !map && !confidence && (
                    <Graph
                      name={capitalize(id)}
                      yAxisTitle={capitalize(id) + ' Count'}
                      seriesData={seriesData}
                      loading={loadVis}
                      frequency={frequency}
                      timeRange={settings.timeRange}
                    />
                  )}
                  {confidence && (
                    <ConfidenceBand
                      name={capitalize(id)}
                      yAxisTitle={capitalize(id) + ' Count'}
                      seriesData={seriesData}
                      loading={loadVis}
                      frequency={frequency}
                      timeRange={settings.timeRange}
                    />
                  )}
                  {map && <Map name={capitalize(id)} data={mapData} loading={loadVis} />}
                </div>
              </Stack>
            </Grid>
          </Grid>
        )}
      </DialogContent>
    </Dialog>
  )
}

export default connect(null, mapDispatchToProps)(WidgetDialog)
