import ReactEchartsCore from 'echarts-for-react/lib/core'
import echarts from 'echarts/lib/echarts'
import 'echarts/lib/chart/tree'
import 'echarts/lib/component/tooltip'
import React from 'react'
import { useTheme } from '@mui/material'
interface IProps {
  data: any[]
  onClickHandler: (event: any) => void
}

export function TraceTree({ data, onClickHandler }: IProps) {
  const theme = useTheme()
  function numberWithCommas(x: { toString: () => string }) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }
  return (
    <>
      <ReactEchartsCore
        style={{ height: '400px', color: 'green' }}
        onEvents={{ click: onClickHandler }}
        echarts={echarts}
        option={{
          tooltip: {
            trigger: 'item',
            triggerOn: 'mousemove',
            formatter: (item: any) => (item ? item.name + ' - ' + numberWithCommas(item.data.duration) + 'ms' : ''),
            transitionDuration: 0,
          },
          series: [
            {
              type: 'tree',
              data: [data],

              top: '5%',
              left: '15%',
              bottom: '5%',
              right: '15%',

              symbolSize: 7,

              initialTreeDepth: 5,

              label: {
                position: 'left',
                verticalAlign: 'middle',
                align: 'right',
                fontSize: 9,
                color: theme.palette.mode == 'dark' ? '#fffffff' : '#000000',
              },

              leaves: {
                label: {
                  position: 'right',
                  verticalAlign: 'middle',
                  align: 'left',
                },
              },
              expandAndCollapse: false,
              animationDuration: 550,
              animationDurationUpdate: 750,
            },
          ],
        }}
      />
    </>
  )
}
