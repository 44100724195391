/* eslint-disable no-unused-vars */
import { useTheme } from '@mui/material/styles'
import { CircularProgress, Divider, Paper, Typography } from '@mui/material'
import moment from 'moment'
import React from 'react'
var AU = require('ansi_up')
var ansi_up = new AU.default()

interface IProps {
  events:
    | {
        ingestionTime: number
        message: string
        timestamp: number
      }[]
    | undefined
  loading: boolean
}

export function LogStream({ events, loading }: IProps) {
  const theme = useTheme()

  return (
    <Paper
      elevation={2}
      style={{
        padding: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
          paddingLeft: theme.spacing(3),
          paddingRight: theme.spacing(3),
        },
        height: 510,
        display: 'flex',
        flexDirection: 'column',
        wordBreak: 'break-all',
        overflowY: 'auto',
      }}
    >
      {loading ? (
        <CircularProgress style={{ alignSelf: 'center' }} color="inherit" />
      ) : !events || !events.length ? (
        <div style={{ textAlign: 'center', height: '100%', paddingTop: '15%' }}>Select a row from the table</div>
      ) : (
        events.map(({ message }, idx) => {
          let body = ''
          const splitMessages = message.split('\t')

          if (splitMessages.length !== 4) {
            return (
              <div key={idx}>
                <pre>{splitMessages[0]}</pre>
                <Divider />
              </div>
            )
          } else  {
            const [timestamp, requestId, logtype, ...splitBody] = splitMessages
            const momentTimestamp = moment(timestamp)
            const formattedTimestamp = `${moment
              .utc(momentTimestamp)
              .format('YYYY-MM-DD HH:mm:ss.SSS')}${momentTimestamp.format('Z')}`
            body = splitBody.join('\t')
            return (
              <div key={idx}>
                <pre style={{ display: 'inline' }}>{`${formattedTimestamp}\t${logtype}\t`}</pre>
                <pre style={{ display: 'inline' }} dangerouslySetInnerHTML={{ __html: ansi_up.ansi_to_html(body) }} />
                <Divider />
              </div>
            )
          }
        })
      )}
    </Paper>
  )
}
