import { actionTypes } from '../constants/action-types'

export const newNotificationMessage = (message: string) => ({
  type: actionTypes.NEW_NOTIFICATION_MESSAGE,
  payload: message,
})
export const newMqttConnection = (userConnectionCredentails: any) => ({
  type: actionTypes.CONNECT_TO_MQTT,
  payload: userConnectionCredentails,
})
export const subscribeToDefaultTopic = (topic: string) => ({
  type: actionTypes.SUBSCRIBE_TO_DEFAULT_TOPIC,
  payload: topic,
})
export const openNotification = (flag: boolean) => ({
  type: actionTypes.OPEN_NOTIFICATION,
  payload: flag,
})
export const closeNotification = (flag: boolean) => ({
  type: actionTypes.CLOSE_NOTIFICATION,
  payload: flag,
})
export const publishToTopic = (message: any) => ({
  type: actionTypes.PUBLISH_TO_MQTT,
  payload: message,
})
export const subscribeToTopic = (topic: string) => ({
  type: actionTypes.SUBSCRIBE_TO_TOPIC,
  payload: topic,
})
export const saveCagInventory = (inventoryArray: any[]) => ({
  type: actionTypes.SAVE_CAG_INVENTORY,
  payload: inventoryArray,
})
export const saveDeviceInventory = (inventoryArray: any[]) => ({
  type: actionTypes.SAVE_DEVICE_INVENTORY,
  payload: inventoryArray,
})
export const saveAsyncDeviceInventory = (inventoryArray: any[]) => ({
  type: actionTypes.SAVE_ASYNC_DEVICE_INVENTORY,
  payload: inventoryArray,
})
export const saveSiteList = (siteList: any[]) => ({
  type: actionTypes.SAVE_SITE_LIST,
  payload: siteList,
})
export const saveErrorList = (errorList: any[]) => ({
  type: actionTypes.SAVE_ERROR_LIST,
  payload: errorList,
})
export const saveKPI = (kpi: any) => ({
  type: actionTypes.SAVE_KPI,
  payload: kpi,
})
export const setDrawerMenuItem = (value: string) => ({
  type: actionTypes.SET_DRAWER_MENU_ITEM,
  payload: value,
})
