import React, { useEffect, useState } from 'react'
import WedgeTable from '../../../components/Table/WedgeTable'
import WedgeTableWithData from '../../../components/Table/WedgeTableWithData'
import { ISite, IWedgeTableProps } from '../../../components/Table/constants/TableInterfaces'
import {
  siteConfigChangesColumns,
  siteAlertsColumns,
  siteNotifsColumns,
  siteDeviceDocsColumns,
  siteGapElimColumns,
} from '../../../components/Table/constants/TableData'
import { styled } from '@mui/material/styles'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  Typography,
} from '@mui/material'
import store from '../../../store/index'
import { setDrawerMenuItem } from '../../../actions/index'
import { useLocation } from 'react-router-dom'
import { POST, POST_ENDPOINT } from '../../../api-request/'
import { GET_SITE } from '../../../constants/end-points'
import moment from 'moment'

const PREFIX = 'device-details'

const classes = {
  root: `${PREFIX}-root`,
  wrapper: `${PREFIX}-wrapper`,
  buttonProgress: `${PREFIX}-buttonProgress`,
  paper: `${PREFIX}-paper`,
}

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
  },

  [`& .${classes.wrapper}`]: {
    margin: theme.spacing(1),
    position: 'relative',
  },

  [`& .${classes.buttonProgress}`]: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },

  [`& .${classes.paper}`]: {
    maxWidth: 600,
    marginLeft: theme.spacing(2),
    padding: theme.spacing(2),
  },
}))

const Details = () => {
  const location = useLocation<ISite>()
  const [siteName, setSiteName] = useState('')
  const [company, setCompany] = useState('')
  const [created, setCreated] = useState('')
  const [archived, setArchived] = useState('')
  const [timeSpan, setTimeSpan] = useState('')
  const [isLwan, setIsLwan] = useState(false)
  const [dialogOpen, setDialogOpen] = useState(false)
  const [dialog, setDialog] = useState({
    dialogTitle: '',
    dialogContent: '',
    dialogPrimaryActionText: '',
    dialogPrimaryAction: () => {},
  })
  const [errMsg, setErrMsg] = useState('')
  const siteId = window.location.pathname.split('/')[2]

  const configChangesProps: IWedgeTableProps = {
    tHeader: 'Configuration Changes',
    tName: 'configuration changes',
    tColumns: siteConfigChangesColumns,
    optionsEndpoint: `sites/${siteId}/changeLogs/options`,
    searchEndpoint: `sites/${siteId}/changeLogs/search`,
    handleSelectRow: () => {},
  }

  const siteAlertsProps: IWedgeTableProps = {
    tHeader: 'Site Alerts',
    tName: 'alerts',
    tColumns: siteAlertsColumns,
    optionsEndpoint: `alerts/${siteId}/alerts/options`,
    searchEndpoint: `alerts/${siteId}/alerts/search`,
    handleSelectRow: () => {},
  }

  const notificationProps: IWedgeTableProps = {
    tHeader: 'Notifications',
    tName: 'notifications',
    tColumns: siteNotifsColumns,
    optionsEndpoint: `users/notifs-by-site/${siteId}/options`,
    searchEndpoint: `users/notifs-by-site/${siteId}/search`,
    handleSelectRow: () => {},
  }

  const deviceDocProps: IWedgeTableProps = {
    tHeader: 'Device Docs',
    tName: 'device docs',
    tColumns: siteDeviceDocsColumns,
    optionsEndpoint: `data/device-docs/${siteId}/options`,
    searchEndpoint: `data/device-docs/${siteId}/search`,
    handleSelectRow: () => {},
  }

  const gapElimProps: IWedgeTableProps = {
    tHeader: 'Gap Elimination',
    tName: 'gap elimination',
    tColumns: siteGapElimColumns,
    optionsEndpoint: `data/data-gaps/${siteId}/options`,
    searchEndpoint: `data/data-gaps/${siteId}/search`,
    handleSelectRow: () => {},
  }

  const updateDialog = (action: string) => {
    switch (action) {
      case 'reset':
        setDialog({
          dialogTitle: 'Reset Devices',
          dialogContent: 'Reset all devices on the site.',
          dialogPrimaryActionText: 'Reset',
          dialogPrimaryAction: resetDevices,
        })
        setDialogOpen(true)
        break
      case 'setReportingInterval':
        setDialog({
          dialogTitle: 'Update Reporting Interval',
          dialogContent: 'Update reporting interval for all devices on the site.',
          dialogPrimaryActionText: 'Update',
          dialogPrimaryAction: setReportingInterval,
        })
        setDialogOpen(true)
        break
      default:
        break
    }
  }

  const resetDevices = () => {
    try {
      POST(`devices/siteId/${siteId}/lwan-reset-devices`, {}).then((res) => {
        console.log(res)
        setDialogOpen(false)
      })
    } catch (err) {
      console.log(err)
      setErrMsg('Reset Devices Failed')
    }
  }

  const setReportingInterval = () => {
    try {
      POST(`devices/siteId/${siteId}/set-reporting-interval`, {}).then((res) => {
        console.log(res)
        setDialogOpen(false)
      })
    } catch (err) {
      console.log(err)
      setErrMsg('Set Reporting Interval Failed')
    }
  }

  useEffect(() => {
    store.dispatch(setDrawerMenuItem('sites'))
    let createdDate = ''
    let archivedDate = ''
    let time = ''

    // handles when location state is not set
    if (typeof location.state === 'undefined' || typeof location.state.name === 'undefined') {
      POST_ENDPOINT(GET_SITE, {
        siteId: siteId,
      }).then((result) => {
        // if location is undefined
        if (result.docs && result.docs.length > 0) {
          let siteState = result.docs[0]
          location.state = result.docs[0]

          setSiteName(location.state.name)
          setCompany(location.state.company)
          setCreated(location.state.created)
          setArchived(location.state.archived)
          setIsLwan(location.state.isLwan)

          createdDate = moment(siteState.created).local().format('MMM D, YYYY')
          archivedDate = siteState.archived ? moment(siteState.archived).local().format('MMM D, YYYY') : 'Present'
          time = createdDate + ' - ' + archivedDate
          setTimeSpan(time)
        }
      })
    } else if (location) {
      setSiteName(location.state.name)
      setCompany(location.state.company)
      setCreated(location.state.created)
      setArchived(location.state.archived)
      setIsLwan(location.state.isLwan)

      createdDate = moment(location.state.created).local().format('MMM D, YYYY')
      archivedDate = location.state.archived ? moment(location.state.archived).local().format('MMM D, YYYY') : 'Present'
      time = createdDate + ' - ' + archivedDate
      setTimeSpan(time)
    } else {
      setSiteName('')
      setCompany('')
      setCreated('')
      setArchived('')
      setIsLwan(false)
      time = createdDate + ' - ' + archivedDate
      setTimeSpan(time)
    }
  }, [])

  return (
    <Root className={'divBg'}>
      <div style={{ marginBottom: '20px' }}>
        <Typography variant="h4">
          <strong>{siteName ? siteName : '...'}</strong>
        </Typography>
        <Typography variant="h5">{company ? company : '...'}</Typography>
        <Typography variant="h5">{timeSpan ? timeSpan : '...'}</Typography>
      </div>
      {isLwan && (
        <Stack style={{ marginBottom: '20px' }} spacing={2} direction={'row'}>
          <Button color="primary" variant="contained" onClick={() => updateDialog('reset')}>
            Reset Devices
          </Button>
          <Button color="primary" variant="contained" onClick={() => updateDialog('setReportingInterval')}>
            Set Reporting Interval
          </Button>
        </Stack>
      )}
      <WedgeTableWithData {...configChangesProps} />
      <div style={{ marginTop: '50px' }}>
        <WedgeTable {...siteAlertsProps} />
      </div>
      <div style={{ marginTop: '50px' }}>
        <WedgeTableWithData {...notificationProps} />
      </div>
      <div style={{ marginTop: '50px' }}>
        <WedgeTableWithData {...deviceDocProps} />
      </div>
      {isLwan && (
        <div style={{ marginTop: '50px' }}>
          <WedgeTableWithData {...gapElimProps} />
        </div>
      )}
      <Dialog open={dialogOpen}>
        <DialogTitle>{dialog.dialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{dialog.dialogContent}</DialogContentText>
          {errMsg && <DialogContentText>{errMsg}</DialogContentText>}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)} variant="contained" color="primary">
            Close
          </Button>
          <Button onClick={() => dialog.dialogPrimaryAction()} variant="contained" color="warning">
            {dialog.dialogPrimaryActionText}
          </Button>
        </DialogActions>
      </Dialog>
    </Root>
  )
}

export default Details
