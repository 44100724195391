import React from 'react'
import styled from '@emotion/styled'

interface TextMainProps {
  children: React.ReactNode
}

export const TextMain = (props: TextMainProps) => {
  return <StyledText>{props.children}</StyledText>
}

const StyledText = styled.p`
  margin: 0;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  display: block;
`
