import defaultConfig from './config.json';

const config = {
  dev: {
    AwsRegion: 'us-west-2',
    UserPoolId: 'us-west-2_TiZPkjKdb',
    IdentityPoolId: 'us-west-2:3f7803bb-cb08-4d77-bc25-63a1d81758b3',
    IotEndPoint: 'a1uukk7lei9dd1-ats.iot.us-west-2.amazonaws.com',
    StageApiEndPointUrl: 'https://dev.wedgeapi.com/',
    ConsoleAppClientId: '151ddepnfvujs6789iv32h4499',
    DeployedStage: 'dev',
    Domain: 'login.dev.wedgeapi.com',
    Redirect: 'https://console.dev.wedgeapi.com/'
  },
  prod: {
    AwsRegion: 'us-west-2',
    DeployedStage: 'prod',
    UserPoolId: 'us-west-2_vGVt4BTcv',
    ConsoleAppClientId: '2b5g43oa8350d52692v02fnf1r',
    IdentityPoolId: 'us-west-2:69a0e580-30f6-4478-8b1a-036870587165',
    IotEndPoint: 'a2u7ymkqblb8nk-ats.iot.us-west-2.amazonaws.com',
    StageApiEndPointUrl: 'https://wedgeapi.com/',
    Domain: 'login.wedgeapi.com',
    Redirect: 'https://console.prod.wedgeapi.com/'
  },
  test: {
    AwsRegion: 'us-west-2',
    DeployedStage: 'test',
    UserPoolId: 'us-west-2_f3dA4WqFr',
    ConsoleAppClientId: '41gfqmntvh23hs3hq36qvlp4fo',
    IdentityPoolId: 'us-west-2:3cbbbbb4-a08d-481b-8a16-9287df791195',
    IotEndPoint: 'aojuo17fe7u07-ats.iot.us-west-2.amazonaws.com',
    StageApiEndPointUrl: 'https://test.wedgeapi.com/',
    Domain: 'login.test.wedgeapi.com',
    Redirect: 'https://console.test.wedgeapi.com/'
  }
};

let getSubdomain = window.location.host.split('.');
let CONFIG: any = {};
if (getSubdomain[1] === 'dev') {
  CONFIG = config['dev'];
} else if (getSubdomain[1] === 'test') {
  CONFIG = config['test'];
} else if (getSubdomain[1] === 'prod') {
  CONFIG = config['prod'];
} else {
  CONFIG = defaultConfig;
}

console.log('DOMAIN :: ' + JSON.stringify(CONFIG.StageApiEndPointUrl));
console.log('DEPLOYED STAGE :: ' + JSON.stringify(CONFIG.DeployedStage));

export default {
  setting: {
    STAGE: CONFIG.DeployedStage,
    REGION: CONFIG.AwsRegion
  },
  apiGateway: {
    REGION: CONFIG.AwsRegion,
    URL: CONFIG.StageApiEndPointUrl,
    VERSION: CONFIG.EndPointVersion,
    API_KEY_STAGE: CONFIG.APIKey
  },
  cognito: {
    REGION: CONFIG.AwsRegion,
    USER_POOL_ID: CONFIG.UserPoolId,
    APP_CLIENT_ID: CONFIG.ConsoleAppClientId,
    IDENTITY_POOL_ID: CONFIG.IdentityPoolId,
    DOMAIN: CONFIG.Domain,
    REDIRECT: CONFIG.Redirect
  },
  analytics: {
    appId: '95bb26c37ba649ae8004836c1a21b707',
    region: 'us-east-1',
    disabled: false
  },
  aws_pubsub_endpoint: {
    IOT_END_POINT: CONFIG.IotEndPoint,
    REGION: CONFIG.AwsRegion
  }
};
