import { Counts } from '../../../utils/types/counts'
import { ISiteFilter, ISiteFilterOptions } from './TableInterfaces'

export const headerMap = {
  Site: 'name',
  Company: 'company',
  Support: 'primarySupport',
  Branch: 'branch.id',
  Country: 'country',
  'Province/State': 'state',
  Region: 'branch.regionName',
  Type: 'projectType',
  'Sub Type': 'projectSubType',
  Tags: 'tags',
  Created: 'created',
  Archived: 'archived',
  GW: 'cagCount',
  WSN: 'wsnCount',
  SH: 'hmcCount',
  TCA: 'tcaCount',
  'LWAN GW': 'lwanGwCount',
  'LWAN WSN': 'lwanWsnCount',
  'DIGI M7': 'm7Count',
  'LWAN M7': 'lwanM7Count',
  Internal: 'internal',
  'Report Interval': 'reportingInterval',
}

export const regionMap = {
  '1 - Corporate': 'Corporate',
  '90 - Closed Location': 'Closed Location',
  '100 - Southwest': 'Southwest',
  '120 - Western Canada': 'Western Canada',
  '140 - X-company Conversion - U.s.': 'X-company Conversion - U.s.',
  '145 - X-company Conversion - Canada': 'X-company Conversion - Canada',
  '150 - Northeast Canada': 'Northeast Canada',
  '180 - Tool Solutions Canada': 'Tool Solutions Canada',
  '200 - South': 'South',
  '240 - Mid Central': 'Mid Central',
  '260 - Ms-new Zealand Region': 'Ms-new Zealand Region',
  '270 - Ms-mobile Storage': 'Ms-mobile Storage',
  '265 - Ms-australia Region': 'Ms-australia Region',
  '280 - Ms-mobile Storage Canada': 'Ms-mobile Storage Canada',
  '300 - Northwest': 'Northwest',
  '330 - Carolinas': 'Carolinas',
  '370 - Industrial': 'Industrial',
  '400 - Trench Safety': 'Trench Safety',
  '421 - Fluid Solutions Europe': 'Fluid Solutions Europe',
  '490 - Trench Safety Canada': 'Trench Safety Canada',
  '500 - Northeast': 'Northeast',
  '520 - Ss-site Services': 'Ss-site Services',
  '550 - Gulf South': 'Gulf South',
  '600 - Midwest': 'Midwest',
  '660 - Southeast': 'Southeast',
  '680 - Tool Solutions': 'Tool Solutions',
  '700 - Power & Hvac': 'Power & Hvac',
  '790 - Power & Hvac Canada': 'Power & Hvac Canada',
  '800 - Mid-atlantic': 'Mid-atlantic',
  '900 - Cmf/refurb': 'Cmf/refurb',
  '920 - Fluid Solutions': 'Fluid Solutions',
  '950 - Fluid Solutions Canada': 'Fluid Solutions Canada',
  '998 - Canadian Distribution Centers': 'Canadian Distribution Centers',
  '999 - Distribution Centers': 'Distribution Centers',
}

export const defaultSiteFilter: ISiteFilter = {
  id: 'showAll',
  name: 'Sites',
  userEmail: '',
  lastUsed: null,
  sortKey: 'created',
  sortOrder: 'desc',
  from: 0,
  size: 10,
  siteName: '',
  companies: [],
  states: [],
  countries: [],
  primarySupport: [],
  primaryAdmins: [],
  branches: [],
  branchRegions: [],
  projectTypes: [],
  projectSubTypes: [],
  showArchived: true,
  showUnarchived: true,
  showActive: true,
  showInactive: true,
  showInternal: true,
  showExternal: true,
  columns: ['Site', 'Created', 'Company', 'Support', 'Branch'],
  columnSizes: [300, 280, 280, 280],
  archivedUnarchived: [],
  internalExternal: [],
  activeInactive: [],
  platform: [],
  gateways: [],
  equipment: [],
}

export const defaultFilters: ISiteFilterOptions = {
  branchRegions: [],
  branches: [],
  companies: [],
  countries: [],
  from: 0,
  primarySupport: [],
  projectSubTypes: [],
  projectTypes: [],
  showArchived: true,
  showExternal: true,
  showActive: true,
  showInactive: true,
  showInternal: true,
  showUnarchived: true,
  siteName: '',
  size: 10,
  sortKey: 'created',
  sortOrder: 'desc',
  states: [],
  archivedUnarchived: [],
  internalExternal: [],
  activeInactive: [],
  platform: [],
  gateways: [],
  equipment: [],
  columns: ['Site', 'Created', 'Company', 'Support', 'Branch'],
  columnSizes: [300, 280, 280, 280],
}

export const defaultCounts: Counts = {
  hmcCount: 0,
  wsnCount: 0,
  cagCount: 0,
  lwGwCount: 0,
  lwWsnCount: 0,
  m7Count: 0,
  lwM7Count: 0,
}
