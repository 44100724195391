import React, { useEffect, useState } from 'react'
import ReactEchartsCore from 'echarts-for-react/lib/core'
import echarts from 'echarts/lib/echarts'
import 'echarts/lib/chart/tree'
import 'echarts/lib/chart/line'
import 'echarts/lib/chart/lines'
import 'echarts/lib/chart/bar'
import 'echarts/lib/component/tooltip'
import 'echarts/lib/component/dataZoom'
import 'echarts/lib/component/brush'
import 'echarts/lib/component/toolbox'
import 'echarts/lib/component/title'
import 'echarts/lib/component/legend'
import 'echarts/lib/component/graphic'
import { IGraphProps } from '../utils'
import { useTheme } from '@mui/material'
import moment from 'moment'

const Graph = (props: IGraphProps) => {
  const { name, yAxisTitle, seriesData, loading, frequency, timeRange } = props
  const theme = useTheme()

  // Series data and no data states
  const [series, setSeries] = useState<any>()
  const [noData, setNoData] = useState(false)

  useEffect(() => {
    // Check if seriesData contains multiple lines
    if (Array.isArray(seriesData)) {
      // if data is not grouped
      if (seriesData.length == 0) {
        setNoData(true)
        setSeries({
          name: name,
          data: [],
          type: 'line',
        })
      } else {
        setNoData(false)
        setSeries([
          {
            name: name,
            data: seriesData,
            type: 'line',
            symbol: 'circle',
            lineStyle: {
              width: 2.5,
            },
          },
        ])
      }
    } else {
      // if data is grouped
      let data = [] as any
      if (Object.keys(seriesData).length == 0) {
        setNoData(true)
        setSeries({
          name: name,
          data: [],
          type: 'line',
          lineStyle: {
            width: 2.5,
          },
        })
      } else {
        setNoData(false)
        Object.keys(seriesData).forEach((key) => {
          data.push({
            name: key,
            data: seriesData[key],
            type: 'line',
            symbol: 'circle',
            lineStyle: {
              width: 2.5,
            },
          })
        })
        setSeries(data)
      }
    }
  }, [seriesData])

  return (
    <ReactEchartsCore
      style={{ height: '100%' }}
      echarts={echarts}
      notMerge={true}
      showLoading={loading}
      loadingOption={{
        maskColor: theme.palette.mode == 'dark' ? 'rgb(0,0,0,0.8)' : 'rgb(255,255,255,0.8)',
        textColor: theme.palette.mode == 'dark' ? 'white' : 'black',
      }}
      option={{
        color: ['#ef271b', '#8F00FF', '#17ffc4', '#ADFF02', '#FF006D', '#FF7D00', '#FFDD00', '#01BEFE'],
        grid: { top: 40, bottom: 0, right: 20, left: 50, height: '83%', containLabel: true },
        graphic: {
          type: 'text',
          left: 'center',
          top: 'middle',
          invisible: !noData,
          style: { text: 'No data available', fill: theme.palette.mode === 'dark' ? 'white' : 'black' },
        },
        tooltip: {
          show: true,
          trigger: 'axis',
          triggerOn: 'mousemove',
          confine: true,
        },
        xAxis: {
          type: 'time',
          name: 'Date',
          nameLocation: 'middle',
          nameGap: 25,
          splitLine: {
            show: false,
          },
          axisLabel: {
            color: theme.palette.mode == 'dark' ? 'white' : 'black',
            formatter: function (value: string) {
              if (frequency == 'hourly' && timeRange == 1) {
                return moment(value).format('HH:mm')
              } else {
                return moment.utc(value).format('MM/DD')
              }
            },
          },
          axisLine: {
            lineStyle: {
              color: theme.palette.mode == 'dark' ? 'white' : 'black',
            },
          },
          axisTick: {
            lineStyle: {
              color: theme.palette.mode == 'dark' ? 'white' : 'black',
            },
          },
        },
        yAxis: {
          name: yAxisTitle,
          nameLocation: 'middle',
          nameGap: 50,
          type: 'value',
          axisLabel: {
            color: theme.palette.mode == 'dark' ? 'white' : 'black',
          },
          axisLine: {
            lineStyle: {
              color: theme.palette.mode == 'dark' ? 'white' : 'black',
            },
          },
          axisTick: {
            lineStyle: {
              color: theme.palette.mode == 'dark' ? 'white' : 'black',
            },
          },
        },
        series: series,
      }}
    ></ReactEchartsCore>
  )
}

export default Graph
