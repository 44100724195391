import React, { Component, Fragment, useEffect, useState } from 'react'
import { styled } from '@mui/material/styles'
import './App.css'
import 'primereact/resources/themes/nova/theme.css'
import 'primereact/resources/primereact.min.css'
import 'primeicons/primeicons.css'
import Auth from '@aws-amplify/auth'
import { Hub } from '@aws-amplify/core'
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  IconButton,
  Drawer,
  List,
  Divider,
  Menu,
  MenuItem,
  useTheme,
  createTheme,
  ThemeProvider,
  ScopedCssBaseline,
} from '@mui/material'
import { Link, useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import { publicListItems, privateListItems } from './components/SiteLinks'
import Routes from './Routes'
import config from './config'
import NotificationBar from './NotificationBar'
import AccountCircle from '@mui/icons-material/AccountCircle'
import MenuIcon from '@mui/icons-material/Menu'
import { connect } from 'react-redux'
import { newMqttConnection } from './actions/'
import short from 'short-uuid'
import { POST_ENDPOINT } from './api-request/'
import { GET_USER_PERMISSIONS } from './constants/end-points'
import Logo from './components/Logo'
import withMediaQuery from './utils/withMediaQuery'
import useMediaQuery from '@mui/material/useMediaQuery'
import { bool } from 'aws-sdk/clients/signer'
import Brightness4Icon from '@mui/icons-material/Brightness4'
import Brightness7Icon from '@mui/icons-material/Brightness7'

const drawerWidth = 275
const PREFIX = 'App'

const classes = {
  root: `${PREFIX}-root`,
  appBarFull: `${PREFIX}-appBarFull`,
  appBarMobile: `${PREFIX}-appBarMobile`,
  appBarButton: `${PREFIX}-appBarButton`,
  drawer: `${PREFIX}-drawer`,
  drawerPaper: `${PREFIX}-drawerPaper`,
  toolbar: `${PREFIX}-toolbar`,
  content: `${PREFIX}-content`,
}

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.root}`]: {
    display: 'flex',
  },

  [`& .${classes.appBarFull}`]: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    borderBottom:
      theme.palette.mode == 'light' ? '1px solid rgba(0, 0, 0, 0.12)' : '1px solid rgba(255, 255, 255, 0.12)',
    boxShadow: 'none',
  },

  [`& .${classes.appBarMobile}`]: {
    width: `100%`,
    marginLeft: drawerWidth,
    borderBottom:
      theme.palette.mode == 'light' ? '1px solid rgba(0, 0, 0, 0.12)' : '1px solid rgba(255, 255, 255, 0.12)',
    boxShadow: 'none',
  },

  [`& .${classes.appBarButton}`]: {
    marginRight: theme.spacing(2),
  },

  [`& .${classes.drawer}`]: {
    width: drawerWidth,
    flexShrink: 0,
  },

  [`& .${classes.drawerPaper}`]: {
    width: drawerWidth,
  },

  [`& .${classes.toolbar}`]: theme.mixins.toolbar,

  [`& .${classes.content}`]: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
    minHeight: '100vh',
  },
}))

const mapDispatchToProps = (dispatch: any) => {
  return {
    newMqttConnection: (credentials: any) => dispatch(newMqttConnection(credentials)),
  }
}

interface IAppProps {
  newMqttConnection: (credentials: any) => any
  isMobile?: boolean
}

const ColorModeContext = React.createContext({ toggleColorMode: () => {} })

const developerList = [
  'tkahessay@ur.com',
  'dcarter@ur.com',
  'sshakya@ur.com',
  'maldulaimi@ur.com',
  'atesche@ur.com',
  'nbui@ur.com',
]

const App = (props: IAppProps) => {
  const { newMqttConnection } = props
  const theme = useTheme()
  const colorMode = React.useContext(ColorModeContext)

  const [appState, setAppState] = useState({
    isAuthenticated: false,
    showNotification: true,
    userPermissions: [] as any[],
    userPermittedPaths: [] as any[],
    userDefaultPath: '',
    left: false,
    anchorEl: null as any,
  })

  const [isAuthenticating, setIsAuthenticating] = useState(true)
  const [isDeveloper, setIsDeveloper] = useState(false)

  const history = useHistory()

  const handleClose = () => {
    setAppState({ ...appState, anchorEl: null })
  }

  const signIn = async () => {
    let sessionDetails = await Auth.currentCredentials()
    let client_id = sessionDetails.identityId + '.' + short.uuid()

    let post_params = {
      userId: sessionDetails.identityId,
    }

    let get_user_permissions = await POST_ENDPOINT(GET_USER_PERMISSIONS, post_params)

    userHasAuthenticated(
      true,
      get_user_permissions.permission,
      get_user_permissions.path,
      get_user_permissions.defaultPath[0]
    )

    if (config.setting.STAGE !== 'prod') {
      console.log({
        accessKeyId: sessionDetails.accessKeyId,
        secretAccessKey: sessionDetails.secretAccessKey,
        sessionToken: sessionDetails.sessionToken,
        service: 'execute-api',
        region: 'us-west-2',
      })
    }

    let mqttClientCredentials = {
      clientId: client_id,
      accessKeyId: sessionDetails.accessKeyId,
      secretAccessKey: sessionDetails.secretAccessKey,
      sessionToken: sessionDetails.sessionToken,
      endpointAddress: config.aws_pubsub_endpoint.IOT_END_POINT,
      region: config.aws_pubsub_endpoint.REGION,
    }

    let topics = {
      rsp: config.setting.STAGE + '/' + sessionDetails.identityId + '/rsp',
      cag: config.setting.STAGE + '/cag/#',
    }

    newMqttConnection({ config: mqttClientCredentials, topics: topics })
  }

  useEffect(() => {
    const handleAuth = (data: any) => {
      const { payload } = data
      console.log('Auth event!', data)
      if (developerList.includes(payload.data.username.split('_')[1])) {
        setIsDeveloper(true)
      }
      if (payload.event === 'signIn' || payload.event === 'cognitoHostedUI') {
        signIn()
      }
    }

    Hub.listen('auth', handleAuth)

    const loadUserSession = async () => {
      try {
        if (await Auth.currentSession()) {
          await signIn()
        }
      } catch (error) {
        console.error('Error during session load:', error)
      }

      // Clean up the subscription when the component unmounts
      return () => Hub.remove('auth', handleAuth)
    }

    const getUserEmail = async () => {
      try {
        let profile = await Auth.currentUserPoolUser()
        return profile.attributes.email
      } catch (error) {
        console.error(error)
      }
    }

    loadUserSession().then(() => {
      getUserEmail().then((email) => {
        if (developerList.includes(email)) {
          setIsDeveloper(true)
        }
      })
      setIsAuthenticating(false)
    })
  }, [])

  const toggleDrawer = (side: any, open: any) => {
    setAppState({
      ...appState,
      [side]: open,
    })
  }

  const userHasAuthenticated = (
    authenticated: any,
    userPermissions: any,
    userPermittedPaths: any,
    userDefaultPath: any
  ) => {
    setAppState({
      ...appState,
      isAuthenticated: authenticated,
      userPermissions: [...userPermissions],
      userPermittedPaths: [...userPermittedPaths],
      userDefaultPath: userDefaultPath,
    })
  }

  const setUserPermissions = (permissions: any) => {
    setAppState({
      ...appState,
      userPermissions: [...permissions, ...appState.userPermissions],
    })
  }

  const setUserPermittedPaths = (path: any) => {
    setAppState({ ...appState, userPermittedPaths: path })
  }

  const setUserDefaultPath = (path: any) => {
    setAppState({ ...appState, userDefaultPath: path })
  }

  const handleLogout = async (event: any) => {
    //Load User Session
    await Auth.signOut()
    userHasAuthenticated(false, [], [], '')
    setAppState({ ...appState, anchorEl: null })
    history.push('/login')
  }

  const childProps: any = {
    isAuthenticated: appState.isAuthenticated,
    userHasAuthenticated: userHasAuthenticated,
    setUserPermissions: setUserPermissions,
    setUserPermittedPaths: setUserPermittedPaths,
    setUserDefaultPath: setUserDefaultPath,
    userPermissions: appState.userPermissions,
    userPermittedPaths: appState.userPermittedPaths,
    userDefaultPath: appState.userDefaultPath,
    isDeveloper: isDeveloper,
  }

  const sideList = (
    <div className="list">
      {appState.isAuthenticated ? (
        <Fragment>
          {' '}
          <List>{privateListItems(childProps)}</List>
        </Fragment>
      ) : (
        <Fragment></Fragment>
      )}
      <List>{publicListItems}</List>
    </div>
  )
  const open = Boolean(appState.anchorEl)
  const { isMobile } = props
  const { left } = appState

  return (
    !isAuthenticating && (
      <Root className={classes.root}>
        <AppBar
          position="fixed"
          className={isMobile ? classes.appBarMobile : classes.appBarFull}
          // style={{ backgroundColor: theme.palette.mode == 'dark' ? 'black' : 'white' }}
        >
          <Toolbar style={{ placeContent: 'space-between' }}>
            {isMobile ? (
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={() => toggleDrawer('left', true)}
                edge="start"
                className={classes.appBarButton}
                size="large"
              >
                <MenuIcon />
              </IconButton>
            ) : (
              <Fragment></Fragment>
            )}
            <Typography variant="h6" color="inherit">
              <Logo />
            </Typography>
            {appState.isAuthenticated ? (
              <div>
                <IconButton onClick={colorMode.toggleColorMode} color="inherit">
                  {theme.palette.mode == 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
                </IconButton>
                <IconButton
                  aria-owns={open ? 'menu-appbar' : undefined}
                  aria-haspopup="true"
                  onClick={(event) => {
                    setAppState({ ...appState, anchorEl: event.currentTarget })
                  }}
                  color="inherit"
                  size="large"
                >
                  <AccountCircle />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={appState.anchorEl}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  PaperProps={{
                    style: {
                      width: 200,
                    },
                  }}
                  open={open}
                  onClose={handleClose}
                >
                  <MenuItem onClick={handleClose}>Profile</MenuItem>
                  <MenuItem onClick={handleClose}>Settings</MenuItem>
                  <MenuItem onClick={handleClose}>Tasks</MenuItem>
                  <Divider />
                  <MenuItem onClick={handleLogout}>Logout</MenuItem>
                </Menu>
              </div>
            ) : (
              <Fragment>
                <Button color="inherit" component={Link} to="/login">
                  Login
                </Button>
              </Fragment>
            )}
          </Toolbar>
        </AppBar>
        <Drawer
          variant={isMobile ? 'temporary' : 'permanent'}
          anchor="left"
          className={classes.drawer}
          classes={{
            paper: classes.drawerPaper,
          }}
          open={isMobile ? left : true}
          onClose={() => toggleDrawer('left', false)}
        >
          <div tabIndex={0} role="button">
            {sideList}
          </div>
        </Drawer>

        <main className={classes.content}>
          <div style={isMobile ? { marginLeft: 0 } : { marginLeft: drawerWidth }}>
            <div className={classes.toolbar} />
            <Routes childProps={childProps} />
          </div>
        </main>
        <NotificationBar />
      </Root>
    )
  )
}

const AppPage = connect(null, mapDispatchToProps)(App as any)

const ToggleColorMode = () => {
  // const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)')
  let defaultColorScheme = localStorage.getItem('colorScheme')
  let prefersDarkMode = false

  if (defaultColorScheme) {
    if (defaultColorScheme == 'dark') {
      prefersDarkMode = true
    }
  }

  const [mode, setMode] = React.useState<'light' | 'dark'>(prefersDarkMode ? 'dark' : 'light')
  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => {
          if (prevMode == 'light') {
            localStorage.setItem('colorScheme', 'dark')
          } else {
            localStorage.removeItem('colorScheme')
          }
          return prevMode === 'light' ? 'dark' : 'light'
        })
      },
    }),
    []
  )

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode,
          primary: {
            light: '#757ce8',
            main: '#fff',
            dark: '#e7e7e7',
            contrastText: '#000000de',
          },
          secondary: {
            light: '#333',
            main: '#666',
            dark: '#999',
            contrastText: '#ccc',
          },
          text: {
            ...(mode === 'light'
              ? {}
              : {
                  primary: '#dedede',
                  secondary: '#dedede',
                }),
          },
        },
        components: {
          MuiListItem: {
            styleOverrides: {
              button: {
                cursor: 'pointer',
                '&:active': {
                  color: '#fff',
                },
                selected: {
                  color: 'red !important',
                },
              },
            },
          },
          MuiListItemIcon: {
            styleOverrides: {
              root: {
                cursor: 'pointer',
                '&:active': {
                  color: 'blue',
                },
                marginRight: 0,
                color: '#b4b4b4',
              },
            },
          },
          MuiDialog: {
            styleOverrides: {
              paper: {
                ...(mode === 'dark'
                  ? {
                      backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))',
                    }
                  : {}),
              },
            },
          },
          MuiAppBar: {
            styleOverrides: {
              ...(mode === 'dark'
                ? {
                    root: {
                      backgroundColor: 'black',
                    },
                  }
                : {
                    root: {
                      backgroundColor: 'white',
                    },
                  }),
            },
          },
        },
      }),
    [mode]
  )

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <ScopedCssBaseline>
          <AppPage />
        </ScopedCssBaseline>
      </ThemeProvider>
    </ColorModeContext.Provider>
  )
}

export default withMediaQuery(ToggleColorMode)
