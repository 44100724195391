import React, { useState, useEffect, useRef } from 'react'
import ReactEchartsCore from 'echarts-for-react/lib/core'
import echarts from 'echarts/lib/echarts'
import 'echarts/lib/chart/tree'
import 'echarts/lib/chart/line'
import 'echarts/lib/chart/lines'
import 'echarts/lib/chart/bar'
import 'echarts/lib/component/tooltip'
import 'echarts/lib/component/dataZoom'
import 'echarts/lib/component/brush'
import 'echarts/lib/component/toolbox'
import 'echarts/lib/component/title'
import 'echarts/lib/component/legend'
import 'echarts/lib/component/legendScroll'
import { Container, Paper, Typography, useTheme } from '@mui/material'
import { POST } from '../../api-request'
import moment from 'moment'
import Rainbow from 'rainbowvis.js'
import '../Table/styles.css'
interface IReponseTimeProps {
  dateFilters: {
    startTime: {
      start: string
      stop: string
    }
  }
  selectedData: Array<string>
  variant: string
  endpoint: string
}

const ResponseTime = ({ dateFilters, selectedData, variant, endpoint }: IReponseTimeProps) => {
  const theme = useTheme()
  let rainbow = new Rainbow()
  rainbow.setSpectrum('#DC3B40', '#FA7B39', '#E7BB12', '#77AC08', '#1272D8', '#4E3380')

  const [xAxisData, setXAxisData] = useState([] as any)
  const [seriesData, setSeriesData] = useState([] as any)
  const [loading, setLoading] = useState(false)

  const localDate = moment(new Date())
  const startDate = moment(localDate).subtract(13, 'days').hours(0).minutes(0).seconds(0)
  const endDate = moment(localDate)

  const rawData = useRef([])

  /**
   * Generates days for the x-axis
   * @returns the days between the start and end date for the x-axis
   */
  const generateDays = () => {
    let sDate = dateFilters.hasOwnProperty('startTime') ? moment(dateFilters.startTime.start) : startDate
    let eDate = dateFilters.hasOwnProperty('startTime') ? moment(dateFilters.startTime.stop) : endDate

    let daysDiff = eDate.diff(sDate, 'days') + 1
    let days = []

    for (let i = 0; i < daysDiff; i++) {
      // let currDate = new Date(new Date().setDate(today.getDate() - i))
      let currDate = moment(eDate).subtract(i, 'days')

      if (i == 0 && currDate.format('MM/DD') == localDate.format('MM/DD')) {
        let hours = moment(localDate).hours()

        for (let start = hours; start >= 0; start--) {
          let date = moment(currDate).hours(start).minutes(0).seconds(0).format()
          days.push(date)
        }
      } else {
        for (let j = 23; j >= 0; j--) {
          let date = moment(currDate).hours(j).minutes(0).seconds(0).format()
          days.push(date)
        }
      }
    }
    return days
  }

  /**
   * API request for response time data
   * @returns response time data
   */
  const getData = async () => {
    setLoading(true)
    let filter = dateFilters.hasOwnProperty('startTime')
      ? dateFilters
      : {
          startTime: {
            start: startDate.format(),
            stop: endDate.format(),
          },
        }

    const response = await POST(endpoint, { ...filter, from: 0 })
    rawData.current = response.docs
    return response
  }

  /**
   * Formats the data to be put on the chart
   * @param data the API response data
   * @param days days for the x-axis
   * @returns
   */
  const formatData = (data: Array<any>, days: Array<any>) => {
    let res = []

    rainbow.setNumberRange(0, selectedData.length > 0 ? selectedData.length : 5)

    let agg = {
      name: 'Total',
      xAxisIndex: 1,
      yAxisIndex: 1,
      data: new Array(days.length).fill(0),
      type: 'bar',
    }

    if (variant == 'site') {
      for (const [index, site] of data.entries()) {
        if (selectedData.includes(site.siteId)) {
          let obj = {
            name: site.siteName ? site.siteName : site.siteId,
            color: '#' + rainbow.colourAt(selectedData.indexOf(site.siteId)),
            type: 'line',
            data: new Array(days.length).fill('-'),
            symbol: 'circle',
          }

          for (let i = 0; i < days.length; i++) {
            let day = moment.utc(days[i]).format()
            if (site.hasOwnProperty(day)) {
              let dur = site[day] / 1000
              obj.data[i] = dur
              agg.data[i] += dur
            }
          }
          res.push(obj)
        }
      }
    } else if (variant == 'function') {
      for (const [index, func] of data.entries()) {
        if (selectedData.includes(func.functionName)) {
          let obj = {
            name: func.functionName,
            color: '#' + rainbow.colourAt(selectedData.indexOf(func.functionName)),
            type: 'line',
            data: new Array(days.length).fill('-'),
            symbol: 'circle',
          }

          for (let i = 0; i < days.length; i++) {
            let day = moment.utc(days[i]).format()
            if (func.hasOwnProperty(day)) {
              let dur = func[day] / 1000
              obj.data[i] = dur
              agg.data[i] += dur
            }
          }
          res.push(obj)
        }
      }
    }

    res.push(agg)
    return res
  }

  /**
   * Formats the dates for the x-axis
   * @param data x-axis data
   * @returns formatted x-axis data
   */
  const formatXAxisData = (data: any) => {
    let res = [] as any
    for (let val of data) {
      res.push(moment(val).format('MM/DD HH:mm'))
    }
    return res
  }

  /**
   * Handles changing the chart data based on the date filters
   */
  useEffect(() => {
    if (dateFilters.hasOwnProperty('startTime')) {
      getData().then((res) => {
        let days = generateDays()
        setXAxisData(days.reverse())
        let series = formatData(res.docs, days)
        setSeriesData(series)
        setLoading(false)
      })
    }
  }, [dateFilters])

  /**
   * Handles the selected row data for the chart
   */
  useEffect(() => {
    if (selectedData.length > 0) {
      if (rawData.current.length > 0) {
        let days = generateDays()
        setXAxisData(days.reverse())
        let series = formatData(rawData.current, days)
        setSeriesData(series)
        setLoading(false)
      } else {
        getData().then((res) => {
          let days = generateDays()
          setXAxisData(days.reverse())
          let series = formatData(res.docs, days)
          setSeriesData(series)
          setLoading(false)
        })
      }
    }
  }, [selectedData, dateFilters])

  return (
    <>
      <Paper sx={{ marginTop: '20px', height: '700px' }}>
        {selectedData.length > 0 ? (
          <ReactEchartsCore
            lazyUpdate={false}
            style={{ height: '700px' }}
            echarts={echarts}
            showLoading={loading}
            loadingOption={{
              maskColor: theme.palette.mode == 'dark' ? 'rgb(0,0,0,0.8)' : 'rgb(255,255,255,0.8)',
              textColor: theme.palette.mode == 'dark' ? 'white' : 'black',
            }}
            notMerge={true}
            option={{
              tooltip: {
                trigger: 'axis',
                triggerOn: 'mousemove',
                axisPointer: {
                  type: 'cross',
                },
                transitionDuration: 0,
                confine: true,
              },
              legend: {
                type: 'scroll',
                top: 8,
                padding: [5, 15, 5, 15],
                left: 'center',
                textStyle: {
                  color: theme.palette.mode == 'dark' ? 'white' : 'black',
                },
              },
              axisPointer: {
                link: [{ xAxisIndex: 'all' }],
                label: {
                  backgroundColor: '#777',
                },
              },
              grid: [
                {
                  left: '8%',
                  right: '8%',
                  height: '62%',
                },
                {
                  left: '8%',
                  right: '8%',
                  top: '73%',
                  height: '15%',
                },
              ],
              yAxis: [
                {
                  type: 'value',
                  axisLabel: {
                    color: theme.palette.mode == 'dark' ? 'white' : 'black',
                  },
                  axisLine: {
                    lineStyle: {
                      color: theme.palette.mode == 'dark' ? 'white' : 'black',
                    },
                  },
                  axisTick: {
                    lineStyle: {
                      color: theme.palette.mode == 'dark' ? 'white' : 'black',
                    },
                  },
                },
                {
                  gridIndex: 1,
                  splitNumber: 2,
                  axisLabel: { show: false },
                  axisLine: { show: false },
                  axisTick: { show: false },
                  splitLine: { show: false },
                },
              ],
              dataZoom: [
                {
                  type: 'inside',
                  xAxisIndex: [0, 1],
                },
                {
                  show: true,
                  xAxisIndex: [0, 1],
                  type: 'slider',
                  top: '90%',
                  textStyle: {
                    color: theme.palette.mode == 'dark' ? 'white' : 'black',
                  },
                },
              ],
              xAxis: [
                {
                  type: 'category',
                  boundaryGap: false,
                  data: formatXAxisData(xAxisData),
                  axisLabel: {
                    color: theme.palette.mode == 'dark' ? 'white' : 'black',
                  },
                  axisLine: {
                    lineStyle: {
                      color: theme.palette.mode == 'dark' ? 'white' : 'black',
                    },
                  },
                  axisTick: {
                    lineStyle: {
                      color: theme.palette.mode == 'dark' ? 'white' : 'black',
                    },
                  },
                  splitNumber: 20,
                  min: 'dataMin',
                  max: 'dataMax',
                },
                {
                  type: 'category',
                  gridIndex: 1,
                  data: formatXAxisData(xAxisData),
                  boundaryGap: false,
                  axisLine: { onZero: false },
                  axisTick: { show: false },
                  splitLine: { show: false },
                  axisLabel: { show: false },
                  splitNumber: 20,
                  min: 'dataMin',
                  max: 'dataMax',
                },
              ],
              series: seriesData,
            }}
          />
        ) : (
          <div style={{ textAlign: 'center', height: '100%', paddingTop: '15%' }}>Select a row from the table</div>
        )}
      </Paper>
    </>
  )
}

export default ResponseTime
