import React from 'react'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Snackbar from '@mui/material/Snackbar'
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import { closeNotification } from './actions/index'
import store from './store/index'
import { IState } from './reducers'
import { useTheme } from '@mui/material/styles'

const PREFIX = 'NotificationBar'

const classes = {
  close: `${PREFIX}-close`,
}

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  [`&.${classes.close}`]: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
}))

const mapStateToProps = (state: IState) => {
  return {
    showNotification: state.showNotification,
    newNotificationMessage: state.newNotificationMessage,
  }
}

const SimpleSnackbar = (props: any) => {
  const { showNotification, newNotificationMessage } = props
  const theme = useTheme()

  const handleClose = () => {
    store.dispatch(closeNotification(false))
  }

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      sx={{
        backgroundColor: theme.palette.mode === 'dark' ? 'black' : 'white',
        color: theme.palette.mode === 'dark' ? 'white' : 'black',
      }}
      open={showNotification}
      autoHideDuration={6000}
      onClose={handleClose}
      message={<span id="message-id">{newNotificationMessage}</span>}
      action={[
        <StyledIconButton
          key="close"
          aria-label="Close"
          color="inherit"
          className={classes.close}
          onClick={handleClose}
          size="large"
        >
          <CloseIcon />
        </StyledIconButton>,
      ]}
    />
  )
}

// class SimpleSnackbar extends React.Component<any, any> {
//   handleClose = () => {
//     store.dispatch(closeNotification(false))
//   }

//   render() {
//     const { showNotification, newNotificationMessage } = this.props
//     const theme = useTheme()

//     return (
//       <Snackbar
//         anchorOrigin={{
//           vertical: 'bottom',
//           horizontal: 'left',
//         }}
//         sx={{
//           backgroundColor: theme.palette.mode === 'dark' ? 'black' : 'white',
//           color: theme.palette.mode === 'dark' ? 'white' : 'black',
//         }}
//         open={showNotification}
//         autoHideDuration={6000}
//         onClose={this.handleClose}
//         message={<span id="message-id">{newNotificationMessage}</span>}
//         action={[
//           <StyledIconButton
//             key="close"
//             aria-label="Close"
//             color="inherit"
//             className={classes.close}
//             onClick={this.handleClose}
//             size="large"
//           >
//             <CloseIcon />
//           </StyledIconButton>,
//         ]}
//       />
//     )
//   }
// }

const List = connect(mapStateToProps)(SimpleSnackbar)
export default List
