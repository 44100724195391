import React, { useEffect, useState } from 'react'
import ReactEchartsCore from 'echarts-for-react/lib/core'
import echarts from 'echarts/lib/echarts'
import 'echarts/lib/chart/tree'
import 'echarts/lib/chart/line'
import 'echarts/lib/chart/lines'
import 'echarts/lib/chart/bar'
import 'echarts/lib/component/tooltip'
import 'echarts/lib/component/dataZoom'
import 'echarts/lib/component/brush'
import 'echarts/lib/component/toolbox'
import 'echarts/lib/component/title'
import 'echarts/lib/component/legend'
import 'echarts/lib/component/graphic'
import { IGraphProps } from '../utils'
import { useTheme } from '@mui/material'
import '../styles.css'
import moment from 'moment'

const Graph = (props: IGraphProps) => {
  const { metric, seriesData, frequency, size } = props

  const [leftMargin, setLeftMargin] = useState(40)
  const [noData, setNoData] = useState(false)

  const theme = useTheme()

  useEffect(() => {
    if (metric > 500 || (!metric && typeof metric != 'number')) {
      setLeftMargin(60)
    }
    if (size == 'xl' || size == 'lg') {
      setLeftMargin(100)
    }
    if (seriesData.length == 0) {
      setNoData(true)
    } else {
      setNoData(false)
    }
  }, [metric])

  return (
    <ReactEchartsCore
      echarts={echarts}
      style={{ padding: 0, marginBottom: 0, height: '100%' }}
      option={{
        grid: { top: 10, bottom: 0, left: leftMargin, height: '83%' },
        graphic: {
          type: 'text',
          left: 'center',
          top: 'middle',
          invisible: !noData,
          style: { text: 'No data available', fill: theme.palette.mode === 'dark' ? 'white' : 'black' },
        },
        tooltip: {
          show: true,
          trigger: 'axis',
          triggerOn: 'mousemove',
          confine: true,
        },
        xAxis: {
          type: 'time',
          splitLine: {
            show: false,
          },
          axisLabel: {
            color: theme.palette.mode == 'dark' ? 'white' : 'black',
            formatter: function (value: string) {
              if (frequency == 'hourly') {
                return moment(value).format('HH:mm')
              } else {
                return moment.utc(value).format('ddd')
              }
            },
          },
          axisLine: {
            lineStyle: {
              color: theme.palette.mode == 'dark' ? 'white' : 'black',
            },
          },
          axisTick: {
            lineStyle: {
              color: theme.palette.mode == 'dark' ? 'white' : 'black',
            },
          },
        },
        yAxis: {
          type: 'value',
          splitLine: {
            show: false,
          },
          axisLabel: {
            color: theme.palette.mode == 'dark' ? 'white' : 'black',
          },
          axisLine: {
            lineStyle: {
              color: theme.palette.mode == 'dark' ? 'white' : 'black',
            },
          },
          axisTick: {
            lineStyle: {
              color: theme.palette.mode == 'dark' ? 'white' : 'black',
            },
          },
        },
        series: [
          {
            data: seriesData,
            type: 'line',
            symbol: 'circle',
            showSymbol: false, // removes dot for each point
            lineStyle: {
              color: '#ef271b',
              width: 2.5,
            },
          },
        ],
      }}
    ></ReactEchartsCore>
  )
}

export default Graph
