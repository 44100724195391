import { ColDef, ColumnMovedEvent, ColumnResizedEvent, IHeaderParams } from 'ag-grid-community'

export interface Column extends ColDef {
  isEnum?: boolean
  options?: string | number[]
  isLink?: boolean
  basePath?: string
  isSearchable?: boolean
  filterable?: boolean
}

export interface IPagination {
  rowsPerPage: number
  page: number
  count: number
}

export type Inventory = IDevice | IGateway

export interface IDevice {
  barcode: string
  cagBarcode: string
  id: string
  partNumber: string
  revision: number
  stage: string
  timeCreated: string
  type: string
  xbeeAppFw: string
  xbeeMacAddr: string
  xbeeRadioFw: string
  comment?: string
}

export interface IGateway {
  barcode: string
  bbMacAddr: string
  bbSerialNo: string
  bbVersion: string
  iccid: string
  id: string
  imei: string
  ipAddress: string
  partNumber: string
  revision: number
  stage: string
  swVersion: string
  timeCreated: string
  timeExpired?: string
  type: string
  ubootVersion: string
  xbeeMacAddr: string
  xbeeNetworkId: string
  xbeeRadioFw: string
}

interface IRawGatewayRecordDeviceSensor {
  channel: number
  offset: number
  type: string
  value: number
}

interface IRawGatewayRecordDevice {
  barcode: string
  hmcStatus?: {
    count: number
    cyclesOff: number
    cyclesOn: number
    heaterOn: boolean
  }
  hmcTarget?: {
    enabled: boolean
    target: number
    targetScheduleId: string
    tempSensors: Array<{ barcode: string; channel: number }>
  }
  linkQuality: number
  pcbTemp: number
  sensors: IRawGatewayRecordDeviceSensor[]
  voltage: number
}

export interface IRawGatewayRecord {
  deviceCount: number
  reportingInterval: number
  sensorCount: number
  source: {
    barcode: string
    csq?: {
      ber: string
      rssi: string
    }
    dataUsage?: {
      interval_bytes: number
      rx_bytes: number
      tx_bytes: number
    }
    devices?: IRawGatewayRecordDevice[]
    gps?: {
      alt: number
      hdop: number
      lat: number
      lng: number
      nsat: number
    }
    invalidBarcodes: string[]
    messageId: string
    restarted?: boolean
    powerFailure?: boolean
    reportingInterval?: number
    timeReceived: string
    timeSent: string
    timestamp: string
    uptime: number
    xbeeNetworkId: string
  }
  timeReceived: string
  timestamp: string
  transmissionDelay: number
  uptime: number
}

export interface IAsyncDevice {
  attributes: any
  barcode: string
  batteryReplaced: string
  comment: string
  devEui: string
  deviceType: string
  email: string
  equipmentId: string
  expired: string | null
  id: string
  make: string
  model: string
  registered?: boolean
  reportingInterval: number
  siteId: string
  siteName: string
  swVersion: string
  timestamp: string
  type: string
  wirelessId: string
}

export interface IAsyncData {
  alarmMsgCount: number
  alarms: boolean
  batteryReading: number
  configError: boolean
  devEui: string
  frameCount: number
  gwEquipmentId: string
  gwEui: string
  id: string
  lowBattery: boolean
  msgCount: number
  payload: string
  rssi: number
  sensors: Array<{ channel: number; type: string; value: number }>
  signalStrength: number
  snr: number
  timestamp: string
  voltage: number
}

export interface ISite {
  archived: string
  branch: {
    active: boolean
    address1: string
    city: string
    country: string
    countryCode: string
    district: number
    districtName: string
    id: string
    region: number
    regionName: string
    state: string
    stateCode: string
    zip: string
  }
  cagCount: number
  company: string
  companyId: string
  country: string
  created: string
  geolocation: {
    lat: number
    lon: number
  }
  hmcCount: number
  internal: boolean
  isLwan: boolean
  name: string
  primaryAdmin: string
  primarySupport: string
  primarySubType: string
  projectType: string
  reportingInterval: number
  siteAdmins: string[]
  siteId: string
  state: string
  support: string[]
  tags: string[]
  tcaCount: number
  timeZoneId: string
  timeZoneName: string
  timestamp: string
  wsnCount: number
}

export interface ITableProps {
  columns: Array<ColDef>
  rows: Array<Inventory | any>
  loading: boolean
  handleSort: (column: string, order: string | null) => void
  refresh: boolean
  handleSelectRow: (row?: any) => void
  multiSelect: boolean
  fixedColSize?: boolean
  onColumnResize?: (params: ColumnResizedEvent) => void
  onColumnMoved?: (params: ColumnMovedEvent) => void
  sortState?: ISortState
}

export interface ICustomHeaderParams extends IHeaderParams {
  sortField: string
  sortOrder: string
}

export interface IFilter {
  headerName: string
  field: string
  value?: string
  values?: Array<string | any>
  map?: any
}

export interface ISortState {
  sortField: string
  sortOrder: string
}

export interface IAnalytics {
  selected: boolean
}

export interface IWedgeTableProps {
  tHeader: string
  tName: string
  tColumns: Array<Column>
  optionsEndpoint: string
  searchEndpoint: string
  dateFields?: string[] // optionally define fields that will be filtered by date
  nonFilterableFields?: string[] // optionally define fields that will not be filterable
  handleSelectRow?: (row?: any) => void
  forceRefresh?: boolean
  filteringDisabled?: boolean
  refreshDisabled?: boolean
  filters?: any
  multiSelect?: boolean
  showPagination?: boolean
  defaultData?: any
  saveAction?: any
}
export interface ISiteFilter {
  inputValue?: string
  id: string
  name: string
  userEmail: string
  lastUsed?: Date
  sortKey: string
  sortOrder: string
  startDate?: Date | undefined
  endDate?: Date | undefined
  from: number
  size: number
  siteName: string
  companies: string[]
  states: string[]
  countries: string[]
  primarySupport: string[]
  primaryAdmins: string[]
  branches: string[]
  branchRegions: string[]
  projectTypes: string[]
  projectSubTypes: string[]
  showArchived: boolean
  showUnarchived: boolean
  showActive: boolean
  showInactive: boolean
  showInternal: boolean
  showExternal: boolean
  columns?: string[]
  columnSizes?: number[]
  archivedUnarchived: string[]
  internalExternal: string[]
  activeInactive: string[]
  platform: Array<string>
  gateways: Array<string>
  equipment: Array<string>
}

export interface ISiteFilterOptions {
  branchRegions: Array<string>
  branches: Array<string>
  companies: Array<string>
  countries: Array<string>
  from: number
  primarySupport: Array<string>
  projectSubTypes: Array<string>
  projectTypes: Array<string>
  showArchived: boolean
  showExternal: boolean
  showActive: boolean
  showInactive: boolean
  showInternal: boolean
  showUnarchived: boolean
  siteName: string
  size: number
  sortKey: string
  sortOrder: string
  states: Array<any>
  archivedUnarchived: Array<string>
  internalExternal: Array<string>
  activeInactive: Array<string>
  platform: Array<string>
  gateways: Array<string>
  equipment: Array<string>
  columns: Array<string>
  columnSizes: Array<number>
}

// Select Props
const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
}
