import AWSMqtt from 'aws-mqtt-client'
import Pako from 'pako'

function invert(obj: any) {
  const keys = Object.keys(obj)
  const newObj: any = {}

  keys.forEach((key) => {
    newObj[obj[key]] = key
  })

  return newObj
}

class MQTTRedux {
  config: any
  mqtt: any
  topicActionMap: any

  constructor(config: any) {
    this.config = config
    //this.mqtt = connect(config.url, config.opt);
    this.mqtt = new AWSMqtt(config)

    this.topicActionMap = {}
  }

  publish(payload: any, store: any) {
    this.mqtt.publish(payload.publishTopic, JSON.stringify(payload.publishText), { qos: 1 })
  }

  subscribe_to_topic(payload: any, store: any) {
    this.mqtt.subscribe(payload, { qos: 1 })
  }

  connect(actions: any, store: any) {
    // const actionNames = Object.keys(actions);
    this.topicActionMap = invert(actions)
    this.mqtt.on('connect', () => {
      this.mqtt.subscribe(actions['SUBSCRIBE_TO_RSP_DATA'], { qos: 1 })
    })

    this.mqtt.on('disconnect', () => {
      store.dispatch({
        type: 'MQTT_DISCONNECT',
        message: 'Disconnected from MQTT broker',
      })
    })

    this.mqtt.on('message', (topic: string, payload: any) => {
      try {
        let parsedPayload
        try {
          parsedPayload = JSON.parse(payload.toString())
          console.log(topic, parsedPayload)
        } catch (error) {
          parsedPayload = JSON.parse(Pako.inflate(payload, { to: 'string' }))
        }
        if (topic.match('cag/.*/rsp')) {
          store.dispatch({
            type: 'SUBSCRIBE_TO_RSP_DATA',
            payload: parsedPayload,
            topic: topic,
          })
        } else {
          store.dispatch({
            type: 'SUBSCRIBE_TO_CAG',
            payload: parsedPayload,
            topic: topic,
          })
        }
      } catch (error) {
        console.error(payload)
      }
    })

    this.mqtt.on('error', (err: any) => {
      store.dispatch({
        type: 'MQTT_ERROR',
        error: err,
      })
    })
  }

  subscribe(actionMap: any) {
    const actionNames = Object.keys(actionMap)
    actionNames.forEach((action) => {
      this.mqtt.subscribe(actionMap[action], { qos: 1 })
      this.topicActionMap[actionMap[action]] = action
    })
  }

  unsubscribe(actions: any) {
    const actionMap = invert(this.topicActionMap)

    if (Array.isArray(actions)) {
      let topic

      actions.forEach((action) => {
        topic = actionMap[action]
        this.mqtt.unsubscribe(topic)

        delete this.topicActionMap[topic]
      })
    } else {
      this.mqtt.unsubscribe(actionMap[actions])

      delete this.topicActionMap[actionMap[actions]]
    }
  }

  createMiddleware() {
    const mqttClient = this.mqtt

    return (store: any) => (next: any) => (action: any) => {
      if (action.mqtt) {
        const mqtt = action.mqtt
        let payload

        if (mqtt.topic && typeof mqtt.payload !== 'function') {
          payload = typeof mqtt.payload === 'string' ? mqtt.payload : JSON.stringify(mqtt.payload)
        } else if (mqtt.topic && typeof mqtt.payload === 'function') {
          payload = mqtt.payload.call(null, store.getState())
        }

        mqttClient.publish(mqtt.topic, payload, { qos: 1 })

        const modifiedAction = {
          ...action,
          mqtt: {
            payload,
            topic: mqtt.topic,
          },
        }

        return next(modifiedAction)
      }

      return next(action)
    }
  }
}

export function createClient(config: any) {
  return new MQTTRedux(config)
}
