import React from 'react'
import { Route, Switch } from 'react-router-dom'
import Home from './containers/Home'
import Device from './containers/inventory/devices'
import DeviceDetails from './containers/inventory/devices/details'
import Gateway from './containers/inventory/gateways'
import GatewayDetails from './containers/inventory/gateways/details'
import AsyncDevice from './containers/inventory/async'
import AsyncDeviceDetails from './containers/inventory/async/details'
import ExecutionLog from './containers/logging/executionLog'
import Syndication from './containers/logging/syndication/'
import Analytics from './containers/logging/analytics'
import AnalyticsDetails from './containers/logging/analytics/details'
import DevTools from './containers/logging/devTools/index'
import TrackUnitApis from './containers/logging/trackunitapis/index'
import Site from './containers/sites/sites/index'
import SiteDetails from './containers/sites/sites/details'
import SitesV2 from './containers/sites/sites-v2/index'
import Users from './containers/users/'
import NotFound from './containers/NotFound'
import Login from './containers/login/login'
import NoPermissions from './containers/NoPermissions'
import AuthenticatedRoute from './components/AuthenticatedRoute'
import UnauthenticatedRoute from './components/UnauthenticatedRoute'

export default ({ childProps }: { childProps: any }) => {
  return (
    <Switch>
      <AuthenticatedRoute path="/" exact component={Home} props={childProps} />
      <AuthenticatedRoute path="/devices" exact component={Device} props={childProps} />
      <AuthenticatedRoute path="/gateways" exact component={Gateway} props={childProps} />
      <AuthenticatedRoute path="/gateways/:barcode" exact component={GatewayDetails} props={childProps} />
      <AuthenticatedRoute path="/devices/:barcode" exact component={DeviceDetails} props={childProps} />
      <AuthenticatedRoute path="/async" exact component={AsyncDevice} props={childProps} />
      <AuthenticatedRoute path="/async/:barcode" exact component={AsyncDeviceDetails} props={childProps} />
      <AuthenticatedRoute path="/async/gw/:barcode" exact component={AsyncDeviceDetails} props={childProps} />
      <AuthenticatedRoute path="/executions" exact component={ExecutionLog} props={childProps} />
      <AuthenticatedRoute path="/analytics" exact component={Analytics} props={childProps} />
      <AuthenticatedRoute path="/analytics/:siteId" exact component={AnalyticsDetails} props={childProps} />
      <AuthenticatedRoute path="/devTools" exact component={DevTools} props={childProps} />
      <AuthenticatedRoute path="/syndication" exact component={Syndication} props={childProps} />
      <AuthenticatedRoute path="/trackunitapis" exact component={TrackUnitApis} props={childProps} />
      <AuthenticatedRoute path="/users" exact component={Users} props={childProps} />
      <AuthenticatedRoute path="/sites" exact component={Site} props={childProps} />
      <AuthenticatedRoute path="/sites/:siteId" exact component={SiteDetails} props={childProps} />
      <AuthenticatedRoute path="/sites-v2" exact component={SitesV2} props={childProps} />
      <UnauthenticatedRoute path="/login" exact component={Login} props={childProps} />
      <NoPermissions path="/no-permissions" exact component={NoPermissions} props={childProps} />
      {/* Finally, catch all unmatched routes */}
      <Route component={NotFound} />
    </Switch>
  )
}
