import API from '@aws-amplify/api'

var myInitData = (data: any) => ({
  body: data
})

export const POST_ENDPOINT = (endpoint: String, data: any) => API.post('console', `${endpoint}`, myInitData(data))
export const GET_ENDPOINT = (endpoint: String) => API.get('console', `${endpoint}`, {})
export const PUT_ENDPOINT = (endpoint: String, id: String, data: any) => API.put('console', `${endpoint}/${id}`, myInitData(data))
export const DELETE_ENDPOINT = (endpoint: String, id: String) => API.del('console', `${endpoint}/${id}`, {})
export const GET_UNIQUE_ENDPOINT = (endpoint: String, id: String) => API.get('console', `${endpoint}/${id}`, {})

export const POST = (endpoint: String, data: any) => API.post('console', `${endpoint}`, myInitData(data))
export const GET = (endpoint: String) => API.get('console', `${endpoint}`, {})
export const PUT = (endpoint: String, id: String, data: any) => API.put('console', `${endpoint}/${id}`, myInitData(data))
export const DELETE = (endpoint: String, id: String) => API.del('console', `${endpoint}/${id}`, {})
