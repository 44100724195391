import React from 'react'
import { Link } from 'react-router-dom'
import WithUserPermissions from '../../user-permissions-hoc'
import DeviceHub from '@mui/icons-material/DeviceHub'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import { CAN_VIEW_CONSOLE_LOGGING_LINK, COMPONENT_TYPE_LINK } from '../../constants/permission-types'
import { connect } from 'react-redux'
import store from '../../store/index'
import { setDrawerMenuItem } from '../../actions/index'
import { IState } from '../../reducers'

const mapStateToProps = (state: IState) => {
  return {
    drawerActiveMenu: state.drawerActiveMenu,
  }
}

interface IProps {
  drawerActiveMenu: string
}

class LwanDeviceRegLink extends React.Component<IProps> {
  handleListItemClick = (index: any) => {
    store.dispatch(setDrawerMenuItem(index))
  }

  render() {
    const { drawerActiveMenu } = this.props

    return (
      <div>
        <span className="listTitle">LORAWAN</span>
        <ListItem
          dense
          button
          component={Link}
          to="/lwan/commissioning"
          selected={drawerActiveMenu === 'lwan-commissioning'}
          onClick={() => this.handleListItemClick('lwan-commissioning')}
        >
          <ListItemIcon>
            <DeviceHub className={drawerActiveMenu === 'lwan-commissioning' ? 'activeLink' : 'nonactiveLink'} />
          </ListItemIcon>
          <ListItemText
            secondary="Device Registration"
            className={drawerActiveMenu === 'lwan-commissioning' ? 'activeLink' : 'nonactiveLink'}
          />
        </ListItem>
      </div>
    )
  }
}

const List = connect(mapStateToProps)(LwanDeviceRegLink)
export default WithUserPermissions(List, CAN_VIEW_CONSOLE_LOGGING_LINK, COMPONENT_TYPE_LINK)
