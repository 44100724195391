import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import React from 'react';

const withMediaQuery = (Component: any) => (props: any) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return <Component isMobile={isMobile} {...props} />;
};

export default withMediaQuery;
