import { CheckboxSelectionCallbackParams } from 'ag-grid-community'
import { Column, IAnalytics } from './TableInterfaces'
/**
 * Column definitions for tables
 * TODO: Replace this as part of an API change to options that passes these details to not be hard-coded
 */

// Device
export const deviceColumns: Array<Column> = [
  {
    headerName: 'Barcode',
    field: 'barcode',
    hide: false,
    sortable: true,
    resizable: true,
    cellRenderer: 'linkRenderer',
  },
  { headerName: 'App Fw', field: 'xbeeAppFw', hide: false },
  { headerName: 'Radio Fw', field: 'xbeeRadioFw', hide: true },
  { headerName: 'MAC', field: 'xbeeMacAddr', hide: true },
  {
    headerName: 'Part Number',
    field: 'partNumber',
    hide: false,
  },
  { headerName: 'Revision', field: 'revision', hide: false },
  {
    headerName: 'Gateway',
    field: 'cagBarcode',
    hide: false,
    cellRenderer: 'linkRenderer',
  },
  { headerName: 'Stage', field: 'stage', hide: false },
  { headerName: 'Type', field: 'type', hide: true },
]

export const deviceDetailColumns: Array<Column> = [
  {
    headerName: 'Time Updated',
    field: 'timeCreated',
    hide: false,
    cellRenderer: 'dateRenderer',
  },
  { headerName: 'App Fw', field: 'xbeeAppFw', hide: false },
  { headerName: 'Radio Fw', field: 'xbeeRadioFw', hide: false },
  { headerName: 'MAC', field: 'xbeeMacAddr', hide: true },
  {
    headerName: 'Part Number',
    field: 'partNumber',
    hide: true,
  },
  { headerName: 'Revision', field: 'revision', hide: true },
  {
    headerName: 'Gateway',
    field: 'cagBarcode',
    hide: false,
    cellRenderer: 'linkRenderer',
  },
  {
    headerName: 'Comment',
    field: 'comment',
    hide: true,
    sortable: false,
    filterable: false,
  },
  {
    headerName: 'Stage',
    field: 'stage',
    hide: true,
  },
]

// Gateway
export const gatewayColumns: Array<Column> = [
  {
    headerName: 'Barcode',
    field: 'barcode',
    hide: false,
    sortable: true,
    resizable: true,
    cellRenderer: 'linkRenderer',
  },
  { headerName: 'Radio Fw', field: 'xbeeRadioFw', hide: false },
  { headerName: 'Part Number', field: 'partNumber', hide: false },
  { headerName: 'Revision', field: 'revision', hide: true },
  { headerName: 'IP Address', field: 'ipAddress', hide: false },
  { headerName: 'App Version', field: 'swVersion', hide: false },
  { headerName: 'Network ID', field: 'xbeeNetworkId', hide: false },
  { headerName: 'Xbee MAC', field: 'xbeeMacAddr', hide: true },
  { headerName: 'BB MAC', field: 'bbMacAddr', hide: true },
  { headerName: 'BB Serial No.', field: 'bbSerialNo', hide: true },
  { headerName: 'BB Version', field: 'bbVersion', hide: true },
  { headerName: 'ICCID', field: 'iccid', hide: true },
  { headerName: 'IMEI', field: 'imei', hide: true },
  { headerName: 'UBoot Version', field: 'ubootVersion', hide: true },
  { headerName: 'Site', field: 'siteId', hide: false, cellRenderer: 'siteRenderer' },
  { headerName: 'Stage', field: 'stage', hide: false },
]

export const gatewayDetailColumns: Array<Column> = [
  { headerName: 'Time Updated', field: 'timeCreated', hide: false, cellRenderer: 'dateRenderer' },
  { headerName: 'Radio Fw', field: 'xbeeRadioFw', hide: false },
  { headerName: 'Part Number', field: 'partNumber', hide: true },
  { headerName: 'Revision', field: 'revision', hide: true },
  { headerName: 'IP Address', field: 'ipAddress', hide: true },
  { headerName: 'App Version', field: 'swVersion', hide: false },
  { headerName: 'Network ID', field: 'xbeeNetworkId', hide: false },
  { headerName: 'Xbee MAC', field: 'xbeeMacAddr', hide: true },
  { headerName: 'BB MAC', field: 'bbMacAddr', hide: true },
  { headerName: 'BB Serial No.', field: 'bbSerialNo', hide: true },
  { headerName: 'BB Version', field: 'bbVersion', hide: true },
  { headerName: 'ICCID', field: 'iccid', hide: true },
  { headerName: 'IMEI', field: 'imei', hide: true },
  { headerName: 'UBoot Version', field: 'ubootVersion', hide: true },
  { headerName: 'Site', field: 'siteId', hide: false, cellRenderer: 'siteRenderer' },
  { headerName: 'Stage', field: 'stage', hide: true },
]

export const gatewayRecordsColumns: Array<Column> = [
  { headerName: 'Timestamp', field: 'timestamp', hide: false, cellRenderer: 'dateRenderer' },
  { headerName: 'Time Received', field: 'timeReceived', hide: true, cellRenderer: 'dateRenderer' },
  { headerName: 'Reporting Interval', field: 'reportingInterval', hide: false },
  { headerName: 'Uptime', field: 'uptime', hide: false, cellRenderer: 'timeRenderer' },
  { headerName: 'Device Count', field: 'deviceCount', hide: false },
  { headerName: 'Sensor Count', field: 'sensorCount', hide: true },
  { headerName: 'Transmit', field: 'transmissionDelay', hide: false, cellRenderer: 'delayRenderer', sortable: false },
]

// Async devices
export const asyncDeviceColumns: Array<Column> = [
  { headerName: 'Equipment ID', field: 'equipmentId', hide: false, cellRenderer: 'linkRenderer' },
  { headerName: 'Make', field: 'make', hide: false },
  { headerName: 'Model', field: 'model', hide: false },
  { headerName: 'Device Type', field: 'deviceType', hide: false, cellRenderer: 'deviceRenderer' },
  { headerName: 'Site', field: 'siteId', hide: false, cellRenderer: 'siteRenderer' },
  { headerName: 'Dev EUI', field: 'devEui', hide: true },
  { headerName: 'Wireless ID', field: 'wirelessId', hide: true },
  { headerName: 'Reporting Interval', field: 'reportingInterval', hide: true },
  { headerName: 'SW Version', field: 'swVersion', hide: true },
]

export const asyncDeviceDetailColumns: Array<Column> = [
  { headerName: 'Timestamp', field: 'timestamp', hide: false, cellRenderer: 'dateRenderer' },
  { headerName: 'Site', field: 'siteId', hide: false, cellRenderer: 'siteRenderer' },
  { headerName: 'Dev EUI', field: 'devEui', hide: true },
  { headerName: 'Wireless ID', field: 'wirelessId', hide: true },
  { headerName: 'Reporting Interval', field: 'reportingInterval', hide: true },
  { headerName: 'SW Version', field: 'swVersion', hide: true },
  { headerName: 'Comment', field: 'comment', hide: false, sortable: false, filterable: false },
  { headerName: 'Email', field: 'email', hide: false },
]

export const asyncDeviceDataColumns: Array<Column> = [
  { headerName: 'Timestamp', field: 'timestamp', hide: false, cellRenderer: 'dateRenderer' },
  {
    headerName: 'GW Equipment Id',
    field: 'gwEquipmentId',
    hide: false,
    filterable: false,
    cellRenderer: 'asyncGwCellRenderer',
  },
  { headerName: 'GW EUI', field: 'gwEui', hide: true },
  { headerName: 'Signal Strength', field: 'signalStrength', hide: false, filterable: false },
  { headerName: 'Voltage', field: 'voltage', hide: false, filterable: false },
]

export const m7DataColumns: Array<Column> = [
  { headerName: 'Timestamp', field: 'timestamp', hide: false, cellRenderer: 'dateRenderer' },
  { headerName: 'Attribute Name', field: 'name', hide: false },
  { headerName: 'Value', field: 'value', hide: false, filterable: false },
  { headerName: 'Site', field: 'siteId', hide: false, cellRenderer: 'siteRenderer' },
  // { headerName: 'Site Id', field: 'siteId', hide: true },
]

// Sites
export const siteColumns: Array<Column> = [
  { headerName: 'Site Name', field: 'name', hide: false, cellRenderer: 'linkRenderer', sortable: false },
  { headerName: 'Company Name', field: 'company.keyword', hide: false, cellRenderer: 'companyRenderer' },
  { headerName: 'Reporting Interval', field: 'reportingInterval', hide: false },
  { headerName: 'Company ID', field: 'companyId', hide: true },
  { headerName: 'Site ID', field: 'siteId', hide: true },
  { headerName: 'Time Updated', field: 'timestamp', hide: true, cellRenderer: 'dateRenderer' },
  { headerName: 'Archived On', field: 'archived', hide: true, cellRenderer: 'dateRenderer' },
  { headerName: 'Lwan', field: 'isLwan', hide: true, cellRenderer: 'booleanRenderer' },
]

export const siteConfigChangesColumns: Array<Column> = [
  { headerName: 'Updated At', field: 'timestamp', hide: false, cellRenderer: 'dateRenderer' },
  { headerName: 'Type', field: 'type', hide: false },
  { headerName: 'Edited By', field: 'email', hide: false },
]

export const siteAlertsColumns: Array<Column> = [
  { headerName: 'Raised', field: 'raised', hide: false, cellRenderer: 'dateRenderer' },
  { headerName: 'Cleared', field: 'cleared', hide: false, cellRenderer: 'dateRenderer' },
  { headerName: 'Silenced By', field: 'silencedByEmail', hide: true },
  { headerName: 'Silenced At', field: 'silencedAt', hide: true },
  { headerName: 'Type', field: 'type', hide: false },
  { headerName: 'Details', field: 'details', hide: false, sortable: false },
  { headerName: 'Device Type', field: 'deviceType', hide: true },
  { headerName: 'Barcode', field: 'barcode', hide: true },
  { headerName: 'Channel', field: 'channel', hide: true },
  { headerName: 'Threshold', field: 'thresholdName', hide: true },
]

export const siteNotifsColumns: Array<Column> = [
  { headerName: 'Time', field: 'timestamp', hide: false, cellRenderer: 'dateRenderer' },
  { headerName: 'Email', field: 'email', hide: false },
  { headerName: 'Type', field: 'notifType', hide: false },
  { headerName: 'Medium', field: 'medium', hide: false },
  { headerName: 'Mobile', field: 'mobile', hide: true },
  { headerName: 'Error', field: 'error', hide: true },
]

export const siteDeviceDocsColumns: Array<Column> = [
  { headerName: 'Time', field: 'timestamp', hide: false, cellRenderer: 'dateRenderer' },
  { headerName: 'GWs', field: 'cagCount', hide: false, sortable: false, filterable: false },
  { headerName: 'WSs', field: 'wsnCount', hide: false, sortable: false, filterable: false },
  { headerName: 'SHs', field: 'hmcCount', hide: false, sortable: false, filterable: false },
  { headerName: 'TCAs', field: 'tcaCount', hide: false, sortable: false, filterable: false },
]

export const siteGapElimColumns: Array<Column> = [
  { headerName: 'Eqpt Id', field: 'equipmentId', hide: false },
  { headerName: 'Start', field: 'gapStart', hide: false, cellRenderer: 'dateRenderer' },
  { headerName: 'End', field: 'gapEnd', hide: true, cellRenderer: 'dateRenderer' },
  { headerName: 'State', field: 'state', hide: false },
  { headerName: 'Recovered', field: 'recovered', hide: true, sortable: false },
]

// Execution logs
export const executionLogsColumns: Array<Column> = [
  { headerName: 'Start Time', field: 'startTime', hide: false, cellRenderer: 'dateRenderer' },
  { headerName: 'Function Name', field: 'functionName', hide: false },
  { headerName: 'Stack', field: 'stack', hide: true },
  { headerName: 'Status', field: 'status', hide: false, cellRenderer: 'statusRenderer' },
  { headerName: 'Site', field: 'siteName', hide: false, cellRenderer: 'siteRenderer' },
  { headerName: 'Barcode', field: 'barcode', hide: false },
  { headerName: 'Duration', field: 'duration', hide: true, cellRenderer: 'delayRenderer' },
  { headerName: 'Site Id', field: 'siteId', hide: true },
  { headerName: 'User', field: 'user.keyword', hide: true, cellRenderer: 'userRenderer' },
  { headerName: 'Method', field: 'method', hide: true },
  { headerName: 'Endpoint', field: 'endpoint', hide: true },
  { headerName: 'Path', field: 'path', hide: true },
]

// Analytics
export const analyticsColumns: Array<Column> = [
  {
    headerName: 'Site',
    field: 'siteId',
    hide: false,
    cellRenderer: 'siteResponseRenderer',
    checkboxSelection: true,
    headerCheckboxSelection: true,
    sortable: false,
  },
]

export const siteAnalyticsColumns: Array<Column> = [
  {
    headerName: 'Function',
    field: 'functionName',
    hide: false,
    cellRenderer: 'siteFunctionRenderer',
    checkboxSelection: true,
    headerCheckboxSelection: true,
    sortable: false,
  },
]

// Trackunit
export const trackUnitApiColumns: Array<Column> = [
  {
    headerName: 'Timestamp',
    field: 'timestamp',
    hide: false,
    cellRenderer: 'dateRenderer',
  },
  {
    headerName: 'Equipment Id',
    field: 'equipmentId',
    hide: false,
  },
  { headerName: 'Site', field: 'siteName', hide: false, cellRenderer: 'siteRenderer' },
  { headerName: 'Reporting Interval', field: 'reportingInterval', hide: true },
  {
    headerName: 'Valid',
    field: 'valid',
    cellRenderer: 'validCellRenderer',
  },
]

// General
export const logsColumns: Array<Column> = [
  { headerName: 'Timestamp', field: 'timestamp', hide: false, cellRenderer: 'dateRenderer', sortable: false },
  { headerName: 'Filename', field: 'filename', hide: false, sortable: false },
  { headerName: 'Filesize', field: 'filesize', hide: false, cellRenderer: 'sizeRenderer', sortable: false },
  {
    headerName: 'Download',
    field: 'download',
    cellRenderer: 'downloadLogRenderer',
    sortable: false,
    filterable: false,
  },
]

// Site V2
export const siteV2Columns: Array<Column> = [
  { headerName: 'Site', field: 'name', hide: false, sortable: true, cellRenderer: 'wedgeRenderer' },
  { headerName: 'Company', field: 'company', hide: false, sortable: true },
  { headerName: 'Support', field: 'primarySupport', hide: false, sortable: true },
  { headerName: 'Branch', field: 'branch.id', hide: false, sortable: true },
  { headerName: 'Country', field: 'country', hide: false, sortable: true },
  { headerName: 'Province/State', field: 'state', hide: false, sortable: true },
  { headerName: 'Region', field: 'branch.regionName', hide: false, sortable: true },
  { headerName: 'Type', field: 'projectType', hide: false, sortable: true },
  { headerName: 'Sub Type', field: 'projectSubType', hide: false, sortable: true },
  { headerName: 'Tags', field: 'tags', hide: false, sortable: false },
  { headerName: 'Created', field: 'created', hide: false, sortable: true, cellRenderer: 'dateRenderer' },
  { headerName: 'Archived', field: 'archived', hide: false, sortable: true, cellRenderer: 'dateRenderer' },
  { headerName: 'GW', field: 'cagCount', hide: false, sortable: true },
  { headerName: 'WSN', field: 'wsnCount', hide: false, sortable: true },
  { headerName: 'SH', field: 'hmcCount', hide: false, sortable: true },
  { headerName: 'TCA', field: 'tcaCount', hide: false, sortable: true },
  { headerName: 'LWAN GW', field: 'lwanGwCount', hide: true, sortable: true },
  { headerName: 'LWAN WSN', field: 'lwanWsnCount', hide: true, sortable: true },
  { headerName: 'DIGI M7', field: 'm7Count', hide: true, sortable: true },
  { headerName: 'LWAN M7', field: 'lwanM7Count', hide: true, sortable: true },
  { headerName: 'Internal', field: 'internal', hide: false, sortable: true, cellRenderer: 'booleanRenderer' },
  { headerName: 'Report Interval', field: 'reportingInterval', hide: false, sortable: true },
]
// Site Data
export const siteDataColumns: Array<Column> = [
  { headerName: 'Timestamp', field: 'timestamp', hide: false, cellRenderer: 'dateRenderer', sortable: true },
]