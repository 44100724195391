import React, { useState, useEffect } from 'react'
import Grid from '@mui/material/Grid'
import { styled } from '@mui/material/styles'
import { setDrawerMenuItem } from '../actions'
import store from '../store'
import Widget from '../components/Widget/Widget'

const PREFIX = 'Home'
const classes = {
  divBg: `${PREFIX}-divBg`,
}

const Root = styled('div')(() => ({
  [`&.${classes.divBg}`]: {
    padding: 10,
    backgroundColor: 'transparent',
  },
}))

let usersWidgetParams = {
  metric: 'monthly',
  timeRange: 7,
}
let equipmentWidgetParams = {
  timeRange: 7,
}
let activeSitesWidgetParams = {
  timeRange: 7,
}
let reportWidgetParams = {
  timeRange: 7,
}

const Dashboard = (props: any) => {
  useEffect(() => {
    store.dispatch(setDrawerMenuItem('home'))
  }, [])

  return (
    <Root className={classes.divBg}>
      <Grid container rowSpacing={2} columnSpacing={2}>
        <Widget
          id="active-sites"
          size="md"
          variant="graph"
          heading="Active Sites"
          options="devices/metrics/site/options"
          endpoint="devices/metrics/site/search"
          params={activeSitesWidgetParams}
          subheading="Number of active sites counted each day for the last 7 days."
          showMetric={false}
          frequency="daily"
          showTimeRange={true}
        />
        <Widget
          id="users"
          size="md"
          variant="graph"
          heading="Monthly Active Users"
          endpoint="users/metrics/search"
          options="users/metrics/options"
          params={usersWidgetParams}
          subheading="Number of unique users counted in a 30-day sliding window each day for the last 7 days."
          showMetric={true}
          frequency="daily"
          showTimeRange={true}
        />
        <Widget
          id="equipment"
          size="md"
          variant="graph"
          heading="Assigned Devices"
          options="devices/metrics/options"
          endpoint="devices/metrics/search"
          params={equipmentWidgetParams}
          subheading="Number of unique devices, gateways and M7/LWAN devices assigned to a site counted each day for the last 7 days."
          showMetric={false}
          frequency="daily"
          showTimeRange={true}
        />
        <Widget
          id="report"
          size="md"
          variant="graph"
          heading="Reports Generated"
          options="reporting/metrics/options"
          endpoint="reporting/metrics/search"
          params={reportWidgetParams}
          subheading="Number of reports generated counted each day for the last 7 days."
          showMetric={false}
          frequency="daily"
          showTimeRange={true}
        />
      </Grid>
    </Root>
  )
}

export default Dashboard
