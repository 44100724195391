import React, { useEffect, useState } from 'react'
import ReactEchartsCore from 'echarts-for-react/lib/core'
import echarts from 'echarts/lib/echarts'
import 'echarts/lib/chart/tree'
import 'echarts/lib/chart/line'
import 'echarts/lib/chart/lines'
import 'echarts/lib/chart/bar'
import 'echarts/lib/component/tooltip'
import 'echarts/lib/component/dataZoom'
import 'echarts/lib/component/brush'
import 'echarts/lib/component/toolbox'
import 'echarts/lib/component/title'
import 'echarts/lib/component/legend'
import 'echarts/lib/component/graphic'
import 'echarts/lib/component/grid'
import { IGraphProps } from '../utils'
import { useTheme } from '@mui/material'
import moment from 'moment'

const ConfidenceBand = (props: IGraphProps) => {
  const { name, yAxisTitle, seriesData, loading, frequency, timeRange } = props
  const theme = useTheme()
  const [series, setSeries] = useState<any>()
  const [noData, setNoData] = useState(false)

  useEffect(() => {
    let formattedData = new Array()

    for (const key of Object.keys(seriesData)) {
      if (key == 'min') {
        let series = {
          z: -1,
          name: 'Min',
          type: 'line',
          data: seriesData[key],
          lineStyle: {
            opacity: 0,
          },
          areaStyle: {
            color: theme.palette.mode == 'dark' ? ['rgba(255, 255, 255)', 'rgba(255, 255, 255)'] : 'white',
            origin: 'start',
          },
          symbol: 'none',
        }
        formattedData.push(series)
      } else if (key == 'max') {
        let series = {
          z: -1,
          name: 'Max',
          type: 'line',
          data: seriesData[key],
          lineStyle: {
            opacity: 0,
          },
          areaStyle: {
            color: '#ccc',
            origin: 'start',
          },
          symbol: 'none',
        }
        formattedData.push(series)
      } else if (key == 'mean') {
        let series = {
          name: 'Mean',
          type: 'line',
          data: seriesData[key],
        }
        formattedData.push(series)
      }
    }
    setSeries(formattedData)
  }, [seriesData])

  return (
    <ReactEchartsCore
      style={{ height: '100%' }}
      echarts={echarts}
      notMerge={true}
      showLoading={loading}
      loadingOption={{
        maskColor: theme.palette.mode == 'dark' ? 'rgb(0,0,0,0.8)' : 'rgb(255,255,255,0.8)',
        textColor: theme.palette.mode == 'dark' ? 'white' : 'black',
      }}
      option={{
        color: ['#ef271b', '#8F00FF', '#17ffc4', '#ADFF02', '#FF006D', '#FF7D00', '#FFDD00', '#01BEFE'],
        grid: { top: 40, bottom: 0, right: 20, left: 50, height: '83%', containLabel: true },
        graphic: {
          type: 'text',
          left: 'center',
          top: 'middle',
          invisible: !noData,
          style: { text: 'No data available', fill: theme.palette.mode === 'dark' ? 'white' : 'black' },
        },
        tooltip: {
          show: true,
          trigger: 'axis',
          triggerOn: 'mousemove',
          confine: true,
        },
        xAxis: {
          type: 'time',
          name: 'Date',
          nameLocation: 'middle',
          nameGap: 25,
          splitLine: {
            show: false,
          },
          axisLabel: {
            color: theme.palette.mode == 'dark' ? 'white' : 'black',
            formatter: function (value: string) {
              if (frequency == 'hourly' && timeRange == 1) {
                return moment(value).format('HH:mm')
              } else {
                return moment.utc(value).format('MM/DD')
              }
            },
          },
          axisLine: {
            lineStyle: {
              color: theme.palette.mode == 'dark' ? 'white' : 'black',
            },
          },
          axisTick: {
            lineStyle: {
              color: theme.palette.mode == 'dark' ? 'white' : 'black',
            },
          },
        },
        yAxis: {
          name: yAxisTitle,
          nameLocation: 'middle',
          nameGap: 50,
          type: 'value',
          min: function (value) {
            return 0
          },
          axisLabel: {
            color: theme.palette.mode == 'dark' ? 'white' : 'black',
          },
          axisLine: {
            lineStyle: {
              color: theme.palette.mode == 'dark' ? 'white' : 'black',
            },
          },
          axisTick: {
            lineStyle: {
              color: theme.palette.mode == 'dark' ? 'white' : 'black',
            },
          },
        },
        series: series,
      }}
    ></ReactEchartsCore>
  )
}

export default ConfidenceBand
