import React from 'react'
import { Link } from 'react-router-dom'
import WithUserPermissions from '../../user-permissions-hoc'
import Description from '@mui/icons-material/ErrorOutlineOutlined'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import { CAN_VIEW_CONSOLE_LOGGING_LINK, COMPONENT_TYPE_LINK } from '../../constants/permission-types'
import { connect } from 'react-redux'
import store from '../../store/index'
import { setDrawerMenuItem } from '../../actions/index'
import { IState } from '../../reducers'

const mapStateToProps = (state: IState) => {
  return {
    drawerActiveMenu: state.drawerActiveMenu
  }
}

interface IProps {
  drawerActiveMenu: string
}

class ErrorLogLink extends React.Component<IProps> {
  render() {
    const { drawerActiveMenu } = this.props

    return (
      <div>
        <ListItem
          dense
          button
          component={Link}
          to='/syndication'
          selected={drawerActiveMenu === 'syndication'}
          onClick={() => store.dispatch(setDrawerMenuItem('syndication'))}
        >
          <ListItemIcon>
            <Description className={drawerActiveMenu === 'syndication' ? 'activeLink' : 'nonactiveLink'} />
          </ListItemIcon>
          <ListItemText secondary='Syndication' className={drawerActiveMenu === 'syndication' ? 'activeLink' : 'nonactiveLink'} />
        </ListItem>
      </div>
    )
  }
}

const List = connect(mapStateToProps)(ErrorLogLink)
export default WithUserPermissions(List, CAN_VIEW_CONSOLE_LOGGING_LINK, COMPONENT_TYPE_LINK)
