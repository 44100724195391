import React, { useEffect, useState } from 'react'
import Auth from '@aws-amplify/auth'
import AWS from 'aws-sdk'
import { styled } from '@mui/material/styles'
import store from '../../../store/index'
import { setDrawerMenuItem } from '../../../actions/index'
import WedgeTable from '../../../components/Table/WedgeTable'
import { executionLogsColumns } from '../../../components/Table/constants/TableData'
import { GET_ENDPOINT, POST_ENDPOINT } from '../../../api-request'
import { LogStream } from '../executions/LogStream'
import { TraceTree } from '../executions/TraceTree'
import { Grid, Paper } from '@mui/material'

AWS.config.region = 'us-west-2'

const PREFIX = 'executions'
const classes = {
  root: `${PREFIX}-root`,
  table: `${PREFIX}-table`,
  tableWrapper: `${PREFIX}-tableWrapper`,
  tableWrapperNon: `${PREFIX}-tableWrapperNon`,
  divBg: `${PREFIX}-divBg`,
  loading: `${PREFIX}-loading`,
}

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.root}`]: {
    width: '100%',
    marginTop: theme.spacing(3),
  },

  [`& .${classes.table}`]: {
    minWidth: 1020,
  },

  [`& .${classes.tableWrapper}`]: {
    overflowX: 'auto',
    display: 'block',
  },

  [`& .${classes.tableWrapperNon}`]: {
    display: 'none',
  },

  [`&.${classes.divBg}`]: {
    padding: 25,
  },

  [`& .${classes.loading}`]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom: 35,
  },
}))

const Index = () => {
  const [selectedRow, setSelectedRow] = useState<null | any>(null)
  const [data, setData] = useState<any>([])
  const [source, setSource] = useState<any>('')
  const [logsLoading, setLogsLoading] = useState(false)

  const tProps = {
    tHeader: 'Execution History',
    tName: 'execution logs',
    tColumns: executionLogsColumns,
    optionsEndpoint: 'logging/executionLogs/options',
    searchEndpoint: 'logging/executionLogs/search',
    handleSelectRow: (row: any) => {
      if (row) {
        handleRowClick({ requestId: row.requestId, source: row })
      }
    },
  }

  const queryAgainstSource = async (source: any) => {
    const params = {
      logGroupName: source.logGroupName /* required */,
      logStreamName: source.logStreamName /* required */,
      endTime: new Date(source.endTime).getTime() + 250,
      startTime: new Date(source.startTime).getTime(),
      nextToken: null,
    }

    let events: any[] = []

    setLogsLoading(true)

    do {
      const response = await POST_ENDPOINT('logging/executionLogs/events', params)
      events = events.concat(response.events)
      params.nextToken = response.nextToken
    } while (params.nextToken)
    setSource({ events })
    setLogsLoading(false)
  }

  const clearChildren = (data: any) => {
    data.itemStyle = {
      borderColor: 'red',
    }

    for (let child of data.children) {
      clearChildren(child)
    }
  }

  const findChild = (data: any, trace: any[]): any => {
    trace.shift() // Remove 'parent'
    if (trace.length > 0) {
      const target = trace[0]
      for (let child of data.children) {
        if (child.requestId === target) {
          return findChild(child, trace)
        }
      }
    } else {
      return data
    }
  }

  const getData = async (requestId: string, row: any) => {
    GET_ENDPOINT(`logging/executionLogs/trace/requestId/${requestId}`).then((response) => {
      let trace = row.source.trace
      trace.push(row.requestId)
      clearChildren(response)
      const child = findChild(response, trace)
      if (child) {
        child.itemStyle = {
          borderColor: 'blue',
        }
      }
      setData(response)
    })
  }

  const handleRowClick = (row: { requestId: string; source: { trace: string | any[] } }) => {
    if (selectedRow === null || selectedRow.requestId !== row.requestId) {
      setSelectedRow(row)
      let reqId = row.source.trace.length > 0 ? row.source.trace[0] : row.requestId
      getData(reqId, row)
      queryAgainstSource(row.source)
    }
  }

  const onChartClick = (event: { data: { trace: any }; value: any }) => {
    let trace = event.data.trace
    trace.push(event.value)
    clearChildren(data)
    const child = findChild(data, trace)
    if (child) {
      child.itemStyle = {
        borderColor: 'blue',
      }
    }
    setData(data)
    queryAgainstSource(child)
  }

  useEffect(() => {
    store.dispatch(setDrawerMenuItem('executions'))

    const getAWSCredentials = async () => {
      try {
        let sessionDetails: any = await Auth.currentCredentials()
        await Auth.currentAuthenticatedUser()

        AWS.config.credentials = new AWS.CognitoIdentityCredentials(sessionDetails.params)
      } catch (error) {
        if (error instanceof Error) alert(error.message)
      }
    }

    try {
      getAWSCredentials()
    } catch (e) {
      console.log(e)
    }
  }, [])

  return (
    <Root className={classes.divBg}>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={12}>
          <WedgeTable {...tProps} />
        </Grid>
        <Grid item xs={12} lg={12}>
          <LogStream events={source.events} loading={logsLoading} />
        </Grid>
        <Grid item xs={12} lg={12}>
          <Paper elevation={2}>
            <TraceTree data={data} onClickHandler={onChartClick} />
          </Paper>
        </Grid>
      </Grid>
    </Root>
  )
}

export default Index
