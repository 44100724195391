import React, { Fragment } from 'react'
import { Route, Redirect } from 'react-router-dom'
import NotFound from '../containers/NotFound'

interface IProps {
  component: any
  path: string
  props: any
  exact?: boolean
}

const RouterComponent = ({ component: Component, props: cProps, ...rest }: IProps) => {
  const isUserPermittedPath = cProps.userPermittedPaths.includes(rest.path) || cProps.userPermittedPaths.includes('*')
  const protectedPath =
    rest.path.includes('analytics') || rest.path.includes('devTools') || rest.path.includes('testScripts')

  return (
    <Route
      {...rest}
      render={(props) => {
        return (
          <Fragment>
            {cProps.isAuthenticated ? (
              <Fragment>
                {cProps.userPermittedPaths.length === 0 || cProps.userDefaultPath === '' ? (
                  <Redirect to={`/no-permissions`} />
                ) : (
                  <Fragment>
                    {isUserPermittedPath ? (
                      protectedPath ? (
                        cProps.isDeveloper ? (
                          <Component {...props} {...cProps} />
                        ) : (
                          <NotFound />
                        )
                      ) : (
                        <Component {...props} {...cProps} />
                      )
                    ) : (
                      <Redirect to={`${cProps.userDefaultPath}`} />
                    )}
                  </Fragment>
                )}
              </Fragment>
            ) : (
              <Redirect to={`/login?redirect=${props.location.pathname}${props.location.search}`} />
            )}
          </Fragment>
        )
      }}
    />
  )
}

export default RouterComponent
