import React from 'react'
import { Link } from 'react-router-dom'
import WithUserPermissions from '../../user-permissions-hoc'
import Description from '@mui/icons-material/ErrorOutlineOutlined'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import { CAN_VIEW_CONSOLE_LOGGING_LINK, COMPONENT_TYPE_LINK } from '../../constants/permission-types'
import { connect } from 'react-redux'
import store from '../../store/index'
import { setDrawerMenuItem } from '../../actions/index'
import { IState } from '../../reducers'
import { Api } from '@mui/icons-material'

const mapStateToProps = (state: IState) => {
  return {
    drawerActiveMenu: state.drawerActiveMenu,
  }
}

interface IProps {
  drawerActiveMenu: string
}

class TrackunitLink extends React.Component<IProps> {
  handleListItemClick = (index: any) => {
    store.dispatch(setDrawerMenuItem(index))
  }

  render() {
    const { drawerActiveMenu } = this.props

    return (
      <div>
        <ListItem
          dense
          button
          component={Link}
          to="/trackunitapis"
          selected={drawerActiveMenu === 'trackunitapis'}
          onClick={() => this.handleListItemClick('trackunitapis')}
        >
          <ListItemIcon>
            <Api className={drawerActiveMenu === 'trackunitapis' ? 'activeLink' : 'nonactiveLink'} />
          </ListItemIcon>
          <ListItemText
            secondary="Trackunit APIs"
            className={drawerActiveMenu === 'trackunitapis' ? 'activeLink' : 'nonactiveLink'}
          />
        </ListItem>
      </div>
    )
  }
}

const List = connect(mapStateToProps)(TrackunitLink)
export default WithUserPermissions(List, CAN_VIEW_CONSOLE_LOGGING_LINK, COMPONENT_TYPE_LINK)
