import { actionTypes } from '../constants/action-types'
import store from '../store/index'
import { createClient } from '../lib/mqttredux'

export interface IState {
  showNotification: boolean
  newNotificationMessage: string
  cagData: any
  cagInventory: any
  deviceInventory: any
  asyncInventory: any
  siteList: any
  errorList: any[]
  kpi: any
  drawerActiveMenu: string
}

const initialState: IState = {
  showNotification: false,
  newNotificationMessage: '',
  cagData: {},
  cagInventory: {},
  deviceInventory: {},
  asyncInventory: {},
  siteList: {},
  errorList: [],
  kpi: {},
  drawerActiveMenu: 'home',
}

let mqttRedux: any

const rootReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.OPEN_NOTIFICATION:
      return { ...state, showNotification: action.payload }
    case actionTypes.CLOSE_NOTIFICATION:
      return { ...state, showNotification: action.payload }
    case actionTypes.NEW_NOTIFICATION_MESSAGE:
      return { ...state, showNotification: true, newNotificationMessage: action.payload }
    case actionTypes.SAVE_CAG_INVENTORY:
      return { ...state, cagInventory: action.payload }
    case actionTypes.SAVE_DEVICE_INVENTORY:
      return { ...state, deviceInventory: action.payload }
    case actionTypes.SAVE_ASYNC_DEVICE_INVENTORY:
      return { ...state, asyncInventory: action.payload }
    case actionTypes.SAVE_SITE_LIST:
      return { ...state, siteList: action.payload }
    case actionTypes.SAVE_ERROR_LIST:
      return { ...state, errorList: action.payload }
    case actionTypes.SAVE_KPI:
      return { ...state, kpi: action.payload }
    case actionTypes.SET_DRAWER_MENU_ITEM:
      return { ...state, drawerActiveMenu: action.payload }

    case actionTypes.SUBSCRIBE_TO_CAG:
      let cag_payload = action.payload
      return {
        ...state,
        cagData: {
          payload: cag_payload,
          topic: action.topic,
        },
      }

    case actionTypes.CONNECT_TO_MQTT:
      console.log('CONNECT_TO_MQTT')
      const payload = action.payload
      mqttRedux = createClient(payload.config)
      //Add actions mapped to topics here
      const actionTopicMapping = {
        SUBSCRIBE_TO_RSP_DATA: payload.topics.rsp,
        SUBSCRIBE_TO_CAG: payload.topics.cag,
      }
      console.log(actionTopicMapping)

      mqttRedux.connect(actionTopicMapping, store)
      return {
        ...state,
        showNotification: true,
        newNotificationMessage: 'Connecting to MQTT broker',
      }

    case actionTypes.MQTT_PUBLISH:
      return {
        ...state,
        showNotification: true,
        newNotificationMessage: 'MQTT Message Published to Topic',
      }

    case actionTypes.PUBLISH_TO_MQTT:
      const publish_payload = action.payload
      mqttRedux.publish(publish_payload, store)
      return {
        ...state,
        showNotification: true,
        newNotificationMessage: 'Published MQTT Message to Topic',
      }
    case actionTypes.SUBSCRIBE_TO_TOPIC:
      const topic_payload = action.payload
      console.log('[' + new Date().toLocaleString() + ']', 'Subscribing to', topic_payload)
      mqttRedux.subscribe_to_topic(topic_payload, store)
      return {
        ...state,
        showNotification: true,
        newNotificationMessage: 'Topic successfully subscribe',
      }
    case actionTypes.SUBSCRIBE_TO_RSP_DATA:
      let rsp_payload = action.payload
      return {
        ...state,
        cagData: {
          payload: rsp_payload,
          topic: action.topic,
        },
      }

    case actionTypes.MQTT_CONNECT:
      return {
        ...state,
        showNotification: true,
        newNotificationMessage: 'Connected to MQTT broker',
      }

    case actionTypes.MQTT_DISCONNECT:
      console.log('DISCONNECTED FROM MQTT BROKER')
      return {
        ...state,
        showNotification: true,
        newNotificationMessage: 'Disconnected from MQTT broker',
      }

    case actionTypes.MQTT_ERROR:
      return {
        ...state,
        showNotification: true,
        newNotificationMessage: action.err.message,
      }

    case actionTypes.SNACKBAR_CLOSE:
      return {
        ...state,
        showNotification: false,
        newNotificationMessage: '',
      }
    default:
      return state
  }
}

export default rootReducer
