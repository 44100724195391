import React, { useEffect, useState } from 'react'
import { IWidgetProps } from './utils'
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  CircularProgress,
  Grid,
  Theme,
  Typography,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import WidgetDialog from '../WidgetDialog/WidgetDialog'
import Graph from './Visualizations/Graph'
import { POST } from '../../api-request'
import moment from 'moment'
import './styles.css'

const useStyles = makeStyles((theme: Theme) => ({
  progressContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '30px',
  },
}))

/**
 * Generic widget component to display analytics/metrics
 * @param props
 * @returns widget
 */
const Widget = (props: IWidgetProps) => {
  const { id, size, variant, heading, endpoint, params, frequency } = props

  // Styling
  const classes = useStyles()
  const [itemSize, setItemSize] = useState(3)

  // Widget states
  const [loading, setLoading] = useState(false)
  const [metric, setMetric] = useState<any>()
  const [units, setUnits] = useState<string>('')
  const [seriesData, setSeriesData] = useState([] as any)
  const [dialogOpen, setDialogOpen] = useState(false)

  /**
   * Formats size of the widget within a grid
   */
  const formatSize = () => {
    switch (size) {
      case 'xs':
        setItemSize(3)
        break
      case 'sm':
        setItemSize(4)
        break
      case 'md':
        setItemSize(6)
        break
      case 'lg':
        setItemSize(9)
        break
      case 'xl':
        setItemSize(12)
        break
      default:
        break
    }
  }

  /**
   * Format data to be shown in visualization for widget
   * @param data
   */
  const formatData = (data: any) => {
    switch (frequency) {
      case 'daily':
        formatDailyData(data)
        break
      case 'hourly':
        formatHourlyData(data)
        break
      default:
        break
    }
  }

  /**
   * Formats daily data
   * @param data
   */
  const formatDailyData = (data: any) => {
    let series = new Array()
    for (let i = 0; i < data.length; i++) {
      let day = moment.utc(Object.keys(data[i])[0]).format('YYYY-MM-DD')
      let value = Object.values(data[i])[0]
      series.push([day, value])
      if (i == data.length - 1) {
        if (id == 'duration') {
          setMetric(value)
          setUnits('ms')
        } else {
          setMetric(value)
        }
      }
    }
    setSeriesData(series)
    setLoading(false)
  }

  /**
   * Formats hourly data
   * @param data
   */
  const formatHourlyData = (data: any) => {
    let series = new Array()
    for (let i = 0; i < data.length; i++) {
      let time = moment.utc(Object.keys(data[i])[0]).local().format()
      let value = Object.values(data[i])[0]
      series.push([time, value])
      if (i == data.length - 1) {
        setMetric(value)
      }
    }
    setSeriesData(series)
    setLoading(false)
  }

  /**
   * Handles opening the dialog
   */
  const handleOpenDialog = () => {
    setDialogOpen(true)
  }

  /**
   * Handles closing the dialog
   */
  const onClose = () => {
    setDialogOpen(false)
  }

  useEffect(() => {
    formatSize()

    /**
     * Gets intial data for widget visualization
     * @returns
     */
    const getData = async () => {
      setLoading(true)
      const response = await POST(endpoint, params)
      return response
    }

    getData().then((res: any) => {
      if (id == 'duration') {
        let meanData = new Array()

        // only use mean data for the duration widget
        res.docs.forEach((doc) => {
          let newDoc = {}
          newDoc[Object.keys(doc)[0]] = doc[Object.keys(doc)[0]].mean
          meanData.push(newDoc)
        })

        formatData(meanData)
      } else {
        formatData(res.docs)
      }
    })
  }, [])

  return (
    <Grid item xs={itemSize}>
      <Card variant="elevation" elevation={1}>
        <CardActionArea onClick={handleOpenDialog}>
          <CardHeader
            title={
              <Grid container justifyContent="space-between" alignItems={'center'} spacing={0}>
                <Grid item>
                  <Typography variant="h6" fontWeight={500}>
                    {heading ? heading : '...'}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant={'h6'} fontWeight={600}>
                    {metric || metric == 0 ? metric + ' ' + units : '-'}
                  </Typography>
                </Grid>
              </Grid>
            }
          >
            <Box>
              <Typography variant="h6">
                <strong>{heading}</strong>
              </Typography>
              <Typography variant="h6">
                <strong>{metric}</strong>
              </Typography>
            </Box>
          </CardHeader>
          <CardContent sx={{ height: '35vh', margin: 0, padding: 0 }}>
            {loading && (
              <div className={classes.progressContainer}>
                <CircularProgress size={28} color="secondary" />
              </div>
            )}
            {!loading && variant == 'graph' && (
              <Graph metric={metric} seriesData={seriesData} frequency={frequency} size={size} />
            )}
            {!loading && variant == 'chart' && <></>}
            {!loading && variant == 'number' && <></>}
          </CardContent>
        </CardActionArea>
      </Card>
      <WidgetDialog {...props} open={dialogOpen} onClose={onClose} />
    </Grid>
  )
}

export default Widget
