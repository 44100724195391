import React, { useEffect, useMemo, useRef, useState } from 'react'
import { AgGridReact } from 'ag-grid-react'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import './styles.css'
import { ITableProps, ICustomHeaderParams } from './constants/TableInterfaces'
import { ColDef, ICellRendererParams, SelectionChangedEvent } from 'ag-grid-community'
import { Link, useHistory } from 'react-router-dom'
import { POST } from '../../api-request'
import moment from 'moment'
import { useTheme } from '@mui/material'
import config from '../../config'

const Table = (props: ITableProps) => {
  const {
    columns,
    rows,
    loading,
    handleSort,
    refresh,
    handleSelectRow,
    multiSelect,
    fixedColSize,
    onColumnMoved,
    onColumnResize,
    sortState,
  } = props
  const theme = useTheme()

  // Grid ref and styles
  const gridRef = useRef<AgGridReact<any>>(null)
  const containerStyle = useMemo(() => ({ width: '100%', height: '100%' }), [])
  const gridStyle = useMemo(() => ({ height: '440px', width: '100%' }), [])

  // Grid states
  const [gridReady, setGridReady] = useState(false)
  const [sortField, setSortField] = useState<string>(sortState ? sortState.sortField : '')
  const [sortOrder, setSortOrder] = useState<string | null>(sortState ? sortState.sortOrder : '')
  const [defaultColDef, setDefaultColDef] = useState<ColDef>({
    sortable: true,
    flex: fixedColSize ? 0 : 1,
    minWidth: 100,
    filter: true,
    resizable: true,
    sortingOrder: ['asc', 'desc', null],
    headerComponentParams: { sortField, sortOrder },
  })

  // State to determine whether in analytics or not (for unique use cases)
  const [analytics, setAnalytics] = useState(false)
  const [rendered, setRendered] = useState(false)

  // useEffect(() => {
  //   console.log(fixedColSize)
  //   setDefaultColDef({
  //     ...defaultColDef,
  //     flex: fixedColSize ? 0 : 1,
  //   })
  // }, [fixedColSize])

  /**
   * Use effect to detect when the sort has changed and update the header
   */
  useEffect(() => {
    if (gridReady) {
      console.log(sortField, sortOrder)
      if (sortField != '' || sortOrder != '') {
        handleSort(sortField, sortOrder)
      }
      gridRef!.current!.api.refreshHeader()
      setDefaultColDef({ ...defaultColDef, headerComponentParams: { sortField, sortOrder } })
    }
  }, [sortField, sortOrder])

  useEffect(() => {
    if (gridReady) {
      if (sortState.sortField && sortState.sortOrder) {
        handleSort(sortState.sortField, sortState.sortOrder)
        let sortField = sortState.sortField
        let sortOrder = sortState.sortOrder
        gridRef!.current!.api.refreshHeader()
        setDefaultColDef({ ...defaultColDef, headerComponentParams: { sortField, sortOrder } })
      }
    }
  }, [sortState])

  /**
   * Use effect to remove sort when table is refreshed
   */
  useEffect(() => {
    if (refresh) {
      setSortField('')
      setSortOrder('')
    }
  }, [refresh])

  /**
   * Custom table header component to allow sorting through API
   * @param props sorting state passed as props for handling sorting
   * @returns custom table header
   */
  const CustomTableHeader = (props: ICustomHeaderParams) => {
    const handleSort = () => {
      console.log(props.column.getColId(), props.sortField, props.sortOrder)
      if (props.sortField == props.column.getColId()) {
        if (props.sortOrder === null) {
          setSortOrder('desc')
        } else if (props.sortOrder === 'desc') {
          setSortOrder('asc')
        } else {
          setSortOrder(null)
        }
      } else {
        setSortField(props.column.getColId())
        setSortOrder('desc')
      }
    }

    if (props.column.getColDef().sortable) {
      return (
        <div>
          <div className="customHeaderLabel" onClick={handleSort} style={{ cursor: 'pointer', marginRight: '0.5rem' }}>
            {props.displayName}
          </div>
          {props.sortField == props.column.getColId() && props.sortOrder == 'asc' && <span>&#x2191;</span>}
          {props.sortField == props.column.getColId() && props.sortOrder == 'desc' && <span>&#x2193;</span>}
        </div>
      )
    } else {
      return <div className="customHeaderLabel">{props.displayName}</div>
    }
  }

  /**
   * Renders link for certain columns with linkable data (TODO: more modular, hard coded right now)
   * @param params cell parameters
   * @returns a Link object to allow details page to be shown
   */
  const LinkCellRenderer = (params: ICellRendererParams) => {
    let table = window.location.pathname.split('/')[1]
    let col = params.column?.getColId()
    let path = ''
    let val = params.value
    if (table === 'devices' && col === 'cagBarcode') {
      path = 'gateways'
    } else if (table === 'devices' && col === 'barcode') {
      path = 'devices'
    } else if (table === 'gateways' && col === 'barcode') {
      path = 'gateways'
    } else if (table === 'gateways' && col === 'name') {
      path = 'sites'
    } else if (table === 'async' && col === 'equipmentId') {
      // console.log(params.data.type)
      if (params.data.type == 'Gateway') {
        path = 'async/gw'
      } else {
        path = 'async'
      }
    } else if (table === 'sites' && col === 'name') {
      path = 'sites'
      val = params.data.siteId
    }

    let pathname = `/${path}/` + val
    return (
      <Link className="link-renderer" to={{ pathname: pathname, state: params.data }}>
        {params.value}
      </Link>
    )
  }

  const WEDGECellRenderer = (params: ICellRendererParams) => {
    let url = ''
    if (config.setting.STAGE != 'prod') {
      url = `https://${config.setting.STAGE}.wedgesystems.com/`
    } else {
      url = `https://wedgesystems.com/`
    }

    if (params.data.hasOwnProperty('siteId')) {
      if (params.data.archived === null || new Date() < new Date(params.data.archived)) {
        url += `site/${params.data.siteId}/auth-app/floorplans/undefined`
      } else {
        url += `sites/archived/${params.data.siteId}`
      }
    }

    return (
      <a className="link-renderer" rel="noopener noreferrer" href={url} target="_blank">
        {params.value}
      </a>
    )
  }

  /**
   * Renders site link for columns relating to sites
   * @param params cell parameters
   * @returns a Link object directing to the site or unassigned label
   */
  const SiteCellRenderer = (params: ICellRendererParams) => {
    if (params.data.hasOwnProperty('siteName')) {
      let siteName = params.data.siteName ? params.data.siteName : params.data.siteId
      return <Link to={'/sites/' + params.data.siteId}>{siteName}</Link>
    } else if (params.data.hasOwnProperty('name')) {
      let siteName = params.data.name ? params.data.name : params.data.siteId
      return <Link to={'/sites/' + params.data.siteId}>{siteName}</Link>
    } else {
      return <span>Unassigned</span>
    }
  }

  /**
   * Renders site link for analytics page to get site details
   * @param params cell parameters
   * @returns a link object directing to the specific site analytics details
   */
  const SiteResponseTimeCellRenderer = (params: ICellRendererParams) => {
    let siteId = params.data.siteId
    params.node.setSelected(params.data.selected)

    if ('siteName' in params.data) {
      let siteName = params.data.siteName ? params.data.siteName : siteId
      return <Link to={{ pathname: '/analytics/' + siteId, state: params.data }}>{siteName}</Link>
    } else {
      return <Link to={{ pathname: '/analytics/' + siteId, state: params.data }}>{siteId}</Link>
    }
  }

  /**
   * Renders a site function selectable cell to be rendered in the chart
   * @param params cell parameters
   * @returns a span containing the function name
   */
  const SiteFunctionCellRenderer = (params: ICellRendererParams) => {
    params.node.setSelected(params.data.selected)

    return <span>{params.value}</span>
  }

  /**
   * Renders the device type (necessary due to schema)
   * @param params cell parameters
   * @returns a span with the device type
   */
  const DeviceCellRenderer = (params: ICellRendererParams) => {
    return <span>{params.data.deviceType}</span>
  }

  /**
   * Renders the company name (necessary due to schema)
   * @param params cell parameters
   * @returns a span with the company name
   */
  const CompanyCellRenderer = (params: ICellRendererParams) => {
    return <span>{params.data.company}</span>
  }

  /**
   * Renders the UTC time to formatted local time within the cell
   * @param params cell parameters
   * @returns a span object formatted to local time
   */
  const DateCellRenderer = (params: ICellRendererParams) => {
    if (params.value == '' || params.value == null) {
      return <span></span>
    } else {
      return <span>{moment(params.value).local().format('MMM D, YYYY HH:mm')}</span>
    }
  }

  /**
   * Renders time related columns that are not relative to date/time
   * @param params cell parameters
   * @returns a span object formatted with time
   */
  const TimeCellRenderer = (params: ICellRendererParams) => {
    const formatTimeSpan = (time: number) => {
      const years = Math.floor(time / 3.154e7)
      const months = Math.floor((time % 3.154e7) / 2.628e6)
      const days = Math.floor((time % 2.628e6) / 8.64e4)
      const hours = Math.floor((time % 8.64e4) / 3.6e3)
      const minutes = Math.floor((time % 3.6e3) / 60)
      const seconds = time % 60
      if (years) {
        return years + ' yr ' + months + ' mo'
      } else if (months) {
        return months + ' mo ' + days + ' day'
      } else if (days) {
        return days + ' day ' + hours + ' hr'
      } else if (hours) {
        return hours + ' hr ' + minutes + ' min'
      } else if (minutes) {
        return minutes + 'min ' + seconds + ' sec'
      } else {
        return seconds + ' sec'
      }
    }
    return <span>{formatTimeSpan(params.value)}</span>
  }

  /**
   * Renders a delay column for time delay columns
   * @param params cell parameters
   * @returns a span object with the time
   */
  const DelayCellRenderer = (params: ICellRendererParams) => {
    const formatTimeSpan = (delay: number) => {
      const months = Math.floor(delay / 2.628e9)
      const days = Math.floor((delay % 2.628e9) / 8.64e7)
      const hours = Math.floor((delay % 8.64e7) / 3.6e6)
      const minutes = Math.floor((delay % 3.6e6) / 60000)
      const seconds = Math.floor((delay % 60000) / 1000)
      const milliseconds = delay % 1000
      if (months) {
        return months + ' mo ' + days + ' day'
      } else if (days) {
        return days + ' day ' + hours + ' hr'
      } else if (hours) {
        return hours + ' hr ' + minutes + ' min'
      } else if (minutes) {
        return minutes + ' min ' + seconds + ' sec'
      } else if (seconds) {
        return seconds + '.' + milliseconds + ' sec'
      } else {
        return milliseconds + 'ms'
      }
    }
    if (params.value) {
      return <span>{formatTimeSpan(params.value)}</span>
    } else {
      return <span></span>
    }
  }

  /**
   * Renders response times to include units and rounding
   * @param params
   */
  const ResponseCellRenderer = (params: ICellRendererParams) => {
    const formatResponseTime = (time: number) => {
      return (time / 1000).toFixed(2)
    }

    if (params.value) {
      return <span>{formatResponseTime(params.value)}s</span>
    } else {
      return <span>-</span>
    }
  }

  /**
   * Overrides default boolean cell renderer
   * @param params cell parameters
   * @returns true or false depending on boolean value
   */
  const BooleanCellRenderer = (params: ICellRendererParams) => {
    if (params.value) {
      return <span>True</span>
    } else {
      return <span>False</span>
    }
  }

  /**
   * Renders the user (necessary due to schema)
   * @param params cell parameters
   * @returns user
   */
  const UserCellRenderer = (params: ICellRendererParams) => {
    return <span>{params.data.user}</span>
  }

  /**
   * Highlights the status in green, orange, or red depending on code
   * @param params cell paramters
   * @returns status code in green, orange, or red
   */
  const StatusCellRenderer = (params: ICellRendererParams) => {
    if (params.value == 200) {
      return <span style={{ color: 'green' }}>{params.value}</span>
    } else if (params.value == 500) {
      return <span style={{ color: 'red' }}>{params.value}</span>
    } else {
      return <span style={{ color: 'orange' }}>{params.value}</span>
    }
  }

  /**
   * Converts size from bytes to megabytes
   * @param params cell parameters
   * @returns size in megabytes3
   */
  const SizeCellRenderer = (params: ICellRendererParams) => {
    let size = params.value / 1048576
    size = Math.round((size + Number.EPSILON) * 100) / 100
    return <span>{size} MB</span>
  }

  /**
   * Gets presigned url for downloading logs from S3 bucket
   * @param params cell parameters
   * @returns redirect to download the log file
   */
  const DownloadLogCellRenderer = (params: ICellRendererParams) => {
    const getLogFile = async () => {
      let endpoint = `devices/gateways/${window.location.pathname.split('/')[2]}/logs/file`
      try {
        let res = await POST(endpoint, { key: params.data.key })
        window.open(res.url, '_blank')
      } catch (e) {
        console.log(e)
      }
    }

    return (
      <button
        onClick={() => {
          getLogFile().then((res) => console.log(res))
        }}
      >
        Download Log
      </button>
    )
  }

  const AsyncGatewayCellRenderer = (params: ICellRendererParams) => {
    let gwEquipmentId = params.data.gwEquipmentId

    const history = useHistory()

    const onClick = () => {
      history.push(`/async/gw/${gwEquipmentId}`, {
        devEui: params.data.gwEui,
        equipmentId: params.data.gwEquipmentId,
        barcode: params.data.gwEquipmentId,
      })
    }

    // if ('gwEquipmentId' in params.data) {
    //   return <Link to={{ pathname: '/async/gw/' + gwEquipmentId, state: params.data }}>{gwEquipmentId}</Link>
    // }

    if ('gwEquipmentId') {
      return <span onClick={() => onClick()}>{gwEquipmentId}</span>
    }
  }

  const ValidCellRenderer = (params: ICellRendererParams) => {
    let valid = params.data.valid

    if (valid) {
      return <span style={{ color: 'green' }}>Valid</span>
    } else {
      return <span style={{ color: 'red' }}>Invalid</span>
    }
  }

  /**
   * Custom components to be rendered within the grid
   */
  const components = useMemo<{
    [p: string]: any
  }>(() => {
    return {
      agColumnHeader: CustomTableHeader,
      linkRenderer: LinkCellRenderer,
      dateRenderer: DateCellRenderer,
      timeRenderer: TimeCellRenderer,
      delayRenderer: DelayCellRenderer,
      responseRenderer: ResponseCellRenderer,
      siteRenderer: SiteCellRenderer,
      deviceRenderer: DeviceCellRenderer,
      companyRenderer: CompanyCellRenderer,
      booleanRenderer: BooleanCellRenderer,
      userRenderer: UserCellRenderer,
      statusRenderer: StatusCellRenderer,
      sizeRenderer: SizeCellRenderer,
      downloadLogRenderer: DownloadLogCellRenderer,
      siteResponseRenderer: SiteResponseTimeCellRenderer,
      siteFunctionRenderer: SiteFunctionCellRenderer,
      wedgeRenderer: WEDGECellRenderer,
      asyncGwCellRenderer: AsyncGatewayCellRenderer,
      validCellRenderer: ValidCellRenderer,
    }
  }, [])

  /**
   * Handles selection changes
   */
  const onSelectionChange = (e: SelectionChangedEvent) => {
    // If the selection change happens when the grid api loads -> ignore it to reduce API calls
    if (e.source == 'api') {
      return
    } else {
      // checkbox event and ui select all event
      const selectedRows = gridRef.current!.api.getSelectedRows()
      if (multiSelect) {
        handleSelectRow(selectedRows)
      } else {
        handleSelectRow(selectedRows[0])
      }
    }
  }

  /**
   * Handles resizing the grid and showing overlays
   */
  useEffect(() => {
    if (gridReady) {
      if (gridRef.current?.props.columnDefs) {
        if (gridRef.current?.props.columnDefs.length > 1) {
          if (gridRef.current?.props.columnDefs[1].headerName?.includes(':')) {
            setAnalytics(true)
          }
        }
      }
      if (loading) {
        gridRef.current?.api.showLoadingOverlay()
      } else {
        gridRef.current?.api.hideOverlay()
        if (analytics) {
          gridRef.current?.columnApi.autoSizeAllColumns()
        }
        if (rows) {
          if (rows.length == 0) {
            gridRef.current?.api.showNoRowsOverlay()
          }
        }
      }
    }
  })

  /**
   * Handles row selection on data render
   */
  const onDataRendered = () => {
    setRendered(true)
    if (typeof rows != 'undefined' && multiSelect) {
      handleSelectRow(gridRef.current!.api.getSelectedRows())
    } else if (!multiSelect) {
      handleSelectRow()
    }
  }

  /**
   * Handles row selection on table data updates
   */
  const onDataUpdated = () => {
    if (rendered && multiSelect) {
      handleSelectRow(gridRef.current!.api.getSelectedRows())
    } else if (!multiSelect) {
      handleSelectRow()
    }
  }

  return (
    <div style={containerStyle}>
      <div className="table-container">
        <div style={gridStyle} className={theme.palette.mode == 'dark' ? 'ag-theme-alpine-dark' : 'ag-theme-alpine'}>
          <AgGridReact<any>
            ref={gridRef}
            rowData={rows}
            columnDefs={columns}
            rowSelection={multiSelect ? 'multiple' : 'single'}
            rowMultiSelectWithClick={multiSelect}
            onGridReady={() => setGridReady(true)}
            onFirstDataRendered={onDataRendered}
            onRowDataUpdated={onDataUpdated}
            components={components}
            defaultColDef={defaultColDef}
            onSelectionChanged={onSelectionChange}
            suppressColumnVirtualisation={false} // temporary fix to show resize all columns in site analytics
            onColumnResized={onColumnResize ? onColumnResize : () => {}}
            onColumnMoved={onColumnMoved ? onColumnMoved : () => {}}
            suppressDragLeaveHidesColumns
          />
        </div>
      </div>
    </div>
  )
}

export default Table
