/*
The search feature will likely be a bit wonky. I hide columns from view rather than removing them.
This results in their hidden content also being search on.

The reason I used this approach rather than removing the columns was a bug in primereact's code.
This bug would prevent columns from being added after a reorder operation was executed.
*/

import React from 'react'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import Auth from '@aws-amplify/auth'
import { GET_ENDPOINT, POST_ENDPOINT } from '../../../api-request'
import Grid from '@mui/material/Grid'
import { connect } from 'react-redux'
import store from '../../../store/index'
import { setDrawerMenuItem } from '../../../actions/index'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import { Select } from '@mui/material'
import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import ReactJson from 'react-json-view'
import { siteDataColumns } from '../../../components/Table/constants/TableData'
import WedgeTableWithData from '../../../components/Table/WedgeTableWithData'
import AWS from 'aws-sdk'
AWS.config.region = 'us-west-2'

const mapStateToProps = (state: { errorList: any }) => {
  return {
    errorList: state.errorList,
  }
}

const PREFIX = 'index'
const classes = {
  root: `${PREFIX}-root`,
  content: `${PREFIX}-content`,
  expanded: `${PREFIX}-expanded`,
  paperTextRoot: `${PREFIX}-paperTextRoot`,
  table: `${PREFIX}-table`,
  tableWrapper: `${PREFIX}-tableWrapper`,
  tableWrapperNon: `${PREFIX}-tableWrapperNon`,
  divBg: `${PREFIX}-divBg`,
  loading: `${PREFIX}-loading`,
  textField: `${PREFIX}-textField`,
}
const Root = styled('div')(({ theme }) => ({
  [`& .${classes.root}`]: {
    width: '50%',
  },

  [`& .${classes.paperTextRoot}`]: {
    padding: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
    height: 510,
  },

  [`& .${classes.table}`]: {
    minWidth: 400,
  },

  [`& .${classes.tableWrapper}`]: {
    overflowX: 'auto',
    display: 'block',
  },

  [`& .${classes.tableWrapperNon}`]: {
    display: 'none',
  },

  [`& .${classes.divBg}`]: {
    padding: 25,
  },

  [`& .${classes.loading}`]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom: 35,
  },

  [`& .${classes.textField}`]: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}))

interface IState {
  cols: any[]
  loading: boolean
  anchorEl: any
  expanded: any[]
  hashSalt: string
  selectedRow: any
  data: any[]
  source: string
  requestId: string
  syndicates: any[]
  siteConfig: any
  companyId: any
  siteId: any
  heaters: any[]
  controllerId: any
  heaterEnabled: any
  heaterTarget: any
  updatingConfig: boolean
  userEmail: string
  siteDataProps: {
    tHeader: string;
    tName: string;
    tColumns: any[];
    optionsEndpoint: string;
    searchEndpoint: string;
    handleSelectRow: (row: any) => void;
  },
  loadTable: boolean
}
class EnhancedTable extends React.Component<any, IState> {
  constructor(props: any) {
    super(props)
    this.state = {
      cols: [],
      loading: true,
      anchorEl: null,
      expanded: [],
      hashSalt: '',
      selectedRow: null,
      data: [],
      source: '',
      requestId: '',
      syndicates: [],
      siteConfig: null,
      companyId: null,
      siteId: null,
      heaters: [],
      controllerId: null,
      heaterEnabled: null,
      heaterTarget: null,
      updatingConfig: false,
      userEmail: '',
      siteDataProps: {
        tHeader: 'Site Data',
        tName: 'site data',
        tColumns: siteDataColumns,
        optionsEndpoint: ``,
        searchEndpoint: ``,
        handleSelectRow: (row: any) => { },
      },
      loadTable: false
    }


  }

  async componentDidMount() {
    // this.handleTableLoaded()
    store.dispatch(setDrawerMenuItem('logging_syndication'))
    try {
      let sessionDetails = await Auth.currentCredentials()
      const profile = await Auth.currentAuthenticatedUser()
      // AWS.config.credentials = new AWS.CognitoIdentityCredentials(sessionDetails.identityId)

      const syndicates = await GET_ENDPOINT('companies/syndicates')
      const companyId = syndicates.length ? syndicates[0].companyId : null
      const siteId = syndicates.length ? syndicates[0].sites[0].siteId : null
      if (syndicates.length) {
        this.setState({
          loading: false,
          syndicates: syndicates,
          companyId: companyId,
          siteId: siteId,
          siteDataProps: {
            ...this.state.siteDataProps,
            optionsEndpoint: `data/syndication/${siteId}/options`,
            searchEndpoint: `data/syndication/${siteId}/search`
          },
          loadTable: true
        })
      }
    } catch (error) {
      if (error instanceof Error) alert(error.message)
    }
  }

  retrieveSiteConfig() {
    GET_ENDPOINT(`sites/${this.state.siteId}`)
      .then((siteConfig) => {
        let heaters: any[] = []
        if (siteConfig && siteConfig.locations) {
          siteConfig.locations.forEach((location: any) => {
            if (
              location.controller == null ||
              location.controller.barcode == null ||
              location.controller.units !== 'CELSIUS'
            ) {
              return
            }
            heaters.push({
              name: location.name,
              controllerId: location.controller.id,
              enabled: location.controller.enabled,
              target: location.controller.target,
            })
          })
          // console.log(`HEATERS:`, heaters)
        }

        let controllerId = this.state.controllerId
        let heater = heaters.find((heater) => {
          return heater.controllerId === controllerId
        })
        if (heater == null && heaters.length) {
          controllerId = heaters[0].controllerId
          heater = heaters.find((heater) => {
            return heater.controllerId === controllerId
          })
        }
        // console.log(`HEATER`, heater)

        this.setState({
          siteConfig: siteConfig,
          heaters: heaters,
          controllerId: heater != null ? controllerId : null,
          heaterEnabled: heater != null ? heater.enabled : null,
          heaterTarget: heater != null ? heater.target : null,
        })

        // console.log(`HEATERS: `, {
        //     siteConfig: siteConfig,
        //     heaters: heaters,
        //     controllerId: heater != null ? controllerId : null,
        //     heaterEnabled: heater != null ? heater.enabled : null,
        //     heaterTarget: heater != null ? heater.target : null,
        // })
      })
      .catch((err) => {
        console.log(err)
      })
  }

  componentDidUpdate(prevState: any) {
    if (prevState.siteId !== this.state.siteId) {
      this.retrieveSiteConfig()
    }
  }

  renderSyndicationDataDocs = (source: any) => {
    if (source === '') {
      return <Root style={{ textAlign: 'center', height: '100%', paddingTop: '15%' }}>Select a row from the table</Root>
    } else {
      return <ReactJson displayDataTypes={false} src={source} />
    }
  }


  handleCompanyChange = (event: any) => {
    if (event.target.value === this.state.companyId) {
      return
    }

    const companyId = event.target.value
    const company = this.state.syndicates.find((syndicate: any) => {
      return syndicate.companyId === companyId
    })

    let siteId = company.sites[0].siteId
    for (const site of company.sites) {
      if (site.siteId === this.state.siteId) {
        siteId = site.siteId
      }
    }

    this.setState({
      companyId: event.target.value,
      siteId: siteId,
      controllerId: null,
    })
  }

  handleSiteChange = (event: any) => {
    if (event.target.value === this.state.siteId) {
      return
    }

    this.setState({
      siteId: event.target.value,
      controllerId: null,
      siteDataProps: {
        ...this.state.siteDataProps,
        optionsEndpoint: `data/syndication/${event.target.value}/options`,
        searchEndpoint: `data/syndication/${event.target.value}/search`
      },
      loadTable: false
    })
    setTimeout(() => {
      this.setState({ loadTable: true })
    }, 200);
  }

  handleHeaterChange = (event: any) => {
    this.setState({
      controllerId: event.target.value,
    })
  }

  handleHeaterEnabledSelect = (event: any) => {
    console.log(`handleHeaterEnabledSelect:`, event)
    this.setState({
      heaterEnabled: event.target.value,
    })
  }

  handleTargetTextFieldChange = (event: any) => {
    console.log(`handleTargetTextField:`, event)
    this.setState({
      heaterTarget: event.target.value,
    })
  }

  handleUpdateButtonClick = (event: any) => {
    console.log(`UPDATE clicked`)
    this.setState({
      updatingConfig: true,
    })

    let heater = this.state.heaters.find((heater) => {
      return heater.controllerId === this.state.controllerId
    })
    const enabledModified = heater === null ? false : heater.enabled !== this.state.heaterEnabled
    const targetModified = heater === null ? false : heater.target !== this.state.heaterTarget

    POST_ENDPOINT('sites/syndication/command', {
      companyId: this.state.companyId,
      userEmail: this.state.userEmail,
      command: 'SET_CONTROLLER_V1',
      corrId: new Date().getTime().toString(),
      timestamp: new Date().toISOString(),
      args: {
        siteId: this.state.siteId,
        controllerId: this.state.controllerId,
        target: targetModified ? parseFloat(this.state.heaterTarget) : undefined,
        enabled: enabledModified ? this.state.heaterEnabled : undefined,
      },
    })
      .then((result) => {
        console.log(`handleUpdateButtonClick:`, result)
        let heaters = JSON.parse(JSON.stringify(this.state.heaters))
        let heater = heaters.find((heater: { controllerId: any }) => {
          return heater.controllerId === this.state.controllerId
        })
        heater.enabled = this.state.heaterEnabled
        heater.target = this.state.heaterTarget
        this.setState({
          updatingConfig: false,
          heaters: heaters,
        })
        this.retrieveSiteConfig()
      })
      .catch((err) => {
        alert(err)
        console.log(`handleUpdateButtonClick:`, err)
      })
      .finally(() => {
        this.setState({
          updatingConfig: false,
        })
      })
  }

  render() {
    const company = this.state.syndicates.find((syndicate) => {
      return syndicate.companyId === this.state.companyId
    })

    let heater = this.state.heaters.find((heater) => {
      return heater.controllerId === this.state.controllerId
    })
    const enabledModified = heater == null ? false : heater.enabled !== this.state.heaterEnabled
    const targetModified = heater == null ? false : heater.target !== this.state.heaterTarget
    const updateEnabled = (enabledModified || targetModified) && !this.state.updatingConfig

    if (heater != null) {
      console.log(
        `enabledModified: ${heater.enabled} ${enabledModified} targetModified: ${heater.target} ${targetModified}`
      )
    }

    return (
      <div className={classes.divBg}>
        <h2>Syndication</h2>

        {this.state.loading === true || this.state.companyId != null || (
          <h4>No active sites for syndicated companies found</h4>
        )}

        {this.state.companyId === null ? (
          <div></div>
        ) : (
          <div>
            <FormControl variant="standard">
              <InputLabel id="select-company-label">Company</InputLabel>
              <Select
                variant="standard"
                id="select-company"
                value={this.state.companyId}
                onChange={this.handleCompanyChange}
                style={{ marginRight: '10px', marginBottom: '30px' }}
                disabled={this.state.updatingConfig}
              >
                {this.state.syndicates.map((syndicate) => (
                  <MenuItem key={syndicate.companyId} value={syndicate.companyId}>
                    {syndicate.companyName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl variant="standard">
              <InputLabel id="select-site-label">Site</InputLabel>
              <Select
                variant="standard"
                id="select-site"
                value={this.state.siteId}
                onChange={this.handleSiteChange}
                style={{ marginRight: '10px' }}
                disabled={this.state.updatingConfig}
              >
                {company.sites.map((site: any) => (
                  <MenuItem key={site.siteId} value={site.siteId}>
                    {site.siteName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        )}

        {/* {this.state.controllerId == null || (
          <div style={{ marginBottom: '30px' }}>
            <h3>Heater Configuration</h3>
            <div style={{ marginRight: '70px', verticalAlign: 'middle' }}>
              <FormControl style={{ verticalAlign: 'middle' }} variant="standard">
                <InputLabel id="select-heater-label">Heater</InputLabel>
                <Select
                  variant="standard"
                  id="select-heater"
                  value={this.state.controllerId}
                  style={{ marginRight: '10px', marginBottom: '10px' }}
                  disabled={this.state.updatingConfig}
                >
                  {this.state.heaters.map((heater) => (
                    <MenuItem key={heater.controllerId} value={heater.controllerId}>
                      {heater.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl variant="standard">
                <InputLabel id="select-heater-enabled-label">Enabled</InputLabel>
                <Select
                  variant="standard"
                  id="select-heater-enabled"
                  style={{ marginRight: '10px', color: enabledModified ? 'blue' : 'black' }}
                  value={this.state.heaterEnabled}
                  onChange={this.handleHeaterEnabledSelect}
                  disabled={this.state.updatingConfig}
                >
                  <MenuItem key="1" value={`true`}>
                    True
                  </MenuItem>
                  <MenuItem key="2" value={`false`}>
                    False
                  </MenuItem>
                </Select>
              </FormControl>
              <TextField
                variant="standard"
                id="heater-target"
                label="Target"
                type="number"
                InputLabelProps={{ shrink: true }}
                InputProps={{ style: { color: targetModified ? 'blue' : 'black' } }}
                value={this.state.heaterTarget}
                onChange={this.handleTargetTextFieldChange}
                disabled={this.state.updatingConfig}
                style={{ width: '70px', marginRight: '10px' }}
              />
              <Button variant="contained" disabled={!updateEnabled} onClick={this.handleUpdateButtonClick}>
                Update
                <img
                  src={require('../../../assets/img/ZZ5H.gif')}
                  width="20px"
                  hidden={!this.state.updatingConfig}
                  alt=""
                />
              </Button>
            </div>

            <div style={{ marginTop: '30px', verticalAlign: 'middle' }}></div>
          </div>
        )} */}

        {this.state.siteId == null || (
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12}>
              {(this.state.loadTable) && <WedgeTableWithData {...this.state.siteDataProps} />}
            </Grid>
          </Grid>
        )}
      </div>
    )
  }
}

const SyndicationPage = connect(mapStateToProps)(EnhancedTable)
export default SyndicationPage
