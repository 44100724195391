export interface IMetricDialogProps {
  /* Id of metric */
  id: string
  /* Dialog open */
  open: boolean
  /* On dialog close */
  onClose: () => void
  /* Type of widget */
  variant: 'graph' | 'number' | 'chart'
  /* Heading */
  heading: string
  /* Options endpoint */
  options: string
  /* Search endpoint */
  endpoint: string
  /* Endpoint params*/
  params: any
  /* Subheading */
  subheading: string
  /* Show metric options */
  showMetric: boolean
  /* Frequency of metric update */
  frequency: 'daily' | 'hourly'
  /* Redux prop to send notification */
  newNotificationMessage: any
  /* Show time range select option */
  showTimeRange: boolean
}

export interface IGraphProps {
  name: string
  yAxisTitle: string
  seriesData: any
  loading: boolean
  frequency: 'daily' | 'hourly'
  timeRange: number
}

export interface IMapProps {
  name: string
  data: any
  loading: boolean
}

// Select Props
const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 250,
      width: 220,
    },
  },
}
