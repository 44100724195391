import React, { Fragment } from 'react';
import { Route } from 'react-router-dom';

const RouterComponent = ({ component: C, props: cProps, ...rest }: any) => {
  return (
    <Route
      {...rest}
      render={(props) => (
        <Fragment>
          <div className='NotFound'>
            <h3>Sorry, you do not have permissions set up for your account!</h3>
          </div>
        </Fragment>
      )}
    />
  );
};

export default RouterComponent;
