import React, { useState, useEffect, ChangeEvent, FormEvent } from 'react'
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  ClickAwayListener,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  Grow,
  IconButton,
  InputBase,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Select,
  Stack,
  TablePagination,
  TextField,
  Tooltip,
  Typography,
  styled,
  useTheme,
} from '@mui/material'
import Table from './Table'
import { siteV2Columns } from './constants/TableData'
import {
  Column,
  IFilter,
  IPagination,
  ISite,
  ISiteFilter,
  ISiteFilterOptions,
  ISortState,
} from './constants/TableInterfaces'
import { headerMap, regionMap, defaultFilters, defaultSiteFilter, defaultCounts } from './constants/Constants'
import { Close, FileDownload, FilterList, MoreVert, Refresh, Save } from '@mui/icons-material'
import { ColDef, ColumnMovedEvent, ColumnResizedEvent } from 'ag-grid-community'
import './styles.css'
import { StatisticList } from '../StatisticsList'
import { Counts } from '../../utils/types/counts'
import { DELETE, GET, POST, PUT } from '../../api-request'
import { capitalize } from '../../utils/utils'
import { geography } from '../../constants/geography'
import moment from 'moment'

const ExcelJS = require('exceljs')
const PREFIX = 'SitesTable'
const defaultWidth = 173

const classes = {
  root: `${PREFIX}-root`,
  paperTextRoot: `${PREFIX}-paperTextRoot`,
  table: `${PREFIX}-table`,
  tableWrapper: `${PREFIX}-tableWrapper`,
  tableWrapperNon: `${PREFIX}-tableWrapperNon`,
  divBg: `${PREFIX}-divBg`,
  loading: `${PREFIX}-loading`,
  textField: `${PREFIX}-textField`,
}

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.root}`]: {
    width: '100%',
  },

  [`& .${classes.paperTextRoot}`]: {
    padding: theme.spacing(2),
    height: 510,
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
    body: {
      overflow: 'auto',
    },
  },

  [`& .${classes.table}`]: {
    minWidth: 1020,
  },

  [`& .${classes.tableWrapper}`]: {
    overflowX: 'auto',
    display: 'block',
  },

  [`& .${classes.tableWrapperNon}`]: {
    display: 'none',
  },

  [`& .${classes.divBg}`]: {
    padding: 25,
  },

  [`& .${classes.loading}`]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom: 35,
  },

  [`& .${classes.textField}`]: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}))

const SitesTable = () => {
  const theme = useTheme()
  const themeColor = theme.palette.mode == 'dark' ? 'primary' : 'secondary'

  /* Custom popper component */
  const customPopper = function (props: any) {
    return <Popper {...props} style={{ width: 'fit-content', minWidth: '250px' }} placement="bottom-start" />
  }

  /* First render state */
  const [firstRender, setFirstRender] = useState<boolean>(true)

  /* Table Component Props */
  const [columns, setColumns] = useState<Column[]>(siteV2Columns)
  const [rows, setRows] = useState<ISite[]>([])
  const [tableDataLoading, setTableDataLoading] = useState(true)
  const [refresh, setRefresh] = useState<boolean>(false)

  /* Stats Component Props */
  const [counts, setCounts] = useState<Counts>(defaultCounts)

  /* Column states */
  const [columnOptionsOpen, setColumnOptionsOpen] = useState<boolean>(false)
  const [anchorColEl, setAnchorColEl] = useState<HTMLElement | null>(null)

  /* Site filter states */
  const [siteFilters, setSiteFilters] = useState<ISiteFilter[] | null>([])
  const [showSave, setShowSave] = useState(false)
  const [sortState, setSortState] = useState<ISortState>({ sortField: '', sortOrder: '' })

  /* Active site filter states */
  const [activeSiteFilter, setActiveSiteFilter] = useState<ISiteFilter | null>(defaultSiteFilter)
  const [activeFilterValues, setActiveFilterValues] = useState<ISiteFilterOptions>(defaultFilters)

  /* Dialog state */
  const [open, setOpen] = useState<boolean>(false)
  const [siteFilterName, setSiteFilterName] = useState<string>('')
  const [dialogProps, setDialogProps] = useState({
    id: '',
    dialogTitle: '',
    primaryBtnText: '',
    secondaryBtnText: '',
    primaryAction: (val) => {},
    secondaryAction: () => {},
  })
  const [primaryActionDisabled, setPrimaryActionDisabled] = useState<boolean>(false)
  const [error, setError] = useState<boolean>(false)
  const [errorMsg, setErrorMsg] = useState<string>('')
  const [fileName, setFileName] = useState<string>('')

  /* Table filter states */
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const [filterableColumns, setFilterableColumns] = useState<Array<IFilter>>()
  const [currentFilter, setCurrentFilter] = useState<IFilter>()
  const [columnFilterOpen, setColumnFilterOpen] = useState<boolean>(false)
  const [filterOptionsOpen, setFilterOptionsOpen] = useState<boolean>(false)
  const [isFilterEnum, setIsFilterEnum] = useState<boolean>(false)
  const [filtered, setFiltered] = useState<boolean>(false)
  const [filterValues, setFilterValues] = useState<ISiteFilterOptions>(defaultFilters)

  /* Table chip states */
  const [filterChips, setFilterChips] = useState<any>([])

  /* Table pagination states */
  const [pagination, setPagination] = useState<IPagination>({ rowsPerPage: 10, page: 0, count: 0 })

  /* Site filter functions */

  /**
   * Handles save as btn click
   */
  const saveAsFilter = () => {
    setOpen(true)

    // Saves an existing filter
    if (activeSiteFilter.id != 'showAll') {
      setDialogProps({
        id: 'saveAs',
        dialogTitle: `Save Filter As`,
        primaryBtnText: 'Save',
        secondaryBtnText: 'Cancel',
        primaryAction: (name) => {
          if (name != activeSiteFilter.name) {
            let newSiteFilter: ISiteFilter = {
              ...activeSiteFilter,
              ...formatFilters(filterValues),
              id: null,
              name: name,
            }
            saveFilter(newSiteFilter)
            setOpen(false)
          } else {
            saveFilter()
            setOpen(false)
          }
        },
        secondaryAction: () => {
          setOpen(false)
        },
      })
    }
    // Saves a filter as a new filter
    else {
      setDialogProps({
        id: 'saveAs',
        dialogTitle: `Save Filter As`,
        primaryBtnText: 'Save',
        secondaryBtnText: 'Cancel',
        primaryAction: (name) => {
          let newSiteFilter: ISiteFilter = { ...activeSiteFilter, ...formatFilters(filterValues), id: null, name: name }
          saveFilter(newSiteFilter)
          setOpen(false)
        },
        secondaryAction: () => {
          setOpen(false)
        },
      })
    }
  }

  /**
   * Saves the active site filter
   * @param siteFilter optional siteFilter for save as functionality
   */
  const saveFilter = (siteFilter?: ISiteFilter) => {
    // Save as filter
    if (siteFilter) {
      try {
        POST('sites/site-filter', siteFilter).then((res) => {
          let newSiteFilters = [...siteFilters, res]

          newSiteFilters = newSiteFilters.filter((filter) => filter.id != 'showAll')

          newSiteFilters.sort((filterA, filterB) => {
            if (filterA.name.toLowerCase() < filterB.name.toLowerCase()) {
              return -1
            } else if (filterA.name.toLowerCase() > filterB.name.toLowerCase()) {
              return 1
            }
            return 0
          })
          newSiteFilters.unshift(defaultSiteFilter)
          setSiteFilters(newSiteFilters)
          setActiveSiteFilter(res)
          setShowSave(false)
        })
      } catch (err) {
        console.log(err)
        throw err
      }
    } else {
      // Save existing filter
      try {
        PUT('sites/site-filter', activeSiteFilter.id, { ...activeSiteFilter, ...formatFilters(filterValues) }).then(
          (res) => {
            let newSiteFilters = siteFilters.map((filter) => {
              if (filter.id == activeSiteFilter.id) {
                return res
              } else {
                return filter
              }
            })
            setActiveSiteFilter(res)
            setFilterValues(extractFilterValues(res))
            setActiveFilterValues(extractFilterValues(res))
            setSiteFilters(newSiteFilters)
            setShowSave(false)
          }
        )
      } catch (err) {
        console.log(err)
        throw err
      }
    }
  }

  /**
   * Deletes the active site filter
   */
  const deleteFilter = (siteFilterId: string) => {
    let siteFilter = siteFilters.filter((filter) => filter.id == siteFilterId)[0]

    setOpen(true)
    setDialogProps({
      id: 'delete',
      dialogTitle: `Delete Filter ${siteFilter.name}?`,
      primaryBtnText: 'Delete',
      secondaryBtnText: 'Cancel',
      primaryAction: () => {
        setPrimaryActionDisabled(true)
        let id = siteFilter.id
        try {
          DELETE(`sites/site-filter`, id).then((res) => {
            setActiveSiteFilter(defaultSiteFilter)
            setSiteFilters(siteFilters.filter((filter) => filter.id != id))
            setShowSave(false)
            setPrimaryActionDisabled(false)
            setOpen(false)
          })
        } catch (err) {
          console.log(err)
          throw err
        }
      },
      secondaryAction: () => {
        setOpen(false)
      },
    })
  }

  /**
   * Resets the filter to the active site filter values
   */
  const resetFilter = () => {
    setTableDataLoading(true)
    let newFilterValues = extractFilterValues(activeSiteFilter)
    let sortField =
      activeSiteFilter.sortKey == 'branchRegion'
        ? 'branch.regionName'
        : activeSiteFilter.sortKey == 'siteName'
        ? 'name'
        : activeSiteFilter.sortKey == 'branch.id'
        ? 'branch'
        : activeSiteFilter.sortKey
    let sortOrder = activeSiteFilter.sortOrder
    setSortState({ sortField, sortOrder })
    updateFilterChips(newFilterValues)
    setActiveFilterValues(newFilterValues)
    setFilterValues(newFilterValues)
    getFilteredData(newFilterValues).then(() => {
      applyColumnStyles(activeSiteFilter.columns, activeSiteFilter.columnSizes)
      setTableDataLoading(false)
      setShowSave(false)
    })
  }

  /**
   * Creates excel data based on sites table result
   * @returns excel workbook
   */
  const getExcelData = async () => {
    // new filter with a max size to retrieve all sites
    let filterVals = { ...activeFilterValues, size: 1000 }
    let headers = activeFilterValues.columns
    let sites = new Array()
    let workbook = new ExcelJS.Workbook()
    workbook.creator = 'WEDGE'

    let res = await POST(`sites/site-filter/${activeSiteFilter.id}`, { ...formatFilters(filterVals) })
    sites = res.sites

    let worksheet = workbook.addWorksheet(`Sites`)
    worksheet.properties.defaultColWidth = 20

    // add headers
    let hRow = worksheet.addRow(headers)
    hRow.alignment = { horizontal: 'center' }

    // map the headers to the fields that are in the response,
    let fields = headers.map((header) => headerMap[header])
    let rows = new Array()
    for (const site of sites) {
      let row = new Array()
      for (const field of fields) {
        let val = site[field]
        if (field == 'branch.id') {
          val = site['branch']
          val = val ? val['id'] : ''
        } else if (field == 'branch.regionName') {
          val = site['branch']
          val = val ? val['regionName'] : ''
        }
        row.push(val == undefined ? '' : val)
      }
      worksheet.addRow(row)
      rows.push(row)
    }
    return workbook
  }

  /**
   * Exports sites table data into an excel file
   * @param fileName
   */
  const exportData = async (fileName: string) => {
    let data = await getExcelData()
    data = await data.xlsx.writeBuffer()
    const blob = new Blob([data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
    })

    const downloadLink = document.createElement('a')
    downloadLink.href = URL.createObjectURL(blob)
    let date = moment(new Date()).format('YYYY_MM_DD')
    // downloadLink.download = `Site_List_${date}.xlsx`
    downloadLink.download = `${fileName}.xlsx`

    // Append the link to the body and click it to trigger the download
    document.body.appendChild(downloadLink)
    downloadLink.click()

    // Clean up by removing the link
    document.body.removeChild(downloadLink)
  }

  /**
   * Handles export btn click
   */
  const exportSites = () => {
    setOpen(true)
    setDialogProps({
      id: 'export',
      dialogTitle: `Export Sites As`,
      primaryBtnText: 'Export',
      secondaryBtnText: 'Cancel',
      primaryAction: (name) => {
        exportData(name).then((res) => {
          setOpen(false)
          setFileName('')
        })
      },
      secondaryAction: () => {
        setFileName('')
        setError(false)
        setErrorMsg('')
        setOpen(false)
      },
    })
  }

  /* Table component functions */

  /**
   * Handles sorting the table
   * @param column column to sort by
   * @param order order to sort by
   */
  const handleSortTable = (column: string, order: string | null) => {
    let sortKey = column
    let sortOrder = order
    if (column == 'name') {
      sortKey = 'siteName'
    } else if (column == 'branch.regionName') {
      sortKey = 'branchRegion'
    } else if (column == 'branch.id') {
      sortKey = 'branch'
    }

    if (sortKey != filterValues.sortKey || sortOrder != filterValues.sortOrder) {
      let newFilters = { ...filterValues }
      if (sortOrder === null || sortKey == '') {
        newFilters = { ...newFilters, sortKey: 'created', sortOrder: 'desc' }
        setFilterValues(newFilters)
      } else {
        newFilters = { ...newFilters, sortKey: sortKey, sortOrder: sortOrder }
        setFilterValues(newFilters)
      }

      setTableDataLoading(true)

      getFilteredData(newFilters).then(() => {
        setTableDataLoading(false)
        setShowSave(true)
      })
    }
  }

  /* Column functions */

  /**
   * Changes a column from visible to non-visible and vice-versa
   * @param e change event
   * @param column column to be updated
   */
  const updateColumnVisbility = (e: ChangeEvent<HTMLInputElement>, column: ColDef): void => {
    let newColumns: Array<ColDef> = columns.map((col) => {
      if (col.field == column.field) {
        return {
          ...col,
          hide: !e.currentTarget.checked,
        }
      } else {
        return col
      }
    })
    setColumns(newColumns)

    let newCols = new Array()
    let colSizes = new Array()

    for (const col of newColumns) {
      if (!col.hide) {
        newCols.push(col.headerName)
        colSizes.push(col.width ? col.width : defaultWidth)
      }
    }

    let newFilters = { ...filterValues, columns: newCols, columnSizes: colSizes }
    setFilterValues(newFilters)
    setActiveFilterValues(newFilters)
    setShowSave(true)
  }

  /**
   * Handles column move event
   * @param params column moved event
   * @returns void
   */
  const onColumnMoved = (params: ColumnMovedEvent) => {
    const colName = params.column?.getColDef().headerName || ''
    if (!colName || !params.finished) {
      return
    }
    const toIndex = params.toIndex as number
    const fromIndex = activeFilterValues.columns.indexOf(colName)
    const newColumns = shiftElement(activeFilterValues.columns, fromIndex, toIndex)
    const newColumnSizes = shiftElement(activeFilterValues.columnSizes, fromIndex, toIndex)

    if (columnsMatch(newColumns, newColumnSizes)) {
      let newFilters = { ...filterValues, columns: newColumns, columnSizes: newColumnSizes }
      setFilterValues(newFilters)
      setActiveFilterValues(newFilters)
      setShowSave(true)
    }
  }

  /**
   * Handles column resize event
   * @param params column resized event params
   * @returns void
   */
  const onColumnResize = (params: ColumnResizedEvent) => {
    if (!params.finished) {
      return
    }

    const colName = params.column?.getColDef().headerName as string
    const newWidth = params.column?.getActualWidth() as number
    const indexToChange = activeFilterValues.columns.findIndex((item: string) => item === colName)
    let resizedColumns: number[] = [...activeFilterValues.columnSizes]

    if (indexToChange > -1) {
      resizedColumns = [
        ...activeFilterValues.columnSizes.slice(0, indexToChange),
        newWidth,
        ...activeFilterValues.columnSizes.slice(indexToChange + 1),
      ]
    }

    if (columnsMatch(activeFilterValues.columns, resizedColumns)) {
      let newFilters = { ...filterValues, columnSizes: resizedColumns }
      setFilterValues(newFilters)
      setActiveFilterValues(newFilters)
      setShowSave(true)
    }
  }

  /**
   * Applies column styles and hidden state
   * @param siteColumns
   * @param columnSizes
   */
  const applyColumnStyles = (siteColumns: Array<string>, columnSizes: Array<number>) => {
    if (columns.length > 0) {
      // Update visible/hidden columns
      let filterColumns = columns.map((col) => {
        if (siteColumns.includes(col.headerName)) {
          col.hide = false
        } else {
          col.hide = true
        }
        return col
      })

      let index = 0
      // Order the columns
      let orderedColumns = new Array()
      for (const col of siteColumns) {
        let colIndex = filterColumns.findIndex((column) => column.headerName == col)
        if (colIndex > -1) {
          let column = filterColumns[colIndex]
          column.width = columnSizes[index]
          index += 1
          orderedColumns.push(column)
          filterColumns.splice(colIndex, 1)
        }
      }
      if (filterColumns.length > 0) {
        orderedColumns = [...orderedColumns, ...filterColumns]
      }
      setColumns(orderedColumns)
    }
  }

  /**
   * Compares columns to the active columns to determine btn action states
   * @param columns
   * @param columnSizes
   * @returns true or false
   */
  const columnsMatch = (columns, columnSizes) => {
    if (columns.length > activeFilterValues.columns.length) {
      return true
    } else {
      for (let i = 0; i < columns.length; i++) {
        if (columns[i] != activeFilterValues.columns[i] || columnSizes[i] != activeFilterValues.columnSizes[i])
          return true
      }
    }
    return false
  }

  /* Table pagination functions */

  /**
   * Handles changing the page of the table
   * @param event change event
   * @param newPage new page number
   */
  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number): void => {
    setPagination({ ...pagination, page: newPage })
    setTableDataLoading(true)
    let start = newPage * activeFilterValues.size
    let newFilterValues = { ...filterValues, from: start }

    setFilterValues(newFilterValues)
    setActiveFilterValues(newFilterValues)

    POST(`sites/site-filter/${activeSiteFilter.id}`, { ...formatFilters(newFilterValues, false, true) }).then((res) => {
      setRows(res.sites)
      setTableDataLoading(false)
    })
  }

  /**
   * Handles user changing rows per page
   * @param event change event
   */
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    let size = parseInt(event.target.value, 10)
    setPagination({ ...pagination, rowsPerPage: size, page: 0 })
    setTableDataLoading(true)

    let newFilterValues = { ...filterValues, size: size, from: 0 }
    setFilterValues(newFilterValues)
    setActiveFilterValues(newFilterValues)

    POST(`sites/site-filter/${activeSiteFilter.id}`, { ...formatFilters(newFilterValues, false, true) }).then((res) => {
      setRows(res.sites)
      setTableDataLoading(false)
    })

    setShowSave(true)
  }

  /* Data filter functions */

  /**
   * Configure the filters into filter objects
   * @param options api response object with filter fields and values
   */
  const configFilters = (options: Object): void => {
    let filters: Array<IFilter> = []
    for (const [key, value] of Object.entries(options)) {
      let filter: IFilter = {
        field: key,
        headerName: formatHeader(key),
        values: value,
      }
      filters.push(filter)
    }
    filters.push({ field: 'siteName', headerName: 'Site Name', value: '' })
    filters.push({ field: 'primarySupport', headerName: 'Support Users', value: '' })
    filters.push({ field: 'states', headerName: 'Province/State', values: geography })
    setFilterableColumns(filters)
  }

  /**
   * Handles selecting one of the filterable columns to filter on
   * @param filter
   */
  const handleSelectFilter = (filter: IFilter): void => {
    setCurrentFilter(filter)
    if ('values' in filter) setIsFilterEnum(true)
    else setIsFilterEnum(false)
    setColumnFilterOpen(false)
    setFilterOptionsOpen(true)
  }

  /**
   * Resets the filter values when a filter is closed
   */
  const onCloseFilterOptions = () => {
    setFilterValues(activeFilterValues)
  }

  /**
   * Gets the active filter values for a filter
   * @param filter filter object
   * @returns an array of the filter values
   */
  const getCurrentFilterValues = (filter: IFilter) => {
    if (filter.field in filterValues) {
      return filterValues[filter.field]
    } else {
      return []
    }
  }

  /**
   * Handles filter selection through autocomplete component
   * @param values array of selected values from dropdown
   */
  const handleSelect = (values: Array<any> | string) => {
    const filterField = currentFilter?.field!
    let newFilters: ISiteFilterOptions = { ...filterValues }
    newFilters = { ...filterValues, from: 0, [filterField]: values }
    if (filterField == 'siteName' && typeof values == 'string') {
      newFilters['siteName'] = values
    }
    setFilterValues(newFilters)
  }

  /**
   * Removes all active filter values
   */
  const removeAllFilters = () => {
    setTableDataLoading(true)
    setFilterValues(defaultFilters)
    updateFilterChips(defaultFilters)
    setShowSave(true)
    getFilteredData(defaultFilters).then(() => {
      setTableDataLoading(false)
    })
  }

  /**
   * Checks if there are active filters to enable/disable clearable state
   * @param currentFilters
   */
  const checkActiveFilters = (currentFilters: ISiteFilterOptions): void => {
    let filters = currentFilters ? currentFilters : filterValues

    for (const [key, val] of Object.entries(filters)) {
      // disregard these fields, boolean fields, and number fields
      if (['columns', 'columnSizes', 'sortKey', 'sortOrder'].includes(key)) {
        continue
      }
      if (typeof val == 'boolean' || typeof val == 'number' || typeof val == 'undefined') {
        continue
      } else {
        if (val.length && val.length > 0) {
          setFiltered(true)
          return
        }
      }
    }

    setFiltered(false)
  }

  /**
   * Applies active filter values to the table on site filter change
   * @param siteFilter active site filter
   */
  const applyActiveFilterValues = (siteFilter: ISiteFilter) => {
    let filterValues = extractFilterValues(siteFilter)
    setActiveFilterValues(filterValues)
    setFilterValues(filterValues)
    updateFilterChips(filterValues)
    checkActiveFilters(filterValues)
  }

  /* Filter chip functions */
  const updateFilterChips = (filters: ISiteFilterOptions) => {
    let arrFilterFields = [
      'activeInactive',
      'archivedUnarchived',
      'branchRegions',
      'branches',
      'companies',
      'countries',
      'internalExternal',
      'primarySupport',
      'projectSubTypes',
      'projectTypes',
      'siteName',
      'states',
      'platform',
      'gateways',
      'equipment',
    ]

    let newFilterChips = [...filterChips]

    for (const key of Object.keys(filters)) {
      if (arrFilterFields.includes(key)) {
        let index = newFilterChips.findIndex((chips) => chips.field == key)
        // the filter exists in the list
        if (index > -1) {
          if (filters[key] && filters[key].length > 0) {
            if (key == 'states') {
              newFilterChips[index] = { field: key, values: filters[key].map((val) => val.name) }
            } else {
              newFilterChips[index] = { field: key, values: filters[key] }
            }
          } else {
            newFilterChips.splice(index, 1)
          }
        } else {
          // the filter is not in the list
          if (filters[key] && filters[key].length > 0) {
            if (key == 'states') {
              newFilterChips.push({ field: key, values: filters[key].map((val) => val.name) })
            } else {
              newFilterChips.push({ field: key, values: filters[key] })
            }
          }
        }
      }
    }

    setFilterChips(newFilterChips)
  }

  /**
   * Get filter chip label based on filter field and values
   * @param filterField
   * @param values selected filter values
   * @returns label string
   */
  const getChipLabel = (filterField: string, values: string | Array<any>): string => {
    let chipLabel = getHeadingFromField(filterField) + ':'

    if (Array.isArray(values)) {
      for (let i = 0; i < values.length; i++) {
        if (i == 0) {
          chipLabel = chipLabel + ' ' + values[i]
        } else {
          chipLabel = chipLabel + ', ' + values[i]
        }
      }
    } else {
      chipLabel += ` "${values}"`
    }

    return chipLabel
  }

  /**
   * Deletes a filter chip from the table based on field
   * @param filterField
   */
  const deleteFilterChip = (filterField: string): void => {
    let newFilterValues: ISiteFilterOptions = { ...filterValues, from: 0 }

    if (filterField == 'siteName') {
      newFilterValues[filterField] = ''
    } else {
      newFilterValues[filterField] = []
    }

    setFilterValues(newFilterValues)
    setActiveFilterValues(newFilterValues)
    updateFilterChips(newFilterValues)
    setTableDataLoading(true)
    getFilteredData(newFilterValues).then(() => {
      setShowSave(true)
      setTableDataLoading(false)
    })
  }

  /* Sort format and helper functions */

  /**
   * Formats camel case headers into separated words
   * @param option
   * @returns formatted header
   */
  const formatHeader = (option: string) => {
    let slashHeaders = ['archivedUnarchived', 'internalExternal', 'activeInactive']
    if (slashHeaders.includes(option)) {
      let fOption = capitalize(option)
      let res = ''
      for (let i = 0; i < fOption.length; i++) {
        let char = fOption.charAt(i)

        if (i == 0) {
          res += char
        } else {
          if (char == char.toUpperCase()) {
            res += '/'
            res += char
          } else {
            res += char
          }
        }
      }
      return res
    } else {
      let fOption = capitalize(option)
      let res = ''
      for (let i = 0; i < fOption.length; i++) {
        let char = fOption.charAt(i)

        if (i == 0) {
          res += char
        } else {
          if (char == char.toUpperCase()) {
            res += ' '
            res += char
          } else {
            res += char
          }
        }
      }
      return res
    }
  }

  /**
   * Format counts for statistics
   * @param counts object of counts
   * @returns object with formatted counts
   */
  const formatCounts = (counts: any) => {
    let fCounts = {} as Counts
    for (const key of Object.keys(counts)) {
      switch (key) {
        case 'GWs':
          fCounts['cagCount'] = counts[key]
          break
        case 'SHs':
          fCounts['hmcCount'] = counts[key]
          break
        case 'WSNs':
          fCounts['wsnCount'] = counts[key]
          break
        case 'LWGWs':
          fCounts['lwGwCount'] = counts[key]
          break
        case 'LWWSNs':
          fCounts['lwWsnCount'] = counts[key]
          break
        case 'M7s':
          fCounts['m7Count'] = counts[key]
          break
        case 'LWM7s':
          fCounts['lwM7Count'] = counts[key]
      }
    }
    return fCounts
  }

  /**
   * Formats filter values
   * @param filterValues
   * @returns formatted filter values
   */
  const formatFilters = (filterValues: ISiteFilterOptions, save = false, from = false) => {
    let newFilterValues = { ...filterValues }
    if (!from) {
      newFilterValues.from = 0
    }
    if (filterValues.branchRegions.length > 0) {
      let branchRegions = new Array()
      if (!save) {
        for (const branch of filterValues.branchRegions) {
          branchRegions.push(regionMap[branch])
        }
      } else {
        for (const branch of filterValues.branchRegions) {
          if (Object.keys(regionMap).includes(branch)) {
            branchRegions.push(branch)
          } else {
            // find branch as value and save key
            let key = Object.keys(regionMap).find((key) => regionMap[key] === branch)
            branchRegions.push(key)
          }
        }
      }
      newFilterValues['branchRegions'] = branchRegions
    }
    if (filterValues.states.length > 0) {
      let states = new Array()
      for (const state of filterValues.states) {
        states.push(state.name)
      }
      newFilterValues['states'] = states
    }
    return newFilterValues
  }

  /**
   * Extract filter values from a site filter
   * @param siteFilter
   * @returns formatted filter values
   */
  const extractFilterValues = (siteFilter: ISiteFilter): ISiteFilterOptions => {
    let formattedFilterValues: ISiteFilterOptions = {
      branchRegions: getRegionsByName(siteFilter.branchRegions),
      branches: siteFilter.branches,
      companies: siteFilter.companies,
      countries: siteFilter.countries,
      from: siteFilter.from,
      primarySupport: siteFilter.primarySupport,
      projectSubTypes: siteFilter.projectSubTypes,
      projectTypes: siteFilter.projectTypes,
      showArchived: siteFilter.showArchived,
      showExternal: siteFilter.showExternal,
      showActive: siteFilter.showActive,
      showInactive: siteFilter.showInactive,
      showInternal: siteFilter.showInternal,
      showUnarchived: siteFilter.showUnarchived,
      siteName: siteFilter.siteName,
      size: siteFilter.size,
      sortKey: siteFilter.sortKey,
      sortOrder: siteFilter.sortOrder,
      states: getStatesByName(siteFilter.states),
      archivedUnarchived: siteFilter.archivedUnarchived,
      internalExternal: siteFilter.internalExternal,
      activeInactive: siteFilter.activeInactive,
      platform: siteFilter.platform,
      gateways: siteFilter.gateways,
      equipment: siteFilter.equipment,
      columns: siteFilter.columns,
      columnSizes: siteFilter.columnSizes,
    }
    return formattedFilterValues
  }

  /**
   * Get state value by name
   * @param stateNames array of state names
   * @returns array of state objects
   */
  const getStatesByName = (stateNames: Array<string>) => {
    let states = []
    for (const name of stateNames) {
      let state = geography.filter((state) => state.name == name)
      states.push(state[0])
    }
    return states
  }

  /**
   * Finds the region value by the name
   * @param regionNames array of region names
   * @returns array of regions
   */
  const getRegionsByName = (regionNames: Array<string>) => {
    let regions = []
    for (const [key, value] of Object.entries(regionMap)) {
      if (regionNames.includes(value)) {
        regions.push(key)
      }
    }
    return regions
  }

  /**
   * Sorts column filters
   * @param filterA
   * @param filterB
   * @returns -1, 0, or 1
   */
  const sortColFilters = (filterA: IFilter, filterB: IFilter) => {
    if (filterA.headerName < filterB.headerName) {
      return -1
    } else if (filterA.headerName > filterB.headerName) {
      return 1
    } else {
      return 0
    }
  }

  /**
   * Maps headings from the field
   * @param filterField
   * @returns heading string
   */
  const getHeadingFromField = (filterField: string): string => {
    let filter = filterableColumns.filter((column) => column.field == filterField)
    if (filterField == 'countries') {
      return 'Country'
    } else if (filterField == 'companies') {
      return 'Company'
    } else if (filter.length > 0) {
      return filter[0].headerName
    } else {
      return ''
    }
  }

  /**
   * Shifts elements in an array
   * @param arr original array
   * @param fromIndex original index
   * @param toIndex desired index
   * @returns modified array
   */
  const shiftElement = (arr: any[], fromIndex: number, toIndex: number): any[] => {
    if (fromIndex < 0 || fromIndex >= arr.length || toIndex < 0 || toIndex >= arr.length || fromIndex === toIndex) {
      return arr
    }

    const updatedArr = [...arr]
    const element = updatedArr[fromIndex]

    if (fromIndex < toIndex) {
      for (let i = fromIndex; i < toIndex; i++) {
        updatedArr[i] = updatedArr[i + 1]
      }
    } else {
      for (let i = fromIndex; i > toIndex; i--) {
        updatedArr[i] = updatedArr[i - 1]
      }
    }

    updatedArr[toIndex] = element
    return updatedArr
  }

  /**
   * Checks user input for invalid filenames
   * @param filename
   * @returns true or false
   */
  function fileNameIsValid(filename) {
    // Check if the filename is empty
    if (!filename.trim()) {
      return false
    }

    // Check if the filename contains only allowed characters
    if (!/^[a-zA-Z0-9_\-\s]+$/.test(filename)) {
      return false
    }

    // Check if the filename exceeds maximum length
    if (filename.length > 255) {
      return false
    }

    // Check for reserved filenames on Windows
    const windowsReservedNames = [
      'con',
      'prn',
      'aux',
      'nul',
      'com1',
      'com2',
      'com3',
      'com4',
      'com5',
      'com6',
      'com7',
      'com8',
      'com9',
      'lpt1',
      'lpt2',
      'lpt3',
      'lpt4',
      'lpt5',
      'lpt6',
      'lpt7',
      'lpt8',
      'lpt9',
    ]
    if (windowsReservedNames.includes(filename.toLowerCase())) {
      return false
    }

    // All checks passed, filename is valid
    return true
  }

  /* Update functions */

  /**
   * Refresh table
   */
  const refreshTable = () => {
    setTableDataLoading(true)
    getFilteredData(filterValues).then(() => setTableDataLoading(false))
  }

  /**
   * Parses site filter into filter values to get filtered rows
   * @param siteFilter
   */
  const applySiteFilter = async (siteFilter: ISiteFilter) => {
    applyColumnStyles(siteFilter.columns, siteFilter.columnSizes)
    applyActiveFilterValues(siteFilter)
    let res = await POST(
      `sites/site-filter/${siteFilter.id}`,
      siteFilter.name == 'Show All' || siteFilter.id == 'showAll' ? {} : { ...siteFilter }
    )
    let fCounts = formatCounts(res.counts)
    setCounts(fCounts)
    setRows(res.sites)
    setPagination({ ...pagination, count: res.total, page: 0 })
  }

  /**
   * Apply filters after they have been selected
   * @param e form submission
   */
  const handleApplyFilters = (e?: FormEvent): void => {
    setTableDataLoading(true)
    if (e) {
      e.preventDefault()
    }
    let newFilters = { ...filterValues }
    updateFilterChips(newFilters)
    setFilterOptionsOpen(false)

    getFilteredData(newFilters).then(() => {
      setActiveFilterValues(newFilters)
      setTableDataLoading(false)
      setShowSave(true)
    })
  }

  /**
   * Get filtered data for the table
   * @param filterValues filter values
   */
  const getFilteredData = async (filterValues: ISiteFilterOptions) => {
    applyColumnStyles(activeFilterValues.columns, activeFilterValues.columnSizes)
    checkActiveFilters(filterValues)
    let res = await POST(`sites/site-filter/${activeSiteFilter.id}`, { ...formatFilters(filterValues, false, true) })
    let fCounts = formatCounts(res.counts)
    setCounts(fCounts)
    setRows(res.sites)
    setPagination({ ...pagination, count: res.total, page: 0 })
  }

  /**
   * Use effect to update on first load
   */
  useEffect(() => {
    const getSiteFilters = async () => {
      try {
        let response = await GET('sites/site-filters')
        return response
      } catch (err) {
        console.log(err)
        throw err
      }
    }

    getSiteFilters().then((res: ISiteFilter[]) => {
      res.sort((filterA, filterB) => {
        if (filterA.name.toLowerCase() < filterB.name.toLowerCase()) {
          return -1
        } else if (filterA.name.toLowerCase() > filterB.name.toLowerCase()) {
          return 1
        }
        return 0
      })
      res.unshift(defaultSiteFilter)
      setSiteFilters(res)
    })

    const getFilterOptions = async () => {
      try {
        let response = await GET('sites/site-filters/options')
        return response
      } catch (err) {
        console.log(err)
        throw err
      }
    }

    const getSitesData = async () => {
      let res = await POST('sites/site-filter/showAll', {})
      return res
    }

    getSitesData().then((res) => {
      let fCounts = formatCounts(res.counts)
      setCounts(fCounts)
      setRows(res.sites)
      setPagination({ ...pagination, count: res.total, page: 0 })
      setSortState({ sortField: 'created', sortOrder: 'desc' })
      applyColumnStyles(activeSiteFilter.columns, activeSiteFilter.columnSizes)
      getFilterOptions().then((options) => {
        configFilters(options)
        updateFilterChips(activeFilterValues)
        setTableDataLoading(false)
        setFirstRender(false)
      })
    })
  }, [])

  /**
   * Use effect to update states on active filter change
   */
  useEffect(() => {
    // apply new site filter whenever active site filter changes
    if (!firstRender) {
      setTableDataLoading(true)
      if (activeSiteFilter.sortKey && activeSiteFilter.sortOrder) {
        let sortField = activeSiteFilter.sortKey
        let sortOrder = activeSiteFilter.sortOrder

        if (sortField == 'siteName') {
          sortField = 'name'
        } else if (sortField == 'branchRegion') {
          sortField = 'branch.regionName'
        } else if (sortField == 'branch') {
          sortField = 'branch.id'
        }

        setSortState({ sortField, sortOrder })
      }

      if (activeSiteFilter.name != 'Sites') {
        setSiteFilterName(activeSiteFilter.name)
      } else {
        setSiteFilterName('')
      }

      applySiteFilter(activeSiteFilter).then(() => {
        setTableDataLoading(false)
      })
    }
  }, [activeSiteFilter])

  return (
    <Root>
      {/* SITE FILTER SELECT AND ACTIONS */}
      <Stack direction={'row'} justifyContent={'space-between'} mt={0} mb={0}>
        <Stack direction={'row'} spacing={2}>
          <FormControl>
            {siteFilters.length > 1 ? (
              <Select
                id="filter-name-select"
                value={activeSiteFilter.id}
                variant="outlined"
                color={themeColor}
                renderValue={(selected) => (
                  <Typography sx={{ fontSize: '2.125rem', lineHeight: '1.235' }}>
                    {siteFilters.filter((filter) => filter.id == selected)[0].name}
                  </Typography>
                )}
                onChange={(event) => {
                  event.stopPropagation()
                  setShowSave(false)
                  let filterId = event.target.value
                  let siteFilter = siteFilters.filter((filter) => filter.id == filterId)[0]
                  setActiveSiteFilter(siteFilter)
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    border: 'none',
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    border: 'none',
                  },
                  '&:focus': {
                    backgroundColor: 'transparent',
                  },
                  overflow: 'hidden',
                }}
              >
                {siteFilters.map((filter) => {
                  return (
                    <MenuItem
                      value={filter.id}
                      key={filter.id}
                      sx={{ justifyContent: 'space-between' }}
                      onClick={(e) => {
                        if (filter.id == activeSiteFilter.id) {
                          resetFilter()
                        }
                      }}
                    >
                      <>{filter.name}</>
                      {filter.id != 'showAll' && (
                        <IconButton
                          onClick={(e) => {
                            e.stopPropagation()
                            deleteFilter(filter.id)
                          }}
                          size="small"
                          sx={{ marginLeft: '50px' }}
                        >
                          <Close />
                        </IconButton>
                      )}
                    </MenuItem>
                  )
                })}
              </Select>
            ) : (
              <Typography sx={{ fontSize: '2.125rem', lineHeight: '1.235', padding: '1rem' }}>Sites</Typography>
            )}
          </FormControl>
        </Stack>
        <div style={{ padding: '1rem 0rem 1rem' }}>
          {/* Add hidden logic */}
          {showSave && (
            <Tooltip
              title="Save"
              componentsProps={{
                popper: {
                  modifiers: [
                    {
                      name: 'offset',
                      options: {
                        offset: [0, -12],
                      },
                    },
                  ],
                },
              }}
            >
              <IconButton aria-label="Save" value="save" size="large" onClick={saveAsFilter}>
                <Save />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip
            title="Export"
            componentsProps={{
              popper: {
                modifiers: [
                  {
                    name: 'offset',
                    options: {
                      offset: [0, -12],
                    },
                  },
                ],
              },
            }}
          >
            <IconButton
              aria-label="Export"
              value="export"
              size="large"
              onClick={() => {
                exportSites()
              }}
            >
              <FileDownload />
            </IconButton>
          </Tooltip>
          <Tooltip
            title="Refresh"
            componentsProps={{
              popper: {
                modifiers: [
                  {
                    name: 'offset',
                    options: {
                      offset: [0, -12],
                    },
                  },
                ],
              },
            }}
          >
            <IconButton value="refresh" size="large" onClick={refreshTable}>
              <Refresh />
            </IconButton>
          </Tooltip>
          {/* Toggle Columns */}
          <Tooltip
            title="Toggle Columns"
            componentsProps={{
              popper: {
                modifiers: [
                  {
                    name: 'offset',
                    options: {
                      offset: [0, -12],
                    },
                  },
                ],
              },
            }}
          >
            <IconButton
              aria-label="More"
              aria-haspopup={true}
              value="more"
              size="large"
              onClick={(e) => {
                setColumnOptionsOpen(true)
                setAnchorColEl(e.currentTarget)
              }}
            >
              <MoreVert />
              <Popper
                placement="bottom-end"
                open={columnOptionsOpen}
                anchorEl={anchorColEl}
                style={{ zIndex: 9999 }}
                transition
                disablePortal
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{ transformOrigin: placement === 'bottom' ? 'left' : 'left', zIndex: 9999, width: '100%' }}
                  >
                    <Paper
                      style={{
                        maxHeight: '250px',
                        overflow: 'hidden',
                        display: 'flex',
                        alignItems: 'center',
                        minWidth: '200px',
                        width: '100%',
                      }}
                    >
                      <ClickAwayListener onClickAway={() => setColumnOptionsOpen(false)}>
                        <FormControl
                          style={{
                            minWidth: '175px',
                            width: '100%',
                            maxHeight: '250px',
                            overflowY: 'scroll',
                            boxSizing: 'content-box',
                            padding: '0px 0px 0px 15px',
                          }}
                          className="scroll-list"
                        >
                          {columns.map((column) => (
                            <FormControlLabel
                              key={column.headerName!}
                              label={column.headerName!}
                              control={
                                <Checkbox
                                  color={themeColor}
                                  checked={!column.hide}
                                  onChange={(e) => {
                                    updateColumnVisbility(e, column)
                                  }}
                                />
                              }
                            ></FormControlLabel>
                          ))}
                        </FormControl>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </IconButton>
          </Tooltip>
        </div>
      </Stack>
      <Divider />
      {/* FILTER COMPONENT */}
      <div style={{ display: 'flex' }}>
        <IconButton
          value="filter"
          onClick={() => {
            anchorEl?.click()
          }}
          size="large"
          // disabled={activeSiteFilter.id == 'showAll' && activeSiteFilter.name == 'Show All'}
        >
          <FilterList />
        </IconButton>
        {/* FILTER CHIPS */}
        <div style={{ margin: 'auto' }}>
          {filterChips.map((filter) => {
            return (
              <Chip
                key={filter.field}
                className={theme.palette.mode == 'dark' ? 'chip-dark' : 'chip'}
                sx={{ marginLeft: '5px', maxWidth: '350px' }}
                label={getChipLabel(filter.field, filter.values)}
                variant="outlined"
                onDelete={() => deleteFilterChip(filter.field)}
                clickable
                // disabled={activeSiteFilter.id == 'showAll' && activeSiteFilter.name == 'Show All'}
                onClick={(e) => {
                  let selectedFilter = filterableColumns?.filter((col) => col.field == filter.field)
                  setCurrentFilter(selectedFilter![0])
                  setAnchorEl(e.currentTarget)
                  handleSelectFilter(selectedFilter![0])
                }}
              />
            )
          })}
        </div>
        {/* TEXT FIELD COMPONENT */}
        <InputBase
          id="input-base"
          value={''}
          onChange={(e) => {}}
          style={{ flex: 1, marginLeft: 6 }}
          placeholder={`Filter sites`}
          onClick={(e) => {
            setColumnFilterOpen(true)
            setAnchorEl(e.currentTarget)
          }}
          inputRef={(node) => setAnchorEl(node)}
        />
        {/* INITIAL POPPER WITH FILTERABLE COLUMN HEADERS */}
        {filterableColumns?.sort(sortColFilters) && (
          <Popper
            placement="bottom-start"
            open={columnFilterOpen}
            anchorEl={anchorEl}
            transition
            disablePortal
            style={{ zIndex: 9999 }}
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{ transformOrigin: placement === 'bottom' ? 'left' : 'left', zIndex: 9999, width: '100%' }}
              >
                <Paper
                  style={{
                    maxHeight: '250px',
                    overflow: 'hidden',
                    display: 'flex',
                    alignItems: 'center',
                    width: '100%',
                  }}
                >
                  <ClickAwayListener onClickAway={() => setColumnFilterOpen(false)}>
                    <MenuList
                      sx={{
                        width: '100%',
                        maxHeight: '250px',
                        overflowY: 'scroll',
                        boxSizing: 'content-box',
                      }}
                      className="scroll-list"
                    >
                      {filterableColumns.map((filter: IFilter) => {
                        return (
                          <MenuItem key={filter.field} onClick={() => handleSelectFilter(filter)}>
                            {filter.headerName}
                          </MenuItem>
                        )
                      })}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        )}
        {/* SECOND POPPER WITH SELECT OPTIONS FOR THE FILTERABLE COLUMN HEADER */}
        {filterableColumns && (
          <Popper
            placement="bottom-start"
            open={filterOptionsOpen}
            anchorEl={anchorEl}
            transition
            disablePortal
            style={{ zIndex: 999 }}
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{ transformOrigin: placement === 'bottom' ? 'left' : 'left', zIndex: 9999, width: '100%' }}
              >
                <Paper style={{ minHeight: '200px', minWidth: '275px', maxWidth: '500px' }}>
                  <ClickAwayListener
                    onClickAway={() => {
                      setFilterOptionsOpen(false)
                      onCloseFilterOptions()
                    }}
                    mouseEvent="onMouseUp"
                  >
                    <div>
                      <DialogTitle id="filter-title" sx={{ padding: '16px' }}>
                        <Box display="flex" alignItems="center" padding={0}>
                          <Box flexGrow={1}>{currentFilter?.headerName}</Box>
                          <Box>
                            <IconButton
                              onClick={() => {
                                setFilterOptionsOpen(false)
                                onCloseFilterOptions()
                              }}
                              size="large"
                            >
                              <Close htmlColor={themeColor} />
                            </IconButton>
                          </Box>
                        </Box>
                      </DialogTitle>
                      <Divider />
                      <DialogContent style={{ margin: 0, padding: '10px' }}>
                        <Box component="form" onSubmit={handleApplyFilters}>
                          {/* SELECT DIALOG FOR FILTER BY ENUM COLUMNS */}
                          {isFilterEnum && (
                            <FormControl style={{ width: '100%', marginTop: '10px' }}>
                              {currentFilter?.field != 'states' ? (
                                <Autocomplete
                                  multiple
                                  disableCloseOnSelect
                                  disableListWrap
                                  autoComplete
                                  options={currentFilter ? currentFilter?.values!.sort() : []}
                                  value={currentFilter ? getCurrentFilterValues(currentFilter) : []}
                                  PopperComponent={customPopper}
                                  onChange={(event, value: Array<any>) => {
                                    handleSelect(value)
                                  }}
                                  renderTags={(value, getTagProps) => {
                                    return value.map((option, index) => (
                                      <Chip label={option} {...getTagProps({ index })} />
                                    ))
                                  }}
                                  renderOption={(props, option, { selected }) => (
                                    <li {...props}>
                                      <Checkbox color={themeColor} checked={selected} />
                                      {option}
                                    </li>
                                  )}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label={currentFilter?.headerName}
                                      variant="outlined"
                                      color={themeColor}
                                    />
                                  )}
                                />
                              ) : (
                                <Autocomplete
                                  multiple
                                  disableCloseOnSelect
                                  disableListWrap
                                  autoComplete
                                  options={currentFilter ? currentFilter.values : []}
                                  value={currentFilter ? getCurrentFilterValues(currentFilter) : []}
                                  PopperComponent={customPopper}
                                  onChange={(event, value: Array<any>) => {
                                    handleSelect(value)
                                  }}
                                  renderTags={(value, getTagProps) => {
                                    return value.map((option, index) => (
                                      <Chip label={option.name} {...getTagProps({ index })} />
                                    ))
                                  }}
                                  groupBy={(option) => option.country}
                                  getOptionLabel={(option) => option.name}
                                  renderOption={(props, option, { selected }) => (
                                    <li {...props}>
                                      <Checkbox color={themeColor} checked={selected} />
                                      {option.name}
                                    </li>
                                  )}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label={currentFilter?.headerName}
                                      variant="outlined"
                                      color={themeColor}
                                    />
                                  )}
                                />
                              )}
                            </FormControl>
                          )}
                          {/* TEXT INPUT FOR FILTER BY VALUE COLUMNS */}
                          {!isFilterEnum && currentFilter && currentFilter.field != 'siteName' && (
                            <FormControl style={{ width: '100%', marginTop: '10px' }}>
                              <Autocomplete
                                options={[]}
                                value={currentFilter ? getCurrentFilterValues(currentFilter) : []}
                                multiple
                                freeSolo
                                onChange={(event, value: Array<any>) => {
                                  handleSelect(value)
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="outlined"
                                    label={currentFilter?.headerName}
                                    color={themeColor}
                                  />
                                )}
                              ></Autocomplete>
                            </FormControl>
                          )}
                          {/* Site name filter */}
                          {!isFilterEnum && currentFilter && currentFilter.field == 'siteName' && (
                            <FormControl style={{ width: '100%', marginTop: '10px' }}>
                              <TextField
                                autoFocus={true}
                                variant="outlined"
                                color={theme.palette.mode == 'dark' ? 'primary' : 'secondary'}
                                label={currentFilter?.headerName}
                                style={{ width: '100%' }}
                                value={currentFilter?.field! in filterValues ? filterValues[currentFilter?.field!] : ''}
                                onChange={(e) => handleSelect(e.target.value)}
                                autoComplete="off"
                              ></TextField>
                            </FormControl>
                          )}
                          <div style={{ display: 'flex', justifyContent: 'right', margin: '10px' }}>
                            <Button variant="text" color={themeColor} type="submit">
                              Apply
                            </Button>
                          </div>
                        </Box>
                      </DialogContent>
                    </div>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        )}
        <IconButton disabled={!filtered} size="large" onClick={removeAllFilters}>
          <Close />
        </IconButton>
      </div>
      {/* DIALOG COMPONENT */}
      <Dialog
        id="filter-action-dialog"
        open={open}
        onClose={() => {
          setError(false)
          setErrorMsg('')
          setSiteFilterName('')
          setFileName('')
        }}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle>{dialogProps.dialogTitle}</DialogTitle>
        <DialogContent>
          <Box
            component="form"
            onSubmit={(e) => {
              e.preventDefault()
              if (
                (dialogProps.id == 'saveAs' && !siteFilterName) ||
                (dialogProps.id == 'export' && !fileName) ||
                primaryActionDisabled
              ) {
                return
              } else {
                if (dialogProps.id == 'saveAs') {
                  dialogProps.primaryAction(siteFilterName)
                } else if (dialogProps.id == 'export') {
                  dialogProps.primaryAction(fileName)
                } else {
                  dialogProps.primaryAction(siteFilterName)
                }
              }
            }}
          >
            {dialogProps.id == 'saveAs' && (
              <TextField
                fullWidth
                margin="dense"
                id="save-as-field"
                label="Name"
                variant="outlined"
                error={error}
                helperText={errorMsg}
                color={themeColor}
                value={siteFilterName}
                onChange={(e) => {
                  setSiteFilterName(e.target.value)
                  let siteFilterName = e.target.value.toLowerCase()
                  if (
                    siteFilterName == 'sites' ||
                    (activeSiteFilter.name.toLowerCase() != siteFilterName &&
                      siteFilters.map((filter) => filter.name.toLowerCase()).includes(siteFilterName))
                  ) {
                    setPrimaryActionDisabled(true)
                    setError(true)
                    setErrorMsg(`Duplicate site filter name: ${e.target.value}`)
                  } else {
                    setPrimaryActionDisabled(false)
                    setError(false)
                    setErrorMsg('')
                  }
                }}
                autoComplete="off"
              ></TextField>
            )}
            {dialogProps.id == 'export' && (
              <TextField
                fullWidth
                margin="dense"
                id="file-name-field"
                label="File Name"
                variant="outlined"
                color={themeColor}
                value={fileName}
                onChange={(e) => {
                  setFileName(e.target.value)
                  let fileName = e.target.value
                  if (fileName != '') {
                    if (!fileNameIsValid(fileName)) {
                      setPrimaryActionDisabled(true)
                      setError(true)
                      setErrorMsg(`Invalid filename`)
                    } else {
                      setPrimaryActionDisabled(false)
                      setError(false)
                      setErrorMsg('')
                    }
                  } else {
                    setPrimaryActionDisabled(true)
                  }
                }}
                autoComplete="off"
              ></TextField>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={dialogProps.secondaryAction} color={themeColor}>
            {dialogProps.secondaryBtnText}
          </Button>
          <Button
            onClick={() => {
              if (dialogProps.id == 'saveAs') {
                dialogProps.primaryAction(siteFilterName)
              } else if (dialogProps.id == 'export') {
                dialogProps.primaryAction(fileName)
              } else {
                dialogProps.primaryAction(siteFilterName)
              }
            }}
            disabled={
              (dialogProps.id == 'saveAs' && !siteFilterName) ||
              (dialogProps.id == 'export' && !fileName) ||
              primaryActionDisabled
            }
            color={dialogProps.id == 'delete' ? 'error' : themeColor}
          >
            {dialogProps.primaryBtnText}
          </Button>
        </DialogActions>
      </Dialog>
      <Divider />
      <StatisticList counts={counts} bgColor={theme.palette.mode == 'dark' ? '#222628' : '#fff'} />
      <Paper elevation={1}>
        <Table
          columns={columns}
          rows={rows}
          loading={tableDataLoading}
          refresh={refresh}
          multiSelect={false}
          handleSort={handleSortTable}
          handleSelectRow={() => {}}
          fixedColSize={true}
          onColumnMoved={onColumnMoved}
          onColumnResize={onColumnResize}
          sortState={sortState}
        />
        <TablePagination
          style={{ width: '100%' }}
          {...pagination}
          component="div"
          page={activeFilterValues.from / activeFilterValues.size}
          rowsPerPage={activeFilterValues.size}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          showFirstButton
          showLastButton
        />
      </Paper>
    </Root>
  )
}

export default SitesTable
