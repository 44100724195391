/**
 * Checks if a value is between a given target within a given threshold
 * @param {number} value number to verify
 * @param {number} target target number
 * @param {number} threshold threshold
 * @returns boolean if value is between target within threshold
 */
export function isBetween(value: any, target: any, threshold: any) {
  return value >= target - threshold && value <= target + threshold
}

/**
 *
 * @param {string} a FW version to verify
 * @param {string} b FW version to verify with
 * @returns true if a >= b, false if b > a, undefined if error
 */
export function compareFWVersion(a: any, b: any) {
  try {
    const y = b.split('.').map((i: any) => parseInt(i))
    const x = a.split('.').map((i: any) => parseInt(i))
    if (x.length < 3 || y.length < 3) {
      throw new Error('invalid FW')
    }
    for (let i = 0; i < 3; i++) {
      if (y[i] > x[i]) {
        return false
      }
    }
    return true
  } catch (error) {
    return undefined
  }
}

/**
 * Helper function to capitalize words
 * @param string word
 * @returns capitalized word
 */
export const capitalize = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const prepend = (value: any, array: Array<any>) => {
  var newArray = array.slice()
  newArray.unshift(value)
  return newArray
}
