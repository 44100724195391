import React, { useEffect, useState } from 'react'
import ReactEchartsCore from 'echarts-for-react/lib/core'
import echarts from 'echarts/lib/echarts'

import 'echarts/lib/chart/map'
import 'echarts/lib/component/visualMap'
import geoJson from '../../../assets/geojson/usa-can.json'
import { useTheme } from '@mui/material'
import { IMapProps } from '../utils'

echarts.registerMap('USA-CAN', geoJson, {})

const Map = (props: IMapProps) => {
  const { name, data, loading } = props
  const theme = useTheme()

  const [mapData, setMapData] = useState(new Array())
  const [max, setMax] = useState(0)

  useEffect(() => {
    let provincesAndStates: any = {
      Alberta: 0,
      'British Columbia': 0,
      Manitoba: 0,
      'New Brunswick': 0,
      'Newfoundland and Labrador': 0,
      'Nova Scotia': 0,
      Ontario: 0,
      'Prince Edward Island': 0,
      Quebec: 0,
      Saskatchewan: 0,
      'Northwest Territories': 0,
      Nunavut: 0,
      Yukon: 0,
      Alabama: 0,
      Alaska: 0,
      Arizona: 0,
      Arkansas: 0,
      California: 0,
      Colorado: 0,
      Connecticut: 0,
      'District of Columbia': 0,
      Delaware: 0,
      Florida: 0,
      Georgia: 0,
      Hawaii: 0,
      Idaho: 0,
      Illinois: 0,
      Indiana: 0,
      Iowa: 0,
      Kansas: 0,
      Kentucky: 0,
      Louisiana: 0,
      Maine: 0,
      Maryland: 0,
      Massachusetts: 0,
      Michigan: 0,
      Minnesota: 0,
      Mississippi: 0,
      Missouri: 0,
      Montana: 0,
      Nebraska: 0,
      Nevada: 0,
      'New Hampshire': 0,
      'New Jersey': 0,
      'New Mexico': 0,
      'New York': 0,
      'North Carolina': 0,
      'North Dakota': 0,
      Ohio: 0,
      Oklahoma: 0,
      Oregon: 0,
      Pennsylvania: 0,
      'Rhode Island': 0,
      'South Carolina': 0,
      'South Dakota': 0,
      Tennessee: 0,
      Texas: 0,
      Utah: 0,
      Vermont: 0,
      Virginia: 0,
      Washington: 0,
      'West Virginia': 0,
      Wisconsin: 0,
      Wyoming: 0,
    }

    if (data) {
      let map = new Array()
      let highest = 1

      for (let i = 0; i < data.length; i++) {
        let st = Object.keys(data[i])[0]
        st = Object.keys(data[i])[0]
        let val = data[i][st]

        if (val > highest && st != 'Unknown') {
          highest = val
        }

        if (val > 0) {
          provincesAndStates[st] = val
        }
      }
      for (const state in provincesAndStates) {
        map.push({ name: state, value: provincesAndStates[state] })
      }
      setMax(highest)
      setMapData(map)
    }
  }, [data])

  return (
    <ReactEchartsCore
      echarts={echarts}
      style={{ height: '100%', width: '100%' }}
      showLoading={loading}
      loadingOption={{
        maskColor: theme.palette.mode == 'dark' ? 'rgb(0,0,0,0.8)' : 'rgb(255,255,255,0.8)',
        textColor: theme.palette.mode == 'dark' ? 'white' : 'black',
      }}
      notMerge={true}
      option={{
        grid: { top: 20, bottom: 10, left: 0, right: 0, height: '90%' },
        tooltip: {
          show: true,
          trigger: 'item',
          triggerOn: 'mousemove',
        },
        series: [
          {
            id: name,
            name: name,
            type: 'map',
            roam: true,
            map: 'USA-CAN',
            animationDurationUpdate: 1000,
            label: {
              show: true,
              formatter: '{c}',
              color: theme.palette.mode == 'dark' ? 'white' : 'black',
            },
            itemStyle: {
              borderColor: theme.palette.mode == 'dark' ? 'white' : 'black',
            },
            emphasis: {
              label: {
                color: theme.palette.mode == 'dark' ? 'white' : 'black',
              },
              itemStyle: {
                color: 'inherit',
              },
              areaStyle: {
                color: 'rgb(0, 80, 170)',
              },
            },
            data: mapData,
          },
        ],
        visualMap: [
          {
            left: 'right',
            min: 0,
            max: max,
            inRange: {
              color: [
                '#277DA1',
                '#577590',
                '#4D908E',
                '#43AA8B',
                '#90BE6D',
                '#F9C74F',
                '#F9844A',
                '#F8961E',
                '#F3722C',
                '#F94144',
              ],
            },
            text: ['High', 'Low'],
            calculable: true,
            textStyle: {
              color: theme.palette.mode == 'dark' ? 'white' : 'black',
            },
          },
        ],
      }}
    />
  )
}

export default Map
