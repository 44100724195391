export const geography = [
  {
    name: 'Alberta',
    country: 'Canada',
    id: 'AB',
  },
  {
    name: 'British Columbia',
    country: 'Canada',
    id: 'BC',
  },
  {
    name: 'Manitoba',
    country: 'Canada',
    id: 'MB',
  },
  {
    name: 'New Brunswick',
    country: 'Canada',
    id: 'NB',
  },
  {
    name: 'Newfoundland and Labrador',
    country: 'Canada',
    id: 'NL',
  },
  {
    name: 'Northwest Territories',
    country: 'Canada',
    id: 'NT',
  },
  {
    name: 'Nova Scotia',
    country: 'Canada',
    id: 'NS',
  },
  {
    name: 'Nunavut',
    country: 'Canada',
    id: 'NU',
  },
  {
    name: 'Ontario',
    country: 'Canada',
    id: 'ON',
  },
  {
    name: 'Prince Edward Island',
    country: 'Canada',
    id: 'PE',
  },
  {
    name: 'Quebec',
    country: 'Canada',
    id: 'QC',
  },
  {
    name: 'Saskatchewan',
    country: 'Canada',
    id: 'SK',
  },
  {
    name: 'Yukon',
    country: 'Canada',
    id: 'YT',
  },
  {
    name: 'Alabama',
    country: 'United States',
    id: 'AL',
  },
  {
    name: 'Alaska',
    country: 'United States',
    id: 'AK',
  },
  {
    name: 'Arizona',
    country: 'United States',
    id: 'AZ',
  },
  {
    name: 'Arkansas',
    country: 'United States',
    id: 'AR',
  },
  {
    name: 'California',
    country: 'United States',
    id: 'CA',
  },
  {
    name: 'Colorado',
    country: 'United States',
    id: 'CO',
  },
  {
    name: 'Connecticut',
    country: 'United States',
    id: 'CT',
  },
  {
    name: 'Delaware',
    country: 'United States',
    id: 'DE',
  },
  {
    name: 'Florida',
    country: 'United States',
    id: 'FL',
  },
  {
    name: 'Georgia',
    country: 'United States',
    id: 'GA',
  },
  {
    name: 'Hawaii',
    country: 'United States',
    id: 'HI',
  },
  {
    name: 'Idaho',
    country: 'United States',
    id: 'ID',
  },
  {
    name: 'Illinois',
    country: 'United States',
    id: 'IL',
  },
  {
    name: 'Indiana',
    country: 'United States',
    id: 'IN',
  },
  {
    name: 'Iowa',
    country: 'United States',
    id: 'IA',
  },
  {
    name: 'Kansas',
    country: 'United States',
    id: 'KS',
  },
  {
    name: 'Kentucky',
    country: 'United States',
    id: 'KY',
  },
  {
    name: 'Louisiana',
    country: 'United States',
    id: 'LA',
  },
  {
    name: 'Maine',
    country: 'United States',
    id: 'ME',
  },
  {
    name: 'Maryland',
    country: 'United States',
    id: 'MD',
  },
  {
    name: 'Massachusetts',
    country: 'United States',
    id: 'MA',
  },
  {
    name: 'Michigan',
    country: 'United States',
    id: 'MI',
  },
  {
    name: 'Minnesota',
    country: 'United States',
    id: 'MN',
  },
  {
    name: 'Mississippi',
    country: 'United States',
    id: 'MS',
  },
  {
    name: 'Missouri',
    country: 'United States',
    id: 'MO',
  },
  {
    name: 'Montana',
    country: 'United States',
    id: 'MT',
  },
  {
    name: 'Nebraska',
    country: 'United States',
    id: 'NE',
  },
  {
    name: 'Nevada',
    country: 'United States',
    id: 'NV',
  },
  {
    name: 'New Hampshire',
    country: 'United States',
    id: 'NH',
  },
  {
    name: 'New Jersey',
    country: 'United States',
    id: 'NJ',
  },
  {
    name: 'New Mexico',
    country: 'United States',
    id: 'NM',
  },
  {
    name: 'New York',
    country: 'United States',
    id: 'NY',
  },
  {
    name: 'North Carolina',
    country: 'United States',
    id: 'NC',
  },
  {
    name: 'North Dakota',
    country: 'United States',
    id: 'ND',
  },
  {
    name: 'Ohio',
    country: 'United States',
    id: 'OH',
  },
  {
    name: 'Oklahoma',
    country: 'United States',
    id: 'OH',
  },
  {
    name: 'Oregon',
    country: 'United States',
    id: 'OR',
  },
  {
    name: 'Pennsylvania',
    country: 'United States',
    id: 'PA',
  },
  {
    name: 'Rhode Island',
    country: 'United States',
    id: 'RI',
  },
  {
    name: 'South Carolina',
    country: 'United States',
    id: 'SC',
  },
  {
    name: 'South Dakota',
    country: 'United States',
    id: 'SD',
  },
  {
    name: 'Tennessee',
    country: 'United States',
    id: 'TN',
  },
  {
    name: 'Texas',
    country: 'United States',
    id: 'TX',
  },
  {
    name: 'Utah',
    country: 'United States',
    id: 'UT',
  },
  {
    name: 'Vermont',
    country: 'United States',
    id: 'VT',
  },
  {
    name: 'Virginia',
    country: 'United States',
    id: 'VA',
  },
  {
    name: 'Washington',
    country: 'United States',
    id: 'WA',
  },
  {
    name: 'West Virginia',
    country: 'United States',
    id: 'WV',
  },
  {
    name: 'Wisconsin',
    country: 'United States',
    id: 'WI',
  },
  {
    name: 'Wyoming',
    country: 'United States',
    id: 'WY',
  },
]
