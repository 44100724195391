import styled from '@emotion/styled'
import React, { useState, useEffect } from 'react'
import { Grid } from '@mui/material'
import Widget from '../../../components/Widget/Widget'
import { setDrawerMenuItem } from '../../../actions'
import store from '../../../store'

const PREFIX = 'dev'
const classes = {
  divBg: `${PREFIX}-divBg`,
}

const Root = styled('div')(() => ({
  [`&.${classes.divBg}`]: {
    padding: 10,
    backgroundColor: 'transparent',
  },
}))

let exceptionsWidgetParams = {
  timeRange: 1,
}

let processingWidgetParams = {
  timeRange: 1,
}

let durationWidgetParams = {
  timeRange: 14,
}

const Index = () => {
  useEffect(() => {
    store.dispatch(setDrawerMenuItem('dev'))
  }, [])

  return (
    <Root className={classes.divBg}>
      <Grid container rowSpacing={2} columnSpacing={2}>
        <Widget
          id="duration"
          size="sm"
          variant="graph"
          heading="Average Duration"
          options="logging/metrics/config/options"
          endpoint="logging/metrics/config/search"
          params={durationWidgetParams}
          subheading="Average duration of PUT/POST requests within the sites-config stack counted each day for the last 14 days."
          showMetric={false}
          frequency="daily"
          showTimeRange={false}
        />
        <Widget
          id="non-processing"
          size="sm"
          variant="graph"
          heading="Non-Processing Sites"
          options="data/metrics/processing/options"
          endpoint="data/metrics/processing/search"
          params={processingWidgetParams}
          subheading="Number of sites that are not processing counted hourly for the last day."
          showMetric={false}
          frequency="hourly"
          showTimeRange={true}
        />
        <Widget
          id="exceptions"
          size="sm"
          variant="graph"
          heading="Exceptions"
          options="logging/metrics/exceptions/options"
          endpoint="logging/metrics/exceptions/search"
          params={exceptionsWidgetParams}
          subheading="Number of 400/500 status logs counted hourly for the last day."
          showMetric={false}
          frequency="hourly"
          showTimeRange={true}
        />
      </Grid>
    </Root>
  )
}

export default Index
