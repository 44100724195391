import React, { useEffect } from 'react'
import { styled } from '@mui/material/styles'
import store from '../../../store/index'
import { saveCagInventory, setDrawerMenuItem } from '../../../actions/index'
import WedgeTable from '../../../components/Table/WedgeTable'
import { gatewayColumns } from '../../../components/Table/constants/TableData'
import { connect } from 'react-redux'

const PREFIX = 'cag'

const classes = {
  root: `${PREFIX}-root`,
  table: `${PREFIX}-table`,
  tableWrapper: `${PREFIX}-tableWrapper`,
  tableWrapperNon: `${PREFIX}-tableWrapperNon`,
  divBg: `${PREFIX}-divBg`,
  loading: `${PREFIX}-loading`,
}

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.root}`]: {
    width: '100%',
    marginTop: theme.spacing(3),
  },

  [`& .${classes.table}`]: {
    minWidth: 1020,
  },

  [`& .${classes.tableWrapper}`]: {
    overflowX: 'auto',
    display: 'block',
  },

  [`& .${classes.tableWrapperNon}`]: {
    display: 'none',
  },

  [`&.${classes.divBg}`]: {
    padding: 25,
  },

  [`& .${classes.loading}`]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom: 35,
  },
}))

const mapStateToProps = (state: { cagInventory: any }) => {
  return {
    cagInventory: state.cagInventory,
  }
}

const Index = (props: any) => {
  const { cagInventory } = props

  const tProps = {
    tHeader: 'Gateway Inventory',
    tName: 'gateways',
    tColumns: gatewayColumns,
    optionsEndpoint: 'devices/gateways/options',
    searchEndpoint: 'devices/gateways/search',
    handleSelectRow: () => {},
    defaultData: cagInventory,
    saveAction: saveCagInventory,
  }

  useEffect(() => {
    store.dispatch(setDrawerMenuItem('gateways'))
  }, [])

  return (
    <Root className={classes.divBg}>
      <WedgeTable {...tProps} />
    </Root>
  )
}

export default connect(mapStateToProps)(Index)
