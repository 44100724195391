import React, { useEffect, useState } from 'react'
import { Counts } from '../utils/types/counts'
import styled from '@emotion/styled'
import { TextMain } from './Text/TextMain'
import { HeadingMain } from './Text/HeadingMain'
import { Divider } from '@mui/material'

interface StatisticListProps {
  counts: Counts
  bgColor: string
}

export const StatisticList = (props: StatisticListProps) => {
  const { counts, bgColor } = props

  const [orderedCounts, setOrderedCounts] = useState({ ...counts })

  const formatLabel = (label: string): string => {
    switch (label as keyof Counts) {
      case 'hmcCount':
        return 'SH (Digi)'
      case 'wsnCount':
        return 'WS (Digi)'
      case 'cagCount':
        return 'GW (Digi)'
      case 'lwGwCount':
        return 'GW (LoRa)'
      case 'lwWsnCount':
        return 'WS (LoRa)'
      case 'm7Count':
        return 'EQ (Digi)'
      case 'lwM7Count':
        return 'EQ (LoRa)'
      default:
        return label
    }
  }

  useEffect(() => {
    let orderedCounts = {
      cagCount: counts.cagCount,
      wsnCount: counts.wsnCount,
      hmcCount: counts.hmcCount,
      m7Count: counts.m7Count,
      lwGwCount: counts.lwGwCount,
      lwWsnCount: counts.lwWsnCount,
      lwM7Count: counts.lwM7Count,
    }
    setOrderedCounts(orderedCounts)
  }, [counts])

  return (
    <Container bgColor={bgColor}>
      {Object.keys(orderedCounts)
        .filter((key) => key.slice(0, 2) != 'lw')
        .map((key: string) => {
          return (
            <ItemContainer key={key}>
              <HeadingMain title={formatLabel(key)} />
              <TextMain>{String(orderedCounts[key as keyof Counts])}</TextMain>
            </ItemContainer>
          )
        })}
      <div></div>
      {Object.keys(orderedCounts)
        .filter((key) => key.slice(0, 2) == 'lw')
        .map((key: string) => {
          return (
            <ItemContainer key={key}>
              <HeadingMain title={formatLabel(key)} />
              <TextMain>{String(orderedCounts[key as keyof Counts])}</TextMain>
            </ItemContainer>
          )
        })}
    </Container>
  )
}

interface ContainerProps {
  bgColor: string
}

const Container = styled.div<ContainerProps>`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(130px, 1fr));
  gap: 1rem;
  border: ${(props) => (props.bgColor === '#fff' ? '1px solid #ccc' : '1px solid #68686e')};
  border-bottom: ${(props) => (props.bgColor === '#fff' ? '#ccc' : '#68686e')};
  background-color: ${(props) => props.bgColor};
`

const ItemContainer = styled.div`
  padding: 0.5rem 1rem;
  text-align: center;
`
