import React, { useEffect, useState } from 'react'
import WedgeTable from '../../../components/Table/WedgeTable'
import { IWedgeTableProps } from '../../../components/Table/constants/TableInterfaces'
import { gatewayDetailColumns, logsColumns, gatewayRecordsColumns } from '../../../components/Table/constants/TableData'
import { styled } from '@mui/material/styles'
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  useTheme,
} from '@mui/material'
import store from '../../../store/index'
import {
  newNotificationMessage,
  openNotification,
  publishToTopic,
  setDrawerMenuItem,
  subscribeToTopic,
} from '../../../actions/index'
import WedgeTableWithData from '../../../components/Table/WedgeTableWithData'
import { POST_ENDPOINT } from '../../../api-request'
import { SSH_COMMAND } from '../../../constants/end-points'
import { connect } from 'react-redux'
import { IState } from '../../../reducers'
import config from '../../../config'
import Auth from '@aws-amplify/auth'
import SplitButton from '../../../components/SplitButton'
import { TramRounded } from '@mui/icons-material'

const PREFIX = 'gateway-details'

const classes = {
  root: `${PREFIX}-root`,
  wrapper: `${PREFIX}-wrapper`,
  buttonProgress: `${PREFIX}-buttonProgress`,
  paper: `${PREFIX}-paper`,
}

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
  },

  [`& .${classes.wrapper}`]: {
    margin: theme.spacing(1),
    position: 'relative',
  },

  // [`& .${classes.buttonProgress}`]: {
  //   position: 'absolute',
  //   top: '50%',
  //   left: '50%',
  //   marginTop: -12,
  //   marginLeft: -12,
  // },

  [`& .${classes.paper}`]: {
    maxWidth: 600,
    marginLeft: theme.spacing(2),
    padding: theme.spacing(2),
  },
}))

const mapDispatchToProps = (dispatch: any) => {
  return {
    openNotification: (flag: any) => dispatch(openNotification(flag)),
    newNotificationMessage: (message: any) => dispatch(newNotificationMessage(message)),
    publishToTopic: (message: any) => dispatch(publishToTopic(message)),
    subscribeToTopic: (message: any) => dispatch(subscribeToTopic(message)),
    setDrawerMenuItem: (message: any) => dispatch(setDrawerMenuItem(message)),
  }
}

const mapStateToProps = (state: IState) => {
  return {
    cagData: state.cagData,
  }
}

interface IDialog {
  action: string
  heading: string
  handler: () => void
  details: string | JSX.Element
  submitText: string
  warning: boolean
}

const Details = (props: any) => {
  const { publishToTopic, subscribeToTopic, setDrawerMenuItem, cagData } = props
  const theme = useTheme()

  const [barcode, setBarcode] = useState('') // stores the barcode of the selected gateway
  const [topicPub, setTopicPub] = useState('')
  const [topicSub, setTopicSub] = useState('')
  const [dialog, setDialog] = useState<IDialog>()
  const [dialogOpen, setDialogOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [statusText, setStatusText] = useState('')
  const [disabled, setDisabled] = useState(false)

  const [refresh, setRefresh] = useState(false)

  /**
   * Handles clicking an action button
   * @param action name of action
   */
  const handleClick = (action: string) => {
    switch (action) {
      case 'Reboot':
        setStatusText('')
        setDialog(cagActions.REBOOT)
        break
      case 'Reverse SSH':
        setStatusText('')
        setDialog(cagActions.REVERSE_SSH)
        break
      case 'Restart Reverse SSH':
        setStatusText('')
        setDialog(cagActions.RESTART)
        break
      case 'Get Logs':
        setStatusText('')
        setDialog(cagActions.GET_LOGS)
        break
      default:
        console.log('default')
    }
  }

  const buttonProps = {
    options: ['Get Logs', 'Reboot', 'Restart Reverse SSH', 'Reverse SSH'],
    handleButtonClick: handleClick,
    setModalOpen: setDialogOpen,
    btnDisabled: disabled,
  }

  /**
   * Handles rebooting the gateway TODO: MAKE THIS WORK
   */
  const handleReboot = () => {
    setDisabled(true)
    setLoading(true)
    setDialogOpen(false)
    // try {
    //   let response = await POST_ENDPOINT(SSH_COMMAND, {
    //     barcode: barcode,
    //     command: 'sudo reboot',
    //   })

    //   setStatusText(response.code === null ? 'Rebooted' : 'An error occured')
    //   console.log(response)
    //   setDisabled(false)
    //   setLoading(false)
    // } catch (error) {
    //   setStatusText('An error occured')
    //   setLoading(false)
    // }
    try {
      publishToTopic({
        publishTopic: topicPub,
        publishText: {
          cag: barcode,
          command: 'REBOOT_CAG',
        },
      })
    } catch (e) {
      console.log(e)
    }
  }

  /**
   * Handles reversing the SSH certs on the gateway
   */
  const handleReverseSSH = () => {
    setDisabled(true)
    setDialogOpen(false)
    setLoading(true)
    try {
      publishToTopic({
        publishTopic: topicPub,
        publishText: {
          cag: barcode,
          command: 'SET_REVERSE_SSH_CERT',
        },
      })
    } catch (e) {
      console.log(e)
    }
  }

  /** Handles restarting the reverse SSH on the gateway */
  const handleRestart = () => {
    setDisabled(true)
    setDialogOpen(false)
    setLoading(true)
    try {
      publishToTopic({
        publishTopic: topicPub,
        publishText: {
          cag: barcode,
          command: 'RESTART_RSSH',
        },
      })
    } catch (e) {
      console.log(e)
    }
  }

  /** Handles getting logs from the gateway */
  const handleGetLogs = () => {
    setDisabled(true)
    setDialogOpen(false)
    setLoading(true)
    try {
      publishToTopic({
        publishTopic: topicPub,
        publishText: {
          cag: barcode,
          command: 'GET_LOG_FILES',
        },
      })
    } catch (e) {
      console.log(e)
    }
  }

  // Contains all the possible dialog options based on actions
  const cagActions = {
    REBOOT: {
      action: 'REBOOT',
      heading: `Reboot Gateway ${barcode}?`,
      handler: handleReboot,
      details: `Rebooting a gateway may result in the loss of data. Are you sure you want to reboot Gateway ${barcode}?`,
      submitText: `Reboot`,
      warning: true,
    },
    REVERSE_SSH: {
      action: 'REVERSE_SSH',
      heading: `Regenerate Reverse SSH Certificates on Gateway ${barcode}?`,
      handler: handleReverseSSH,
      details: (
        <>
          Regenerating reverse SSH certificates may result in the loss of <strong>all</strong> ssh connections to the
          gateway. Are you sure you want to regenerate reverse ssh certificates on Gateway {barcode}?
        </>
      ),
      submitText: `Regenerate Reverse SSH Certs`,
      warning: true,
    },
    RESTART: {
      action: 'RESTART',
      heading: `Restart Reverse SSH on Gateway ${barcode}?`,
      handler: handleRestart,
      details: `Are you sure you want to restart the reverse ssh server through Gateway ${barcode}?`,
      submitText: `Restart Reverse SSH`,
      warning: false,
    },
    GET_LOGS: {
      action: 'GET_LOGS',
      heading: `Get Logs from Gateway ${barcode}?`,
      handler: handleGetLogs,
      details: `Are you sure you want to get logs on device ${barcode}?`,
      submitText: `Get Logs`,
      warning: false,
    },
  }

  // Details to construct inventory history table
  const inventoryHistoryProps: IWedgeTableProps = {
    tHeader: 'Inventory History',
    tName: 'gateways',
    tColumns: gatewayDetailColumns,
    optionsEndpoint: `devices/gateways/${window.location.pathname.split('/')[2]}/options`,
    searchEndpoint: `devices/gateways/${window.location.pathname.split('/')[2]}/search`,
    handleSelectRow: () => {},
  }

  // Details to construct raw cag records table
  const gatewayRecordsProps: IWedgeTableProps = {
    tHeader: 'Raw Cag Records',
    tName: 'records',
    tColumns: gatewayRecordsColumns,
    optionsEndpoint: `devices/gateways/${window.location.pathname.split('/')[2]}/records/options`,
    searchEndpoint: `devices/gateways/${window.location.pathname.split('/')[2]}/records/search`,
    handleSelectRow: () => {},
  }

  const gatewayLogsProps: IWedgeTableProps = {
    tHeader: 'Cag Logs',
    tName: 'logs',
    tColumns: logsColumns,
    optionsEndpoint: `devices/gateways/${window.location.pathname.split('/')[2]}/logs/options`,
    searchEndpoint: `devices/gateways/${window.location.pathname.split('/')[2]}/logs/search`,
    handleSelectRow: () => {},
    forceRefresh: refresh,
    filteringDisabled: true,
  }

  // Use effect to check if the cagData has been updated due to a response from cag mqtt
  useEffect(() => {
    console.log(cagData)
    if ('topic' in cagData) {
      if (cagData.topic.includes(barcode)) {
        let command = cagData.topic.split('/')[-1]
        console.log(`Command: ${command}`)
      } else if (cagData.topic == topicSub) {
        console.log('This is a user rsp')
      }
    }

    // Check to see for a message from the cag indicating the reverse ssh is successful
    if ('payload' in cagData) {
      if ('message' in cagData.payload && cagData.topic == topicSub) {
        setDisabled(false)
        if (cagData.payload.message == 'SET REVERSE SSH SUCCESSFUL') {
          setLoading(false)
          setStatusText(cagData.payload.message)
        } else if (cagData.payload.message == 'GET LOGS SUCCESSFUL') {
          setRefresh(true)
          setLoading(false)
          setStatusText(cagData.payload.message)
        } else if (cagData.payload.message == 'REBOOT SUCCESSFUL') {
          setLoading(false)
          setStatusText(cagData.payload.message)
        } else if (cagData.payload.message == 'RESTART SUCESSFUL') {
          setLoading(false)
          setStatusText(cagData.payload.message)
        } else if (cagData.payload.message == 'IN PROGRESS') {
          setStatusText(cagData.payload.message)
        } else {
          setLoading(false)
          setStatusText(cagData.payload.message)
        }
      }
    }
  }, [cagData])

  useEffect(() => {
    setDrawerMenuItem('gateways')

    let cagBarcode = window.location.pathname.split('/')[2]
    setBarcode(cagBarcode)
    let tPub = ''
    let tSub = ''

    // Retrieve client id for mqtt pub/sub
    const getClientId = async () => {
      let sessionDetails = await Auth.currentCredentials()
      let client_id = sessionDetails.identityId
      return client_id
    }

    getClientId().then((id) => {
      tPub = `${config.setting.STAGE}/${id}/cmd`
      tSub = `${config.setting.STAGE}/cag/${cagBarcode}/rsp` // listen to response from cag here for debug
      subscribeToTopic(tSub)
      setTopicPub(tPub)
      tSub = `${config.setting.STAGE}/${id}/rsp`
      setTopicSub(tSub)
    })

    setTopicPub(tPub)
    setTopicSub(tSub)
  }, [])

  return (
    <Root className={'divBg'}>
      <Box>
        <Typography variant="h4" sx={{ marginBottom: '20px', marginRight: '20px' }} color={'textPrimary'}>
          Gateway Details: <strong>{barcode}</strong>
        </Typography>
        <div style={{ display: 'flex', marginBottom: '20px' }}>
          <SplitButton {...buttonProps} />
          {loading && (
            <div style={{ display: 'flex', marginLeft: '20px' }}>
              <Typography variant="body1" style={{ marginRight: '20px' }}>
                <strong>Status: </strong>
              </Typography>
              <CircularProgress size={28} className={classes.buttonProgress} color="secondary" />
            </div>
          )}
          {statusText && (
            <Typography
              variant="body1"
              color={statusText.includes('ERROR') ? 'red' : 'green'}
              style={{ marginLeft: '20px' }}
            >
              <strong style={theme.palette.mode == 'dark' ? { color: 'white' } : { color: 'black' }}>Status: </strong>
              {statusText}
            </Typography>
          )}
        </div>
        <Dialog
          open={dialogOpen}
          onClose={() => setDialogOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{dialog?.heading}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">{dialog?.details}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={() => setDialogOpen(false)}>
              Cancel
            </Button>
            <Button
              color={dialog?.warning ? 'error' : 'primary'}
              variant="contained"
              onClick={dialog?.handler}
              autoFocus
            >
              {dialog?.submitText}
            </Button>
          </DialogActions>
        </Dialog>
        <WedgeTable {...inventoryHistoryProps} />
      </Box>
      <Box sx={{ marginTop: '20px' }}>
        <WedgeTableWithData {...gatewayRecordsProps} />
      </Box>
      <Box sx={{ marginTop: '20px' }}>
        <WedgeTable {...gatewayLogsProps} />
      </Box>
    </Root>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Details)
