import React from 'react'
import DashboardLink from './PrivateLinks/dashboard'
import InventoryLinks from './PrivateLinks/inventory'
import LoggingLink from './PrivateLinks/logging'
import SyndicationLink from './PrivateLinks/loggingSyndication'
import SiteListLink from './PrivateLinks/siteList'
import TrackunitLink from './PrivateLinks/trackunitApis'
import LwanDeviceRegLink from './PrivateLinks/lwanDeviceReg'

export const publicListItems = <div></div>

export const privateListItems = (childProps: any) => {
  return (
    <div>
      <DashboardLink {...childProps} />
      <InventoryLinks {...childProps} />
      <LoggingLink {...childProps} />
      <SyndicationLink {...childProps} />
      <TrackunitLink {...childProps} />
      <LwanDeviceRegLink {...childProps} />
      <SiteListLink {...childProps} />
    </div>
  )
}
