export const actionTypes = {
  NEW_NOTIFICATION_MESSAGE: 'NEW_NOTIFICATION_MESSAGE',
  OPEN_NOTIFICATION: 'OPEN_NOTIFICATION',
  CLOSE_NOTIFICATION: 'CLOSE_NOTIFICATION',
  CONNECT_TO_MQTT: 'CONNECT_TO_MQTT',
  SUBSCRIBE_TO_DEFAULT_TOPIC: 'SUBSCRIBE_TO_DEFAULT_TOPIC',
  PUBLISH_TO_MQTT: 'PUBLISH_TO_MQTT',
  SUBSCRIBE_TO_TOPIC: 'SUBSCRIBE_TO_TOPIC',
  SAVE_CAG_INVENTORY: 'SAVE_CAG_INVENTORY',
  SAVE_DEVICE_INVENTORY: 'SAVE_DEVICE_INVENTORY',
  SAVE_ASYNC_DEVICE_INVENTORY: 'SAVE_ASYNC_DEVICE_INVENTORY',
  SAVE_SITE_LIST: 'SAVE_SITE_LIST',
  SAVE_ERROR_LIST: 'SAVE_ERROR_LIST',
  SAVE_KPI: 'SAVE_KPI',
  SET_DRAWER_MENU_ITEM: 'SET_DRAWER_MENU_ITEM',
  SUBSCRIBE_TO_RSP_DATA: 'SUBSCRIBE_TO_RSP_DATA',
  SUBSCRIBE_TO_CAG: 'SUBSCRIBE_TO_CAG',
  MQTT_PUBLISH: 'MQTT_PUBLISH',
  MQTT_CONNECT: 'MQTT_CONNECT',
  MQTT_DISCONNECT: 'MQTT_DISCONNECT',
  MQTT_ERROR: 'MQTT_ERROR',
  SNACKBAR_CLOSE: 'SNACKBAR_CLOSE',
}
