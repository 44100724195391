import API from '@aws-amplify/api'
import Auth from '@aws-amplify/auth'
import { createTheme, StyledEngineProvider, ThemeProvider } from '@mui/material/styles'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import App from './App'
// import './assets/css/material-dashboard-react.css?v=1.2.0'
import config from './config'
import './index.css'
import store from './store/index'

let setupConfig = {
  Auth: {
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
    oauth: {
      domain: config.cognito.DOMAIN,
      scope: ['phone', 'email', 'openid', 'profile', 'aws.cognito.signin.user.admin'],
      redirectSignIn: config.cognito.REDIRECT,
      redirectSignOut: config.cognito.REDIRECT,
      responseType: 'code',
    },
  },
  API: {
    endpoints: [
      {
        name: 'console',
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION,
      },
    ],
  },
}

//Amplify.configure(setupConfig);
Auth.configure(setupConfig.Auth)
// Storage.configure(setupConfig.Storage);
API.configure(setupConfig.API)

ReactDOM.render(
  <Router>
    <StyledEngineProvider injectFirst>
      <Provider store={store}>
        <App />
      </Provider>
    </StyledEngineProvider>
  </Router>,
  document.getElementById('root')
)
//registerServiceWorker();
