import React, { useEffect } from 'react'
import { styled } from '@mui/material/styles'
import SitesTable from '../../../components/Table/SitesTable'
import store from '../../../store'
import { setDrawerMenuItem } from '../../../actions'

const PREFIX = 'sites-v2'

const classes = {
  root: `${PREFIX}-root`,
  table: `${PREFIX}-table`,
  tableWrapper: `${PREFIX}-tableWrapper`,
  tableWrapperNon: `${PREFIX}-tableWrapperNon`,
  divBg: `${PREFIX}-divBg`,
  loading: `${PREFIX}-loading`,
}

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.root}`]: {
    width: '100%',
    marginTop: theme.spacing(3),
  },

  [`& .${classes.table}`]: {
    minWidth: 1020,
  },

  [`& .${classes.tableWrapper}`]: {
    overflowX: 'auto',
    display: 'block',
  },

  [`& .${classes.tableWrapperNon}`]: {
    display: 'none',
  },

  [`&.${classes.divBg}`]: {
    padding: 25,
  },

  [`& .${classes.loading}`]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom: 35,
  },
}))

const Index = (props: any) => {
  useEffect(() => {
    store.dispatch(setDrawerMenuItem('sites-v2'))
  })
  return (
    <Root className={classes.divBg}>
      <SitesTable />
    </Root>
  )
}

export default Index
