import React, { ChangeEvent, useState, useEffect, FormEvent } from 'react'
import withStyles from '@mui/styles/withStyles'
import { Theme } from '@mui/material/styles'
import {
  ClickAwayListener,
  Grow,
  Popper,
  MenuItem,
  MenuList,
  DialogContent,
  DialogTitle,
  Divider,
  Chip,
  Paper,
  InputBase,
  Button,
  Typography,
  Checkbox,
  TextField,
  FormControlLabel,
  IconButton,
  FormGroup,
  FormControl,
  InputLabel,
  Select,
  SelectChangeEvent,
  TablePagination,
  OutlinedInput,
  styled,
  Box,
  useTheme,
  Autocomplete,
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import Table from './Table'
import { POST, GET } from '../../api-request'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import FilterListIcon from '@mui/icons-material/FilterList'
import CloseIcon from '@mui/icons-material/CloseOutlined'
import { ColDef } from 'ag-grid-community'
import { Refresh } from '@mui/icons-material'
import { IFilter, ISortState, IWedgeTableProps, MenuProps } from './constants/TableInterfaces'
import './styles.css'
import { LocalizationProvider } from '@mui/x-date-pickers'
import moment, { Moment } from 'moment'
import { prepend } from '../../utils/utils'
import store from '../../store'

const PREFIX = 'WedgeTable'

const classes = {
  root: `${PREFIX}-root`,
  paperTextRoot: `${PREFIX}-paperTextRoot`,
  table: `${PREFIX}-table`,
  tableWrapper: `${PREFIX}-tableWrapper`,
  tableWrapperNon: `${PREFIX}-tableWrapperNon`,
  divBg: `${PREFIX}-divBg`,
  loading: `${PREFIX}-loading`,
  textField: `${PREFIX}-textField`,
}

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.root}`]: {
    width: '100%',
  },

  [`& .${classes.paperTextRoot}`]: {
    padding: theme.spacing(2),
    height: 510,
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
    body: {
      overflow: 'auto',
    },
  },

  [`& .${classes.table}`]: {
    minWidth: 1020,
  },

  [`& .${classes.tableWrapper}`]: {
    overflowX: 'auto',
    display: 'block',
  },

  [`& .${classes.tableWrapperNon}`]: {
    display: 'none',
  },

  [`& .${classes.divBg}`]: {
    padding: 25,
  },

  [`& .${classes.loading}`]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom: 35,
  },

  [`& .${classes.textField}`]: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}))

const customPopper = function (props: any) {
  return <Popper {...props} style={{ width: 'fit-content', minWidth: '250px' }} placement="bottom-start" />
}
/**
 * Custom Wedge table derived from ag-grid with sort and filter capabilities
 * @param tProps table props required for rendering, includes name, columns, and relevant endpoints
 * @returns Wedge table component
 */
const WedgeTable = (tProps: IWedgeTableProps) => {
  const theme = useTheme()
  let {
    tHeader,
    tName,
    tColumns,
    optionsEndpoint,
    searchEndpoint,
    forceRefresh,
    filteringDisabled,
    filters,
    multiSelect,
    refreshDisabled,
    showPagination,
    defaultData,
    saveAction,
  } = tProps
  let { handleSelectRow } = tProps
  const [anchorColEl, setAnchorColEl] = useState<null | HTMLElement>(null)
  const [tableDataLoading, setTableDataLoading] = useState<boolean>(true)

  // Table data states
  const [rows, setRows] = useState()
  const [columns, setColumns] = useState(tColumns)

  // Table pagination states
  const [pagination, setPagination] = useState({
    rowsPerPage: 10,
    page: 0,
    count: 0,
  })
  const paginationEnabled = typeof showPagination != 'undefined' ? showPagination : true

  // Filtering states
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const [isFilterEnum, setIsFilterEnum] = useState<boolean>()
  const [columnFilterOpen, setColumnFilterOpen] = useState(false) // use state for column filter popper
  const [filterOptionsOpen, setFilterOptionsOpen] = useState(false) // use state for filter value popper
  const [columnOptionsOpen, setColumnOptionsOpen] = useState(false) // use state for column toggle popper

  const [filterableColumns, setFilterableColumns] = useState<Array<IFilter>>() // columns and their enumerable values
  const [currentFilter, setCurrentFilter] = useState<IFilter>() // tracks current filter
  const [filterValues, setFilterValues] = useState<any>({}) // tracks all filters applied
  const [filtered, setFiltered] = useState<boolean>(false) // tracks if there are any filtered states
  const [filterChips, setFilterChips] = useState<any>({}) // data for filter chips
  const [refresh, setRefresh] = useState<boolean>(false) // tracks if the table has been refreshed

  // date filtering
  const dateChipFormat = 'MMM D, YYYY'
  const [dateCondition, setDateCondition] = useState<string>('after')
  const [date, setDate] = useState<Moment | null>()

  // Sorting state
  const [sortState, setSortState] = useState<ISortState | {}>({})

  // column fields that require date filter options rather than value or select
  const dateFields = tProps.dateFields
    ? tProps.dateFields
    : [
        'time',
        'timestamp',
        'timeCreated',
        'timeReceived',
        'archived',
        'raised',
        'cleared',
        'gapStart',
        'gapEnd',
        'silencedAt',
        'startTime',
      ]

  /**
   * Processes filters for each column depending on filter type
   * @param allColumns array of all column names
   * @param enumColumns array of column fields that are filterable by enumerated value
   * @param valueColumns array of column fields that are filterable by value
   * @returns
   */
  const processFilters = (allColumns: string[], enumColumns: any, valueColumns: string[]): Array<IFilter> => {
    let filters: Array<IFilter> = []

    for (let i = 0; i < allColumns.length; i++) {
      let columnField = allColumns[i]

      let filter: IFilter = { headerName: '', field: '' }
      let header = getHeadingFromField(columnField) // only get headers that exist in columns

      // Filter by value
      if (valueColumns.includes(columnField)) {
        if (header) {
          filter = {
            headerName: header,
            field: columnField,
            value: '',
          }
          filters.push(filter)
        }
      }
      // Filter by enum
      else {
        // add map for siteId to relate siteName with siteId
        if (columnField == 'siteId') {
          let map = {}
          let siteNames = 'siteName' in enumColumns ? enumColumns['siteName'] : enumColumns['name']
          for (let i = 0; i < enumColumns[columnField].length; i++) {
            map[enumColumns[columnField][i]] = siteNames[i]
          }

          filter = {
            headerName: getHeadingFromField(columnField),
            field: columnField,
            values: enumColumns[columnField],
            map: map,
          }
        } else {
          filter = {
            headerName: getHeadingFromField(columnField),
            field: columnField,
            values: enumColumns[columnField],
          }
        }
        filters.push(filter)
      }
    }
    return filters
  }

  /**
   * Updates the column to be hidden/not hidden
   * @param e Event handling of the selected checkbox
   * @param column The column that was selected
   */
  const updateColumn = (e: ChangeEvent<HTMLInputElement>, column: ColDef): void => {
    let newColumns: Array<ColDef> = columns.map((col) => {
      if (col.field == column.field) {
        return {
          ...col,
          hide: !e.currentTarget.checked,
        }
      } else {
        return col
      }
    })

    setColumns(newColumns)
  }

  /**
   * Handles selecting a filterable column
   * @param filter the selected column filter
   */
  const handleSelectFilter = (filter: IFilter): void => {
    setCurrentFilter(filter)
    if ('values' in filter) setIsFilterEnum(true)
    else setIsFilterEnum(false)
    setColumnFilterOpen(false)
    setFilterOptionsOpen(true)
  }

  /**
   * Helper to get the header based on the field
   * @param filterField
   * @returns header
   */
  const getHeadingFromField = (filterField: string): string => {
    let filter: ColDef[] = columns.filter((column) => column.field == filterField)
    if (filter.length > 0) {
      return filter[0].headerName!
    } else {
      return ''
    }
  }

  /**
   * Helper to get the chip label
   * @param filterField
   * @param values selected values
   * @returns string with the chip label
   */
  const getChipLabel = (filterField: string, values: any): string => {
    let chipLabel = getHeadingFromField(filterField) + ':'
    if (
      filterField === 'siteId' &&
      (tHeader.includes('Async') || tHeader.includes('Gateway') || tHeader.includes('M7'))
    ) {
      values = formatSiteIds(values)
    }

    if (Array.isArray(values)) {
      for (let i = 0; i < values.length; i++) {
        if (i == 0) {
          chipLabel = chipLabel + ' ' + values[i]
        } else {
          chipLabel = chipLabel + ', ' + values[i]
        }
      }
    } else if (dateFields.includes(filterField)) {
      if ('start' in values) {
        chipLabel += ` After ${moment(values.start).local().format(dateChipFormat)}`
      } else if ('stop' in values) {
        chipLabel += ` Before ${moment(values.stop).local().format(dateChipFormat)}`
      } else {
        chipLabel += `After ${moment(values).local().format(dateChipFormat)}`
      }
    } else {
      chipLabel += ` "${values}"`
    }

    return chipLabel
  }

  /**
   * Deletes the filter chip and removes the filter
   * @param filterField
   */
  const deleteFilterChip = (filterField: string): void => {
    let newFilterValues = { ...filterValues }
    delete newFilterValues[filterField]
    if (dateFields.includes(filterField)) {
      setDate(null)
      setDateCondition('after')
    }
    setFilterValues(newFilterValues)
    setFilterChips(newFilterValues)
    getFilteredData(newFilterValues)
    checkActiveFilters(newFilterValues)
  }

  /**
   * Handles change of page of the table
   * @param event back or forward event
   * @param newPage new page number
   */
  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number): void => {
    setPagination({ ...pagination, page: newPage })
    setTableDataLoading(true)

    let start = newPage * pagination.rowsPerPage

    const fetchData = async () => {
      const response = await POST(searchEndpoint, {
        ...filterValues,
        ...sortState,
        from: start,
        size: pagination.rowsPerPage,
      })
      return response
    }

    try {
      fetchData().then((response) => {
        setRows(response.docs)
        setTableDataLoading(false)
      })
    } catch (e) {
      console.log(e)
    }
  }

  /**
   * Handles changing rows per page of the table
   * @param event change event
   */
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    setPagination({ ...pagination, rowsPerPage: parseInt(event.target.value, 10), page: 0 })
    setTableDataLoading(true)

    const fetchData = async () => {
      const response = await POST(searchEndpoint, { ...filterValues, size: parseInt(event.target.value, 10), from: 0 })
      return response
    }

    try {
      fetchData().then((response) => {
        setRows(response.docs)
        setTableDataLoading(false)
      })
    } catch (e) {
      console.log(e)
    }
  }

  /**
   * Refresh table with no filters/sorting data by calling API
   */
  const refreshTable = (): void => {
    setRefresh(true)
    setTableDataLoading(true)
    // setFiltered(false)
    // setFilterChips({})
    // setFilterValues({})
    setSortState({})
    setDate(null)
    setDateCondition('after')
    handleSelectRow!()

    const getColumnData = async () => {
      const response = await GET(optionsEndpoint)
      return response
    }

    const getRowData = async () => {
      const response = await POST(searchEndpoint, { ...filterValues, from: 0, size: pagination.rowsPerPage })
      return response
    }

    try {
      getColumnData().then((response) => {
        try {
          getRowData().then((res) => {
            if (res.docs.length > 0) {
              let enumColumns = response
              configColumnFilters(enumColumns)
            }
            setPagination({ ...pagination, count: res.total, page: 0 })
            setRows(res.docs)
            setTableDataLoading(false)
          })
        } catch (e) {
          console.log(e)
          console.log('Error retreiving row data')
        }
      })
    } catch (e) {
      console.log(e)
      console.log('Error retreiving column data')
    }
  }

  /**
   * Removes all of the active filters
   */
  const removeAllFilters = (): void => {
    let emptyFilterObj: any = {}
    setDate(null)
    setDateCondition('after')
    setFilterValues(emptyFilterObj)
    setFilterChips(emptyFilterObj)
    getFilteredData(emptyFilterObj)
    setFiltered(false)
  }

  /**
   * Handles change in selection within filter dialog
   * @param e Select event
   */
  const handleSelectChange = (e: SelectChangeEvent<string[]>): void => {
    const {
      target: { value },
    } = e

    let filterField = currentFilter?.field!
    let newFilters: any = {}

    if (filterField == 'siteId') {
      // convert siteNames to id if filtering by siteId
      let siteNameToIdMap: any = {}
      for (let i = 0; i < currentFilter!.map!.length; i++) {
        let siteName: string = currentFilter?.map![i]
        siteNameToIdMap[siteName] = currentFilter?.values![i]
      }
      if (value.includes('All')) {
        if (filterField in filterValues) {
          if (filterValues[filterField].length == currentFilter?.values!.length) {
            newFilters = { ...filterValues }
            delete newFilters[filterField]
          } else {
            newFilters = { ...filterValues, [filterField]: currentFilter?.values }
          }
        } else {
          newFilters = { ...filterValues, [filterField]: currentFilter?.values }
        }
      } else {
        newFilters = { ...filterValues, [filterField]: value }
      }
    } else {
      if (value.includes('All')) {
        if (filterField in filterValues) {
          // remove all values when all is deselected
          if (filterValues[filterField].length == currentFilter?.values!.length) {
            newFilters = { ...filterValues }
            delete newFilters[filterField]
          } else {
            newFilters = { ...filterValues, [filterField]: currentFilter?.values }
          }
        } else {
          newFilters = { ...filterValues, [filterField]: currentFilter?.values }
        }
      } else {
        newFilters = { ...filterValues, [filterField]: value }
      }
    }

    setFilterValues(newFilters)
  }

  /**
   * Handles change in selection within filter dialog
   * @param e Select event
   */
  const handleSelect = (value: Array<any>): void => {
    let filterField = currentFilter?.field!
    let newFilters: any = {}

    if (filterField == 'siteId') {
      // convert siteNames to id if filtering by siteId
      let siteNameToIdMap: any = {}
      for (let i = 0; i < currentFilter!.map!.length; i++) {
        let siteName: string = currentFilter?.map![i]
        siteNameToIdMap[siteName] = currentFilter?.values![i]
      }
      if (value.includes('All')) {
        if (filterField in filterValues) {
          if (filterValues[filterField].length == currentFilter?.values!.length) {
            newFilters = { ...filterValues }
            delete newFilters[filterField]
          } else {
            newFilters = { ...filterValues, [filterField]: currentFilter?.values }
          }
        } else {
          newFilters = { ...filterValues, [filterField]: currentFilter?.values }
        }
      } else {
        newFilters = { ...filterValues, [filterField]: value }
      }
    } else {
      if (value.includes('All')) {
        if (filterField in filterValues) {
          // remove all values when all is deselected
          if (filterValues[filterField].length == currentFilter?.values!.length) {
            newFilters = { ...filterValues }
            delete newFilters[filterField]
          } else {
            newFilters = { ...filterValues, [filterField]: currentFilter?.values }
          }
        } else {
          newFilters = { ...filterValues, [filterField]: currentFilter?.values }
        }
      } else {
        newFilters = { ...filterValues, [filterField]: value }
      }
    }
    setFilterValues(newFilters)
  }

  /**
   * Maps site ids to the site name to display name instead of id
   * @param values site ids to be converted
   * @returns array of site names
   */
  const formatSiteIds = (values: Array<any>) => {
    // convert siteIds to name for readability
    let siteIdFilter = filterableColumns!.find((filter: IFilter) => filter.field == 'siteId')

    let siteNames = []
    for (const val of values) {
      let name = siteIdFilter?.map![val]
      siteNames.push(name)
    }

    return siteNames
  }

  /**
   * Handles change in input within filter dialog
   * @param value input value
   */
  const handleInputChange = (value: string): void => {
    let filterField = currentFilter?.field!
    setFilterValues({ ...filterValues, [filterField]: value })
  }

  /**
   * Handles selected "before" or "after" for date select
   * @param event Select event
   */
  const handleDateCondition = (event: SelectChangeEvent): void => {
    let dateConditionVal = event.target.value as string
    setDateCondition(dateConditionVal)
    let filter = {}
    if (date) {
      let filterDate = date.format()
      if (dateConditionVal == 'after') {
        filter = { ...filterValues, [currentFilter?.field!]: { start: filterDate } }
      } else if (dateConditionVal == 'before') {
        filter = { ...filterValues, [currentFilter?.field!]: { stop: filterDate } }
      }
      setFilterValues(filter)
    }
  }

  /**
   * Handles date change from date picker
   * @param value date selected
   */
  const handleDateChange = (value: Moment | null): void => {
    if (value) {
      setDate(moment.utc(value))
      let filterDate = moment.utc(value).format()
      let filter = {}
      if (dateCondition == 'after') {
        filter = { ...filterValues, [currentFilter?.field!]: { start: filterDate } }
      } else if (dateCondition == 'before') {
        filter = { ...filterValues, [currentFilter?.field!]: { stop: filterDate } }
      }
      setFilterValues(filter)
    }
  }

  /**
   * Applies filters to column data
   */
  const handleApplyFilters = (e: FormEvent): void => {
    e.preventDefault()
    let newFilters = { ...filterValues }
    // Remove any filters with null values
    for (let key of Object.keys(newFilters)) {
      // if filter is an object with more keys, check each key
      if (newFilters[key].length === undefined) {
        let empty = true
        for (const prop in newFilters[key]) {
          if (newFilters[key][prop].length > 0) {
            empty = false
          }
        }
        if (empty) {
          delete newFilters[key]
        }
      } else if (newFilters[key].length == 0) {
        delete newFilters[key]
      }
    }
    setFilterValues(newFilters)
    checkActiveFilters()
    setFilterOptionsOpen(false)
    setFilterChips(newFilters)
    getFilteredData(newFilters)
  }

  /**
   * Checks filters to see if any filters are active to set filtered state
   * @param currentFilters optionally pass the current filters
   */
  const checkActiveFilters = (currentFilters?: any): void => {
    let filters = currentFilters ? currentFilters : filterValues
    let filterKeys = Object.keys(filters)

    for (let i = 0; i < filterKeys.length; i++) {
      if (filters[filterKeys[i]].length > 0 || filters[filterKeys[i]].length === undefined) {
        setFiltered(true)
        return
      }
    }

    setFiltered(false)
  }

  /**
   * API call to retrieve row data with applicable filters
   * @param filterSelection optionally pass the current filters
   */
  const getFilteredData = (filterSelection?: any, sortSelection?: any): void => {
    setTableDataLoading(true)
    let filters = filterSelection ? filterSelection : filterValues
    let sort = sortSelection ? sortSelection : sortState
    let params = { ...filters, ...sort, size: pagination.rowsPerPage }

    const filterTable = async () => {
      const response = await POST(searchEndpoint, { ...params, from: 0 })
      return response
    }

    try {
      filterTable().then((response) => {
        setPagination({ ...pagination, count: response.total, page: 0 })
        setRows(response.docs)
        setTableDataLoading(false)
      })
    } catch (e) {
      console.log(e)
    }
  }

  /**
   * Configures columns to find filterable columns and setup filtering
   * @param eColumns enumerable columns
   */
  const configColumnFilters = (eColumns: Object): void => {
    let eColumnFields: string[] = Object.keys(eColumns) // map enum columns to string array of fields
    // remove the non filterable columns and map columns to string array of fields
    let allColumns: string[] = columns.map((col) => {
      if ('filterable' in col) {
        if (col.filterable) {
          return col.field!
        } else {
          return ''
        }
      } else {
        return col.field!
      }
    })
    allColumns.filter((col) => col != '')
    let vColumns = allColumns.filter((col) => !eColumnFields.includes(col)) // filters any column that is not enumerable

    let filters = processFilters(allColumns, eColumns, vColumns)
    setFilterableColumns(filters)
  }

  /**
   * Handles sorting the table through calling the API
   */
  const handleSortTable = (column: string, order: string | null): void => {
    if (order === null) {
      setSortState({})
      getFilteredData(null, {})
    } else {
      let sort = { sortField: column, sortOrder: order }
      setSortState(sort)
      getFilteredData(null, sort)
    }
  }

  /**
   * Helper function to sort the filters by header names
   * @param filterA
   * @param filterB
   * @returns -1, 1, or 0 dependent on order
   */
  const sortColFilters = (filterA: IFilter, filterB: IFilter) => {
    if (filterA.headerName < filterB.headerName) {
      return -1
    } else if (filterA.headerName > filterB.headerName) {
      return 1
    } else {
      return 0
    }
  }

  /**
   * Calls API on load to get the filterable columns and data for the table
   */
  useEffect(() => {
    if (!handleSelectRow) {
      handleSelectRow = (row: any) => {}
    }

    if (defaultData && Object.keys(defaultData).length > 0) {
      setRows(defaultData.rows)
      configColumnFilters(defaultData.options)
      setPagination(defaultData.pagination)
      setTableDataLoading(false)
    }

    const getColumnData = async () => {
      const response = await GET(optionsEndpoint)
      return response
    }

    const getRowData = async () => {
      if (filters) {
        const response = await POST(searchEndpoint, { ...filters, from: 0 })
        return response
      } else {
        const response = await POST(searchEndpoint, { ...filterValues, from: 0 })
        return response
      }
    }

    try {
      getColumnData().then((response) => {
        try {
          getRowData().then((res) => {
            if (res.docs.length > 0) {
              let enumColumns = response
              configColumnFilters(enumColumns)
            }
            setPagination({ ...pagination, count: res.total, page: 0 })
            setRows(res.docs)
            setTableDataLoading(false)
            if (typeof saveAction == 'function') {
              let data: any = {
                rows: res.docs,
                options: response,
                pagination: { ...pagination, count: res.total, page: 0 },
              }
              store.dispatch(saveAction(data))
            }
          })
        } catch (e) {
          console.log(e)
          console.log('Error retreiving row data')
        }
      })
    } catch (e) {
      console.log(e)
      console.log('Error retreiving column data')
    }
  }, [tProps.optionsEndpoint, tProps.searchEndpoint])

  /**
   * Handles applying filters that are handled outside of the WedgeTable component
   */
  useEffect(() => {
    if (typeof filters != 'undefined' && Object.keys(filters).length > 0) {
      getFilteredData(filters)
    }
  }, [filters])

  /**
   * Forces refresh of table if data fetch required (ex: get logs)
   */
  useEffect(() => {
    if (forceRefresh) refreshTable()
  }, [forceRefresh])

  /**
   * Update columns when they change
   */
  useEffect(() => {
    setColumns(tProps.tColumns)
  }, [tProps.tColumns])

  return (
    <Root>
      <div style={{ display: 'flex', height: 'auto' }}>
        <Typography variant="h4" style={{ flex: '1' }}>
          {tHeader}
        </Typography>
        <IconButton
          value="refresh"
          size="large"
          onClick={refreshTable}
          disabled={refreshDisabled ? refreshDisabled : false}
        >
          <Refresh />
        </IconButton>
        {/* Toggle Columns */}
        <IconButton
          aria-label="More"
          aria-haspopup="true"
          value="more"
          size="large"
          onClick={(e) => {
            setColumnOptionsOpen(true)
            setAnchorColEl(e.currentTarget)
          }}
        >
          <MoreVertIcon />
          <Popper
            placement="bottom-end"
            open={columnOptionsOpen}
            anchorEl={anchorColEl}
            style={{ zIndex: 9999 }}
            transition
            disablePortal
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{ transformOrigin: placement === 'bottom' ? 'left' : 'left', zIndex: 9999, width: '100%' }}
              >
                <Paper
                  style={{
                    maxHeight: '250px',
                    overflow: 'hidden',
                    display: 'flex',
                    alignItems: 'center',
                    minWidth: '200px',
                    width: '100%',
                  }}
                >
                  <ClickAwayListener onClickAway={() => setColumnOptionsOpen(false)}>
                    <FormControl
                      style={{
                        minWidth: '175px',
                        width: '100%',
                        maxHeight: '250px',
                        overflowY: 'scroll',
                        boxSizing: 'content-box',
                        padding: '0px 0px 0px 15px',
                      }}
                      className="scroll-list"
                    >
                      {columns.map((column) => (
                        <FormControlLabel
                          key={column.headerName!}
                          label={column.headerName!}
                          control={
                            <Checkbox
                              color={theme.palette.mode == 'dark' ? 'primary' : 'secondary'}
                              checked={!column.hide}
                              onChange={(e) => {
                                updateColumn(e, column)
                              }}
                            />
                          }
                        ></FormControlLabel>
                      ))}
                    </FormControl>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </IconButton>
      </div>
      <Divider />
      {/* FILTER COMPONENT */}
      <div style={{ display: 'flex' }}>
        <IconButton
          value="filter"
          onClick={() => {
            anchorEl?.click()
          }}
          disabled={filteringDisabled}
          size="large"
        >
          <FilterListIcon />
        </IconButton>
        <div style={{ margin: 'auto' }}>
          {Object.keys(filterChips).map((filterField) => {
            return (
              <Chip
                key={filterField}
                className={theme.palette.mode == 'dark' ? 'chip-dark' : 'chip'}
                sx={{ marginLeft: '5px', maxWidth: '350px' }}
                label={getChipLabel(filterField, filterChips[filterField])}
                variant="outlined"
                onDelete={() => deleteFilterChip(filterField)}
                clickable
                onClick={(e) => {
                  let filter = filterableColumns?.filter((col) => col.field == filterField)
                  setCurrentFilter(filter![0])
                  setAnchorEl(e.currentTarget)
                  handleSelectFilter(filter![0])
                }}
              />
            )
          })}
        </div>
        {/* TEXT FIELD COMPONENT */}
        <InputBase
          style={{ flex: 1, marginLeft: 6 }}
          placeholder={`Filter ${tName}`}
          onClick={(e) => {
            if (!filteringDisabled) {
              setColumnFilterOpen(true)
              setAnchorEl(e.currentTarget)
            }
          }}
          inputRef={(node) => setAnchorEl(node)}
          id="input-base"
          value={''}
          onChange={(e) => {}}
          disabled={filteringDisabled}
        />
        {/* INITIAL POPPER WITH FILTERABLE COLUMN HEADERS */}
        {filterableColumns?.sort(sortColFilters) && (
          <Popper
            placement="bottom-start"
            open={columnFilterOpen}
            anchorEl={anchorEl}
            transition
            disablePortal
            style={{ zIndex: 9999 }}
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{ transformOrigin: placement === 'bottom' ? 'left' : 'left', zIndex: 9999, width: '100%' }}
              >
                <Paper
                  style={{
                    maxHeight: '250px',
                    overflow: 'hidden',
                    display: 'flex',
                    alignItems: 'center',
                    width: '100%',
                  }}
                >
                  <ClickAwayListener onClickAway={() => setColumnFilterOpen(false)}>
                    <MenuList
                      sx={{
                        width: '100%',
                        maxHeight: '250px',
                        overflowY: 'scroll',
                        boxSizing: 'content-box',
                      }}
                      className="scroll-list"
                    >
                      {filterableColumns.map((filter: IFilter) => {
                        // if (!nonFilterableFields.includes(filter.field)) {
                        return (
                          <MenuItem
                            key={filter.field}
                            onClick={() => {
                              handleSelectFilter(filter)
                            }}
                          >
                            {filter.headerName}
                          </MenuItem>
                        )
                        // }
                      })}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        )}
        {/* SECOND POPPER WITH SELECT OPTIONS FOR THE FILTERABLE COLUMN HEADER */}
        {filterableColumns && (
          <Popper
            placement="bottom-start"
            open={filterOptionsOpen}
            anchorEl={anchorEl}
            transition
            disablePortal
            style={{ zIndex: 999 }}
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{ transformOrigin: placement === 'bottom' ? 'left' : 'left', zIndex: 9999, width: '100%' }}
              >
                <Paper style={{ minHeight: '200px', minWidth: '275px', maxWidth: '500px' }}>
                  <ClickAwayListener
                    onClickAway={() => {
                      setFilterOptionsOpen(false)
                      setFilterValues(filterChips)
                      setDate(null)
                      setDateCondition('after')
                    }}
                    mouseEvent="onMouseUp"
                  >
                    <div>
                      <DialogTitle id="filter-title" sx={{ padding: '16px' }}>
                        <Box display="flex" alignItems="center" padding={0}>
                          <Box flexGrow={1}>{currentFilter?.headerName}</Box>
                          <Box>
                            <IconButton
                              onClick={() => {
                                setFilterOptionsOpen(false)
                                setFilterValues(filterChips)
                                setDate(null)
                                setDateCondition('after')
                              }}
                              size="large"
                            >
                              <CloseIcon htmlColor={theme.palette.mode == 'dark' ? 'white' : 'black'} />
                            </IconButton>
                          </Box>
                        </Box>
                      </DialogTitle>
                      <Divider />
                      <DialogContent style={{ margin: 0, padding: '10px' }}>
                        <Box component="form" onSubmit={handleApplyFilters}>
                          {/* SELECT DIALOG FOR FILTER BY ENUM COLUMNS */}
                          {isFilterEnum && (
                            <FormControl style={{ width: '100%', marginTop: '10px' }}>
                              <Autocomplete
                                multiple
                                disableCloseOnSelect
                                disableListWrap
                                autoComplete
                                options={currentFilter ? prepend('All', currentFilter?.values!.sort()) : []}
                                value={currentFilter?.field! in filterValues ? filterValues[currentFilter?.field!] : []}
                                PopperComponent={customPopper}
                                onChange={(event, value: Array<any>) => {
                                  handleSelect(value)
                                }}
                                renderTags={(value, getTagProps) => {
                                  if (
                                    currentFilter &&
                                    currentFilter.field == 'siteId' &&
                                    (tHeader.includes('Async') || tHeader.includes('Gateway') || tHeader.includes('M7'))
                                  ) {
                                    return value.map((option, index) => (
                                      <Chip label={currentFilter.map[option]} {...getTagProps({ index })} />
                                    ))
                                  } else {
                                    return value.map((option, index) => (
                                      <Chip label={option} {...getTagProps({ index })} />
                                    ))
                                  }
                                }}
                                renderOption={(props, option, { selected }) =>
                                  currentFilter &&
                                  currentFilter.field == 'siteId' &&
                                  (tHeader.includes('Async') ||
                                    tHeader.includes('Gateway') ||
                                    tHeader.includes('M7')) ? (
                                    <li {...props}>
                                      <Checkbox
                                        color="secondary"
                                        checked={
                                          option == 'All'
                                            ? currentFilter &&
                                              currentFilter.field in filterValues &&
                                              filterValues[currentFilter?.field].length == currentFilter?.values!.length
                                            : selected
                                        }
                                      />
                                      {option == 'All' ? 'All' : currentFilter.map[option]}
                                    </li>
                                  ) : (
                                    <li {...props}>
                                      <Checkbox
                                        color="secondary"
                                        checked={
                                          option == 'All'
                                            ? currentFilter &&
                                              currentFilter.field in filterValues &&
                                              filterValues[currentFilter?.field!].length ==
                                                currentFilter?.values!.length
                                            : selected
                                        }
                                      />
                                      {option}
                                    </li>
                                  )
                                }
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label={currentFilter?.headerName}
                                    variant="outlined"
                                    color={theme.palette.mode == 'dark' ? 'primary' : 'secondary'}
                                  />
                                )}
                              />
                            </FormControl>
                          )}
                          {/* TEXT INPUT FOR FILTER BY VALUE COLUMNS */}
                          {!isFilterEnum && !dateFields.includes(currentFilter?.field!) && (
                            <FormControl style={{ width: '100%', marginTop: '10px' }}>
                              <TextField
                                autoFocus={true}
                                variant="outlined"
                                color={theme.palette.mode == 'dark' ? 'primary' : 'secondary'}
                                label="Value"
                                style={{ width: '100%' }}
                                value={currentFilter?.field! in filterValues ? filterValues[currentFilter?.field!] : ''}
                                onChange={(e) => handleInputChange(e.target.value)}
                                autoComplete="off"
                              ></TextField>
                            </FormControl>
                          )}
                          {/* DATE SELECTOR FOR FILTER BY TIME COLUMNS */}
                          {!isFilterEnum && dateFields.includes(currentFilter?.field!) && (
                            <FormGroup>
                              <FormControl style={{ width: '100%', marginTop: '10px' }}>
                                <InputLabel color="secondary">Select</InputLabel>
                                <Select
                                  fullWidth
                                  value={dateCondition}
                                  input={<OutlinedInput label="Select" />}
                                  onChange={handleDateCondition}
                                  MenuProps={MenuProps}
                                  color="secondary"
                                  displayEmpty
                                >
                                  <MenuItem value={'after'}>Date is after</MenuItem>
                                  <MenuItem value={'before'}>Date is before</MenuItem>
                                </Select>
                              </FormControl>
                              <FormControl style={{ width: '100%', marginTop: '15px' }}>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                  <DatePicker
                                    label="Select Date"
                                    value={date != null ? date : null}
                                    onChange={(newDate) => {
                                      handleDateChange(newDate)
                                    }}
                                    slotProps={{
                                      textField: { InputLabelProps: { color: 'secondary' }, focused: false },
                                    }}
                                  />
                                </LocalizationProvider>
                              </FormControl>
                            </FormGroup>
                          )}
                          <div style={{ display: 'flex', justifyContent: 'right', margin: '10px' }}>
                            <Button
                              variant="text"
                              color={theme.palette.mode === 'dark' ? 'primary' : 'secondary'}
                              type="submit"
                              disabled={dateFields.includes(currentFilter?.field!) && (!dateCondition || !date)}
                            >
                              Apply
                            </Button>
                          </div>
                        </Box>
                      </DialogContent>
                    </div>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        )}
        <IconButton disabled={!filtered} size="large" onClick={removeAllFilters}>
          <CloseIcon />
        </IconButton>
      </div>
      <Divider />
      {/* TABLE */}
      <Paper elevation={1}>
        <Table
          columns={columns}
          rows={rows!}
          loading={tableDataLoading}
          handleSort={handleSortTable}
          refresh={refresh}
          handleSelectRow={handleSelectRow!}
          multiSelect={multiSelect ? multiSelect : false}
        />
        {paginationEnabled && (
          <TablePagination
            style={{ width: '100%' }}
            {...pagination}
            component="div"
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            showFirstButton
            showLastButton
          />
        )}
      </Paper>
    </Root>
  )
}

export default WedgeTable
