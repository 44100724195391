import React, { Fragment } from 'react';

function WithUserPermissions(WrappedComponent: any, componentPermissionRequired: any, componentType: any) {
  return class WithFoo extends React.Component<any, any> {
    constructor(props: any) {
      super(props);
    }

    render() {
      const isUserPermitted = this.props.userPermissions.includes(componentPermissionRequired) || this.props.userPermissions.includes('*');
      return <Fragment>{isUserPermitted ? <WrappedComponent {...this.props} /> : ''}</Fragment>;
    }
  };
}

export default WithUserPermissions;
